import { Role } from 'API';
import reducer from 'app/pages/store';
import {
  getCardProductApplication,
  getFinancialAccountIdThrottled,
  getIntegration,
  selectReviewOnboardSlice,
} from 'app/pages/store/reviewOnboardSlice';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';
import { useAppDispatch, useAppSelector } from 'app/store';
import { refreshUserSession } from 'app/store/authSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import withReducer from 'app/store/withReducer';
import { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';

const PurchaseCardsWrapper = ({ children }: PropsWithChildren): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedPaidolId, selectedPaidol, userRole } = useAppSelector(selectUserCompanies);
  const { integration, application } = useAppSelector(selectReviewOnboardSlice);

  const userIsAdministrator = Role.ADMINISTRATOR === userRole;

  const { 'firstRunStateFlags.isStepperEnabled': isStepperEnabled } = useIsEnabled([
    { featureName: 'firstRunStateFlags', flagName: 'isStepperEnabled' },
  ]);

  const ROUTES = {
    ADDITIONAL_DOCUMENTS: '/review/additionalDocuments',
    PERMISSION: '/review/permission',
    ONBOARD: '/review/onboard',
  };

  useDeepCompareEffect(() => {
    if (!integration) {
      dispatch(getIntegration(selectedPaidolId));
    } else {
      // Refresh API access tokens since they depend on PaidolHighnoteIntegration
      dispatch(refreshUserSession());
    }
  }, [dispatch, integration, selectedPaidolId]);

  useDeepCompareEffect(() => {
    // Check application status
    // TODO: listen for CARD_PRODUCT_APPLICATION_APPROVED in Highnote
    // webhook and update on the backend
    if (
      integration &&
      integration.accountHolderCardProductApplicationId &&
      !integration.isApplicationAccepted
    ) {
      dispatch(getCardProductApplication(integration));
    }
  }, [
    dispatch,
    integration,
    integration?.accountHolderCardProductApplicationId,
    integration?.isApplicationAccepted,
  ]);

  useDeepCompareEffect(() => {
    // Retrieve financial account ID
    // TODO: listen for ACCOUNT_STATUS_ACTIVE in Highnote
    // webhook and update on the backend
    if (integration && integration?.isApplicationAccepted && !integration.financialAccountId) {
      dispatch(getFinancialAccountIdThrottled(integration));
    }
  }, [dispatch, integration, integration?.isApplicationAccepted, integration?.financialAccountId]);

  /* Vince said comment out payables logic */
  /* if (selectedPaidol && !selectedPaidol.enablePCards) {
    return <Navigate to="/payables" replace />;
  } */

  useDeepCompareEffect(() => {
    const isStepperComplete = selectedPaidol?.firstRunChecklist?.isStepperComplete === true;
    const isAdditionalDocumentsPage = /^\/review\/additionalDocuments/.test(location.pathname);
    const isPermissionPage = /^\/review\/permission/.test(location.pathname);
    const isOnboardPage = /^\/review\/onboard/.test(location.pathname);
    const isAppAccepted = integration?.isApplicationAccepted === true;

    if (!selectedPaidol || !integration) {
      return;
    }

    if (userIsAdministrator) {
      if (!isAppAccepted && application?.needDocuments === true && !isAdditionalDocumentsPage) {
        navigate(ROUTES.ADDITIONAL_DOCUMENTS, { replace: true });
        return;
      }
      if (!isAppAccepted && !isPermissionPage && application?.needDocuments === false) {
        navigate(ROUTES.PERMISSION, { replace: true });
        return;
      }
      if (isAppAccepted && !isPermissionPage && !integration?.financialAccountId) {
        navigate(ROUTES.PERMISSION, { replace: true });
        return;
      }
      if (
        integration.financialAccountId &&
        !isStepperComplete &&
        isStepperEnabled &&
        isAppAccepted &&
        !isOnboardPage
      ) {
        navigate(ROUTES.ONBOARD, { replace: true });
        return;
      }
    }
  }, [
    isStepperEnabled,
    userIsAdministrator,
    selectedPaidol,
    integration,
    location.pathname,
    integration?.financialAccountId,
    application?.needDocuments,
    integration?.isApplicationAccepted,
  ]);

  return <>{children}</>;
};

export default withReducer('cards', reducer)(PurchaseCardsWrapper);
