import { useAppDispatch, useAppSelector } from 'app/store';
import { closeDialog, selectDialog } from 'app/store/dialogSlice';
import ResponsiveDialog from './dialog/ResponsiveDialog';

function SharedDialog(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isOpen, options } = useAppSelector(selectDialog);

  return (
    <ResponsiveDialog open={isOpen} onClose={(e) => dispatch(closeDialog())}>
      {options.children}
    </ResponsiveDialog>
  );
}

export default SharedDialog;
