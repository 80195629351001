import {
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Typography,
} from '@mui/material';
import styled from '@mui/styles/styled';
import defaultColors from 'app/theme/defaultColors';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  ...theme,
  boxShadow: '0px 2px 6px 2px #00000026',
  borderRadius: '0.5rem',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  ...theme,
  justifyContent: 'left',
  border: 'none',
  borderRadius: '0.5rem',
  padding: '0.5rem 1rem 0.5rem 1rem',
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: defaultColors.lightBlueColor,
  },
}));

type VerticalStyledToggleButtonGroupProps = { options: Record<string, string> } & ToggleButtonGroupProps;

function VerticalStyledToggleButtonGroup({ options, ...props }: VerticalStyledToggleButtonGroupProps) {
  return (
    <StyledToggleButtonGroup orientation="vertical" exclusive {...props}>
      {Object.entries(options).map(([key, name]) => (
        <StyledToggleButton key={key} value={key}>
          <Typography variant="body1">
            <strong>{name}</strong>
          </Typography>
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}

export default VerticalStyledToggleButtonGroup;
