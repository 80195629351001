import { combineReducers } from '@reduxjs/toolkit';
import onboard from './reviewOnboardSlice';
import type { ReviewOnboardState } from './reviewOnboardSlice';
import paymentCards, { PaymentCardsState } from './paymentCardsSlice';
import specificCard, { SpecificCardState } from './specificCardSlice';
import budgetWidget, { BudgetWidgetState } from './budgetWidgetSlice';
import transactions, { TransactionsState } from './transactionsSlice';
import spendWidget, { SpendWidgetState } from './spendWidgetSlice';
import issueCard, { IssueCardState } from './issueCardSlice';
import topMerchantsWidget, { TopMerchantsWidgetState } from './topMerchantsWidgetSlice';
import groups, { GroupsState } from 'app/pages/store/groupsSlice';
import funding, { FundingState } from './fundingSlice';
import { enableMapSet } from 'immer';
import spendRules, { SpendRulesState } from './spendRulesSlice';
import downloadTransactions, { DownloadTransactionsState } from './downloadTransactionsSlice';
import procore, { ProcoreState } from './procoreSlice';
import agave, { AgaveState } from './agaveSlice';
import priorityActions, { priorityActionsState } from './priorityActionsSlice';

enableMapSet();

export interface CardsState {
  onboard: ReviewOnboardState;
  paymentCards: PaymentCardsState;
  specificCard: SpecificCardState;
  budgetWidget: BudgetWidgetState;
  transactions: TransactionsState;
  spendWidget: SpendWidgetState;
  topMerchantsWidget: TopMerchantsWidgetState;
  groups: GroupsState;
  funding: FundingState;
  spendRules: SpendRulesState;
  issueCard: IssueCardState;
  downloadTransactions: DownloadTransactionsState;
  procore: ProcoreState;
  agave: AgaveState;
  priorityActions: priorityActionsState;
}

const reducer = combineReducers({
  onboard,
  paymentCards,
  specificCard,
  budgetWidget,
  transactions,
  spendWidget,
  topMerchantsWidget,
  groups,
  agave,
  funding,
  spendRules,
  issueCard,
  downloadTransactions,
  procore,
  priorityActions,
});

export default reducer;
