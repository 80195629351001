import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { GetPriorityActionsQuery } from 'API';
import { RootState } from 'app/store/rootReducer';
import { getPriorityActions } from 'graphql/queries';

export interface PriorityActionsState {
  loading: boolean;
  newMessages: number | null;
  inactiveCards: number | null;
  unactivatedCards: number | null;
  reimbursements: number | null;
}

export const initialState: PriorityActionsState = {
  loading: true,
  newMessages: null,
  inactiveCards: null,
  unactivatedCards: null,
  reimbursements: null,
};

export const fetchPriorityActions = createAsyncThunk(
  'overview/getPriorityActions',
  async ({ paidolId, userId }: { paidolId: string; userId: string }) => {
    return (
      API.graphql(graphqlOperation(getPriorityActions, { input: { paidolId, userId } })) as Promise<
        GraphQLResult<GetPriorityActionsQuery>
      >
    ).then((res) => res.data?.getPriorityActions);
  }
);

const priorityActionsSlice = createSlice({
  name: 'priorityActions',
  initialState,
  reducers: {
    resetAll: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPriorityActions.pending, (state) => {
      state.loading = true;
      state.newMessages = null;
      state.inactiveCards = null;
      state.unactivatedCards = null;
      state.reimbursements = null;
    });
    builder.addCase(fetchPriorityActions.fulfilled, (state, action) => {
      if (action.payload) {
        state.newMessages = action.payload.newMessages || 0;
        state.inactiveCards = action.payload.inactiveCards || 0;
        state.unactivatedCards = action.payload.unactivatedCards || 0;
        state.reimbursements = action.payload.reimbursements || 0;
      }
    });
    builder.addCase(fetchPriorityActions.rejected, (state, action) => {
      state.newMessages = 0;
      state.inactiveCards = 0;
      state.unactivatedCards = 0;
      state.reimbursements = 0;
    });
    builder.addMatcher(isAnyOf(fetchPriorityActions.fulfilled, fetchPriorityActions.rejected), (state) => {
      state.loading = false;
    });
  },
});

export const priorityActionsSelect = (state: RootState) => state.overview?.priorityActions || initialState;

export default priorityActionsSlice.reducer;
