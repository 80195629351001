import { Box, BoxProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

const ResponsiveButtonContainer = forwardRef(({ sx, ...props }: BoxProps, ref: Ref<HTMLDivElement>) => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        gap: 2,
        py: 2,
        '& button': {
          width: { xs: '100%', sm: 'inherit' },
        },
        ...sx,
      }}
    />
  );
});

export default ResponsiveButtonContainer;
