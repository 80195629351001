import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  CreateMerchantSpendRuleInput,
  CreateMerchantSpendRuleMutation,
  HNCreateAmountLimitSpendRuleInput,
  HnCreateAmountLimitSpendRuleMutation,
  HNCreateMerchantCategorySpendRuleInput,
  HnCreateMerchantCategorySpendRuleMutation,
  HNCreateMerchantCountrySpendRuleInput,
  HnCreateMerchantCountrySpendRuleMutation,
  HnGetSpendRuleByIdQuery,
  HNMerchantSpendRule,
  HNUpdateMerchantCategorySpendRuleInput,
  ListMerchantSpendRulesByPaidolIdQuery,
  MerchantSpendRule,
  ModelMerchantSpendRuleConnection,
  UpdateMerchantSpendRuleInput,
  UpdateMerchantSpendRuleMutation,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import {
  CreateAmountLimitSpendRule,
  CreateHighnoteSpendRule,
  CreateMerchantCategorySpendRule,
  CreateMerchantCountrySpendRule,
  CreateMerchantSpendRule,
  UpdateSpendRule,
} from './mutations';
import { GetSpendRuleById, GetMerchantSpendRulesForPaidolId } from './queries';

const spendRulesEntityAdapter = createEntityAdapter<MerchantSpendRule>();

const initialState = spendRulesEntityAdapter.getInitialState({
  loading: false,
  nextToken: undefined as string | undefined,
  selectedSpendRuleId: undefined as string | undefined,
  rules: new Map<string, HNMerchantSpendRule>(),
});

export type SpendRulesState = typeof initialState;

export interface GetSpendRulesArgs {
  paidolId: string;
  nextToken?: string;
  limit?: number;
  shouldLoadDetails?: boolean;
}

export interface GetSpendRuleByIdArgs {
  ruleId: string;
}

export interface NewHighnoteSpendRuleArgs {
  merchantCategoryInput?: HNCreateMerchantCategorySpendRuleInput;
  merchantCountryInput?: HNCreateMerchantCategorySpendRuleInput;
  amountLimitInput?: HNCreateAmountLimitSpendRuleInput;
}

export interface NewMerchantSpendRuleArgs {
  createMerchantSpendRuleInput: CreateMerchantSpendRuleInput;
}

export interface UpdateSpendRuleArgs {
  merchantCategoryInput?: HNUpdateMerchantCategorySpendRuleInput;
  merchantCountryInput?: HNUpdateMerchantCategorySpendRuleInput;
  updateMerchantSpendRuleInput: UpdateMerchantSpendRuleInput;
}

export const getMerchantSpendRules = createAsyncThunk(
  'cards/spend-rules/getMerchantSpendRules',
  async ({ paidolId, shouldLoadDetails = true }: GetSpendRulesArgs, { dispatch }) => {
    return (
      API.graphql(graphqlOperation(GetMerchantSpendRulesForPaidolId, { paidolId })) as Promise<
        GraphQLResult<ListMerchantSpendRulesByPaidolIdQuery>
      >
    ).then((result) => {
      const promises: any[] = [];

      if (shouldLoadDetails) {
        result.data?.listMerchantSpendRulesByPaidolId?.items.forEach((r) => {
          const rule = r as MerchantSpendRule;

          if (rule?.merchantCategorySpendRuleId) {
            const promise = dispatch(getSpendRuleById({ ruleId: rule?.merchantCategorySpendRuleId }));
            promises.push(promise);
          }

          if (rule?.merchantCountrySpendRuleId) {
            const promise = dispatch(getSpendRuleById({ ruleId: rule?.merchantCountrySpendRuleId }));
            promises.push(promise);
          }

          if (rule?.amountLimitSpendRuleId) {
            const promise = dispatch(getSpendRuleById({ ruleId: rule?.amountLimitSpendRuleId }));
            promises.push(promise);
          }
        });
      }

      return Promise.all(promises).then(() => {
        return result.data?.listMerchantSpendRulesByPaidolId as ModelMerchantSpendRuleConnection;
      });
    });
  }
);

export const getSpendRuleById = createAsyncThunk(
  'cards/spend-rules/getSpendRuleById',
  async ({ ruleId }: GetSpendRuleByIdArgs) => {
    return (
      API.graphql(graphqlOperation(GetSpendRuleById, { ruleId })) as Promise<
        GraphQLResult<HnGetSpendRuleByIdQuery>
      >
    ).then((result) => result.data?.hnGetSpendRuleById as HNMerchantSpendRule);
  }
);

export const newMerchantSpendRule = createAsyncThunk(
  'cards/spend-rules/newMerchantSpendRule',
  async ({ createMerchantSpendRuleInput }: NewMerchantSpendRuleArgs) => {
    return (
      API.graphql(
        graphqlOperation(CreateMerchantSpendRule, {
          createMerchantSpendRuleInput,
        })
      ) as Promise<GraphQLResult<CreateMerchantSpendRuleMutation>>
    ).then((results) => results.data?.createMerchantSpendRule);
  }
);

export const newHighnoteSpendRule = createAsyncThunk(
  'cards/spend-rules/newHighnoteSpendRule',
  async ({ merchantCategoryInput, merchantCountryInput, amountLimitInput }: NewHighnoteSpendRuleArgs) => {
    if (!merchantCategoryInput && !merchantCountryInput && !amountLimitInput) {
      throw new Error('At least one spend rule input must be provided');
    }
    return (
      API.graphql(
        graphqlOperation(CreateHighnoteSpendRule, {
          merchantCategoryInput,
          merchantCountryInput,
          amountLimitInput,
        })
      ) as Promise<GraphQLResult<CreateMerchantSpendRuleMutation>>
    ).then((results) => results.data);
  }
);

export const updateSpendRule = createAsyncThunk(
  'cards/spend-rules/updateSpendRule',
  async ({ updateMerchantSpendRuleInput }: UpdateSpendRuleArgs) => {
    return (
      API.graphql(
        graphqlOperation(UpdateSpendRule, {
          updateMerchantSpendRuleInput,
        })
      ) as Promise<GraphQLResult<UpdateMerchantSpendRuleMutation>>
    ).then((results) => results.data?.updateMerchantSpendRule as MerchantSpendRule | undefined);
  }
);

export const createMerchantCategorySpendRule = createAsyncThunk(
  'spendRules/createMerchantCategorySpendRule',
  async (merchantCategoryInput: HNCreateMerchantCategorySpendRuleInput) => {
    const result = (await API.graphql(
      graphqlOperation(CreateMerchantCategorySpendRule, { merchantCategoryInput })
    )) as GraphQLResult<HnCreateMerchantCategorySpendRuleMutation>;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result.data?.hnCreateMerchantCategorySpendRule;
  }
);

export const createMerchantCountrySpendRule = createAsyncThunk(
  'spendRules/createMerchantCountrySpendRule',
  async (merchantCountryInput: HNCreateMerchantCountrySpendRuleInput) => {
    const result = (await API.graphql(
      graphqlOperation(CreateMerchantCountrySpendRule, { merchantCountryInput })
    )) as GraphQLResult<HnCreateMerchantCountrySpendRuleMutation>;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result.data?.hnCreateMerchantCountrySpendRule;
  }
);

export const createAmountLimitSpendRule = createAsyncThunk(
  'spendRules/createAmountLimitSpendRule',
  async (amountLimitInput: HNCreateAmountLimitSpendRuleInput) => {
    const result = (await API.graphql(
      graphqlOperation(CreateAmountLimitSpendRule, { amountLimitInput })
    )) as GraphQLResult<HnCreateAmountLimitSpendRuleMutation>;

    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result.data?.hnCreateAmountLimitSpendRule;
  }
);

const spendRuleSlice = createSlice({
  name: 'cards/spend-rules',
  initialState,
  reducers: {
    resetSpendRulesSlice: () => initialState,
    setSelectedSpendRuleId: (state, action) => {
      state.selectedSpendRuleId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMerchantSpendRules.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSpendRuleById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMerchantSpendRules.fulfilled, (state, action) => {
      if (action.payload?.items) {
        spendRulesEntityAdapter.addMany(state, action.payload.items as MerchantSpendRule[]);
        state.loading = false;
      }
      state.nextToken = action.payload?.nextToken ?? undefined;
    });
    builder.addCase(getSpendRuleById.fulfilled, (state, action) => {
      if (action.payload?.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.rules.set(action.payload.id, action.payload);
      }
    });
    builder.addCase(updateSpendRule.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateSpendRule.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(newMerchantSpendRule.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(newMerchantSpendRule.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(newHighnoteSpendRule.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(newHighnoteSpendRule.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addMatcher(
      isAnyOf(
        createMerchantCategorySpendRule.pending,
        createMerchantCountrySpendRule.pending,
        createAmountLimitSpendRule.pending
      ),
      (state) => {
        state.loading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(
        createMerchantCategorySpendRule.fulfilled,
        createMerchantCountrySpendRule.fulfilled,
        createAmountLimitSpendRule.fulfilled
      ),
      (state, action) => {
        state.loading = false;
      }
    );

    builder.addMatcher(
      isAnyOf(
        createMerchantCategorySpendRule.rejected,
        createMerchantCountrySpendRule.rejected,
        createAmountLimitSpendRule.rejected
      ),
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export const { resetSpendRulesSlice, setSelectedSpendRuleId } = spendRuleSlice.actions;

export const selectSpendRulesSlice = (state: RootState) => state.cards?.spendRules ?? initialState;

export const selectSelectedSpendRule = (state: RootState) => {
  if (state.cards?.spendRules.selectedSpendRuleId) {
    return selectBySpendRuleId(state, state.cards?.spendRules.selectedSpendRuleId);
  }

  return undefined;
};
export const { selectAll: selectAllSpendRules, selectById: selectBySpendRuleId } =
  spendRulesEntityAdapter.getSelectors(selectSpendRulesSlice);

export default spendRuleSlice.reducer;
