import { Box } from '@mui/material';
import Loading from './Loading';

const LoadingAbsolute = () => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed', // Use 'fixed' to keep it on top of the viewport, or 'absolute' if you want it relative to the parent container
          top: 0,
          left: 0,
          width: '100%', // Full width of the viewport
          height: '100%', // Full height of the viewport
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none', // Allow clicks through the overlay
          zIndex: 9999, // High z-index to ensure it appears above other elements
        }}
      >
        <Loading />
      </Box>
    </>
  );
};

export default LoadingAbsolute;
