import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role, SearchableHighnoteTransactionFilterInput } from 'API';
import { getTransactionsWithFilters } from 'app/pages/store/transactionsSlice';
import type { RootState } from 'app/store/rootReducer';
import { formatISO } from 'date-fns';
import { DateOption } from 'util/constants';

export interface FilterOption {
  id: string;
  label: string;
}

export interface ColumnFilter {
  key: string;
  type: 'string' | 'number' | 'date';
  name: string;
  options?: Array<FilterOption>;
  selectedValues?: Array<FilterOption>;
  minNumber?: number;
  maxNumber?: number;
  rule: string;
  page: string;
  active?: boolean;
}

export interface ColumnFilterMap {
  [key: string]: ColumnFilter;
}

export interface SetColumnFilterPayload {
  pageName: string;
  columnFilters: ColumnFilterMap;
}

export interface SetDateFilterPayload {
  page: string;
  firstDate: Date;
  lastDate: Date;
  dateOption?: DateOption;
}

export interface ClearColumnFilterPayload {
  page: string;
}

export interface SortOption {
  field: string;
  direction: string;
}

export interface TableFilters {
  columnFilters: ColumnFilterMap;
  firstDate?: Date;
  lastDate?: Date;
  dateOption?: DateOption;
  sortOption?: SortOption;
  filtering: boolean;
  extraFilters?: SearchableHighnoteTransactionFilterInput;
}

export interface SearchAndFilterState {
  [page: string]: TableFilters;
}

export interface ApplyDateFilterPayload {
  pageName: string;
  firstDate?: Date | null;
  lastDate?: Date | null;
  dateOption?: DateOption;
}

export interface ApplySortFilterPayload {
  pageName: string;
  sortOption: SortOption;
}

export interface ApplyColumnFiltersPayload {
  pageName: string;
  columnFilters: ColumnFilterMap;
}

export interface ClearColumnFiltersPayload {
  pageName: string;
}

export interface SetSortOrderPayload {
  pageName: string;
  sortOption: SortOption;
}

export interface SetExtraFilters {
  pageName: string;
  extraFilters: SearchableHighnoteTransactionFilterInput;
}

export interface ExecuteLoadMore {
  pageName: string;
  nextToken: string;
}

const initialState: SearchAndFilterState = {};

const generateFilterObject = (
  { firstDate, lastDate, extraFilters, columnFilters }: TableFilters,
  paidolId: string,
  role: Role,
  cardGroupIds?: Array<string>
) => {
  const filterObject: any = createInitialFilterObject(paidolId, firstDate, lastDate, extraFilters);
  const orConditions = gatherOrConditions(columnFilters);
  const groupAdminFilter = role === Role.GROUP_ADMINISTRATOR && {
    or: cardGroupIds?.map((id) => ({ cardGroupId: { eq: id } })) || [{ transactionId: { exists: false } }],
  };

  if (orConditions.length) {
    filterObject.and = [{ or: orConditions }, groupAdminFilter ? groupAdminFilter : {}];
  } else if (groupAdminFilter) {
    filterObject.and = groupAdminFilter ? [groupAdminFilter] : [];
  } else {
    delete filterObject.and;
  }

  return filterObject;
};

const createInitialFilterObject = (
  paidolId: string,
  firstDate?: Date,
  lastDate?: Date,
  extraFilters?: Record<string, unknown>
) => {
  return {
    paidolId: { eq: paidolId },
    status: { ne: 'REVERSED' },
    ...createDateFilter(firstDate, lastDate),
    ...extraFilters,
  };
};

const createDateFilter = (firstDate?: Date, lastDate?: Date) => {
  if (!firstDate && !lastDate) return {};

  return {
    transactionDate: {
      ...(firstDate ? { gt: formatISO(firstDate) } : {}),
      ...(lastDate ? { lt: formatISO(lastDate) } : {}),
    },
  };
};

const gatherOrConditions = (columnFilters: Record<string, ColumnFilter>) => {
  const orConditions: Array<any> = [];

  Object.values(columnFilters).forEach((filter) => {
    orConditions.push(...processFilterValues(filter));
  });

  return orConditions;
};

const processFilterValues = (filter: ColumnFilter) => {
  const conditions = [];

  filter.selectedValues?.forEach((obj) => {
    const condition = { [filter.key]: { [filter.rule === 'noExcept' ? 'eq' : 'ne']: obj.id } };
    conditions.push(condition);
  });

  if (filter.type === 'number' && (filter.minNumber || filter.maxNumber)) {
    conditions.push(processNumberTypeFilter(filter));
  }

  return conditions;
};

const processNumberTypeFilter = (filter: ColumnFilter) => {
  switch (filter.rule) {
    case 'range':
      return { [filter.key]: { gte: filter.minNumber, lte: filter.maxNumber } };
    case 'moreThan':
      return { [filter.key]: { gte: filter.minNumber } };
    case 'lessThan':
      return { [filter.key]: { lte: filter.maxNumber } };
  }
};

const getFilterForPage = (state: RootState, pageName: string) => {
  const searchAndFilter = state.searchAndFilter[pageName];
  return generateFilterObject(
    {
      ...searchAndFilter,
      columnFilters: searchAndFilter.columnFilters || {},
      filtering: searchAndFilter.filtering || false,
    },
    state.userCompanies.selectedPaidolId,
    state.userCompanies.userRole || Role.CARDHOLDER,
    state.userCompanies?.paidolUsers?.[0].managedGroups?.items?.map((group) => group!.cardGroup.id)
  );
};

export const applyDateFilter = createAsyncThunk<any, ApplyDateFilterPayload>(
  'searchAndFilter/applyDateFilter',
  async ({ pageName }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const searchAndFilter = state.searchAndFilter[pageName];
    const filter = getFilterForPage(state, pageName);
    const response = await dispatch(
      getTransactionsWithFilters({
        filterObject: filter,
        sortObject: searchAndFilter?.sortOption?.field ? searchAndFilter?.sortOption : undefined,
      })
    );
    return response;
  }
);

export const applySortFilter = createAsyncThunk<any, ApplySortFilterPayload>(
  'searchAndFilter/applySortFilter',
  async ({ pageName, sortOption }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const filter = getFilterForPage(state, pageName);
    const response = await dispatch(
      getTransactionsWithFilters({
        filterObject: filter,
        sortObject: sortOption.field ? sortOption : undefined,
      })
    );
    return response;
  }
);

export const applyColumnFilters = createAsyncThunk<any, ApplyColumnFiltersPayload>(
  'searchAndFilter/applyColumnFilters',
  async ({ pageName }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const searchAndFilter = state.searchAndFilter[pageName];
    const filter = getFilterForPage(state, pageName);
    const response = await dispatch(
      getTransactionsWithFilters({
        filterObject: filter,
        sortObject: searchAndFilter?.sortOption?.field ? searchAndFilter?.sortOption : undefined,
      })
    );
    return response;
  }
);

export const clearColumnFilters = createAsyncThunk<any, ClearColumnFiltersPayload>(
  'searchAndFilter/clearColumnFilters',
  async ({ pageName }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const searchAndFilter = state.searchAndFilter[pageName];
    const response = await dispatch(
      getTransactionsWithFilters(
        generateFilterObject(
          { ...searchAndFilter, columnFilters: {} },
          state.userCompanies.selectedPaidolId,
          state.userCompanies.userRole || Role.CARDHOLDER,
          state.userCompanies?.paidolUsers?.[0].managedGroups?.items?.map((group) => group!.cardGroup.id)
        )
      )
    );
    return response;
  }
);

export const executeLoadMore = createAsyncThunk<any, ExecuteLoadMore>(
  'searchAndFilter/executeLoadMore',
  async ({ pageName, nextToken }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const searchAndFilter = state.searchAndFilter[pageName];
    const filter = getFilterForPage(state, pageName);
    const response = await dispatch(
      getTransactionsWithFilters({
        filterObject: filter,
        sortObject: searchAndFilter?.sortOption?.field ? searchAndFilter?.sortOption : undefined,
        nextToken,
      })
    );
    return response;
  }
);

export const executeSearch = createAsyncThunk<any, string>(
  'searchAndFilter/executeSearch',
  async (pageName, { dispatch, getState }) => {
    const state = getState() as RootState;
    const searchAndFilter = state.searchAndFilter[pageName];
    const filter = getFilterForPage(state, pageName);
    const response = await dispatch(
      getTransactionsWithFilters({
        filterObject: filter,
        sortObject: searchAndFilter?.sortOption?.field ? searchAndFilter?.sortOption : undefined,
      })
    );
    return response;
  }
);

const searchAndFilterSlice = createSlice({
  name: 'searchAndFilter',
  initialState,
  reducers: {
    setDateFilters: (state, action: PayloadAction<SetDateFilterPayload>) => {
      state[action.payload.page] = {
        ...state[action.payload.page],
        firstDate: action.payload.firstDate,
        lastDate: action.payload.lastDate,
        dateOption: action.payload.dateOption,
      };
    },
    setColumnFilters: (state, action: PayloadAction<SetColumnFilterPayload>) => {
      state[action.payload.pageName] = {
        ...state[action.payload.pageName],
        columnFilters: { ...state[action.payload.pageName]?.columnFilters, ...action.payload.columnFilters },
      };
    },
    setSortOrder: (state, action: PayloadAction<SetSortOrderPayload>) => {
      state[action.payload.pageName] = {
        ...state[action.payload.pageName],
        sortOption: { ...state[action.payload.pageName]?.sortOption, ...action.payload.sortOption },
      };
    },
    setExtraFilters: (state, action: PayloadAction<SetExtraFilters>) => {
      state[action.payload.pageName] = {
        ...state[action.payload.pageName],
        extraFilters: { ...action.payload.extraFilters },
      };
    },
    resetSearchAndFilter: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(clearColumnFilters.pending, (state, action) => {
      const filtersToClear = { ...state[action.meta.arg.pageName].columnFilters };
      Object.keys(filtersToClear).forEach((key) => {
        filtersToClear[key].selectedValues = [];
        filtersToClear[key].rule = filtersToClear[key].type === 'number' ? 'range' : 'noExcept';
      });
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        columnFilters: filtersToClear,
        filtering: true,
      };
    });
    builder.addCase(applyDateFilter.pending, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        firstDate: action.meta.arg.firstDate ? action.meta.arg.firstDate : undefined,
        lastDate: action.meta.arg.lastDate ? action.meta.arg.lastDate : undefined,
        dateOption: action.meta.arg.dateOption,
        filtering: true,
      };
    });
    builder.addCase(applySortFilter.pending, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        sortOption: action.meta.arg.sortOption,
        filtering: true,
      };
    });
    builder.addCase(applyColumnFilters.pending, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        columnFilters: action.meta.arg.columnFilters,
        filtering: true,
      };
    });
    builder.addCase(executeLoadMore.pending, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: true,
      };
    });
    builder.addCase(executeSearch.pending, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        filtering: true,
      };
    });
    builder.addCase(clearColumnFilters.fulfilled, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: false,
      };
    });
    builder.addCase(applyDateFilter.fulfilled, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: false,
      };
    });
    builder.addCase(applySortFilter.fulfilled, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: false,
      };
    });
    builder.addCase(applyColumnFilters.fulfilled, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: false,
      };
    });
    builder.addCase(executeLoadMore.fulfilled, (state, action) => {
      state[action.meta.arg.pageName] = {
        ...state[action.meta.arg.pageName],
        filtering: false,
      };
    });
    builder.addCase(executeSearch.fulfilled, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        filtering: false,
      };
    });
  },
});

export const { setDateFilters, setColumnFilters, setSortOrder, setExtraFilters, resetSearchAndFilter } =
  searchAndFilterSlice.actions;

export const selectSearchAndFilter = (state: RootState): SearchAndFilterState => state.searchAndFilter;

export default searchAndFilterSlice.reducer;
