import NewCardForm from 'app/pages/cards/cards/NewCardSheet/NewCardForm';
import { Grid } from '@mui/material';
import { FormType, NewCardFormValues } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { useAppSelector } from 'app/store';
import { selectAllSpendRules } from 'app/pages/store/spendRulesSlice';
import { useTranslation } from 'react-i18next';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import { useIssueOrUpdateCard } from 'app/hooks/useIssueOrUpdateCard';
import { useCallback } from 'react';

interface IssueCardFormProps {
  handleNext: () => void;
}
const IssueCardForm = ({ handleNext }: IssueCardFormProps) => {
  const merchantSpendRules = useAppSelector(selectAllSpendRules);
  const formType = FormType.IssueCard;
  const { t } = useTranslation();

  const { onSaveCard, loading } = useIssueOrUpdateCard({ formType: FormType.IssueCard });

  const onSubmit = useCallback(
    async (formValues: NewCardFormValues) => {
      try {
        await onSaveCard(formValues);
        handleNext();
      } catch (error) {
        console.error('Error saving card:', error);
      }
    },
    [onSaveCard, handleNext]
  );

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        pt: 3,
      }}
    >
      <Grid item xs={12}>
        <ContainerComponent
          title={t('createCardFirstRun.title')}
          description={t('createCardFirstRun.description')}
        />
      </Grid>
      <Grid
        item
        xs={9}
        md={6}
        lg={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <NewCardForm
          formType={formType}
          loading={loading}
          groups={[]}
          rules={merchantSpendRules}
          onSubmit={onSubmit}
          onCancel={() => handleNext()}
          cancelText={t('illDoThisLater')}
        />
      </Grid>
    </Grid>
  );
};

export default IssueCardForm;
