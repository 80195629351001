import { TextField } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  DateValidationError,
  LocalizationProvider,
  MobileDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import BrowserView from 'app/shared-components/util/BrowserView';
import MobileView from 'app/shared-components/util/MobileView';
import { useCallback, useState } from 'react';

const StyledTextField = styled(TextField)(
  ({ theme }) => `
  .MuiOutlinedInput-root {
    border-radius: 8px 8px 0px 0px;
    background-color: ${theme.palette.primary.main};
    .MuiOutlinedInput-notchedOutline {
      border: none
    }
    .MuiButtonBase-root, .MuiOutlinedInput-input {
      color: white;
    }
  }
`
);

export interface DatePickerProps extends MuiDatePickerProps<Date> {
  initialValue?: Date | null;
}

function DatePicker({
  initialValue = new Date(),
  onChange,
  onAccept,
  ...rest
}: DatePickerProps): JSX.Element {
  const [value, setValue] = useState<Date | null>(initialValue);

  const changeHandler = useCallback(
    (newDate: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
      setValue(newDate);
      if (onChange) {
        onChange(newDate, context);
      }
    },
    [onChange]
  );

  const acceptHandler = useCallback(
    (newDate: Date | null) => {
      setValue(newDate);
      if (onAccept) {
        onAccept(newDate);
      }
    },
    [onAccept]
  );

  return (
    <Box sx={{ width: '18rem', ml: 3, mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileView>
          <MobileDatePicker
            openTo="year"
            views={['year', 'month']}
            value={value}
            onChange={changeHandler}
            onAccept={acceptHandler}
            slots={{ textField: (params) => <StyledTextField {...params} /> }}
            {...rest}
          />
        </MobileView>
        <BrowserView>
          {/* DatePicker was replaced here by MobileDatePicker because of rendering issues */}
          <MobileDatePicker
            openTo="year"
            views={['year', 'month']}
            value={value}
            onChange={changeHandler}
            onAccept={acceptHandler}
            slots={{ textField: (params) => <StyledTextField {...params} /> }}
            {...rest}
          />
        </BrowserView>
      </LocalizationProvider>
    </Box>
  );
}

export default DatePicker;
