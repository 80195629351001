import { Reducer } from 'redux';
import { configureStore, ThunkDispatch, Action } from '@reduxjs/toolkit';
// eslint-disable-next-line no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createDebounce from 'redux-debounced';
import createReducer, { RootState } from './rootReducer';

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).prepend([createDebounce()]);

    if (process.env.REACT_APP_ENV === 'development') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { createLogger } = require(`redux-logger`);
      const logger = createLogger({
        collapsed: (getState: () => unknown, action: unknown, logEntry: ReduxLogger.LogEntryObject) =>
          !logEntry.error,
      });

      return middlewares.concat(logger);
    }

    return middlewares;
  },
  devTools: process.env.REACT_APP_ENV === 'development',
});

if (process.env.REACT_APP_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

interface AsyncReducers {
  [index: string]: Reducer;
}

const asyncReducers: AsyncReducers = {};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const injectReducer = (key: string, reducer: Reducer) => {
  if (asyncReducers[key]) {
    return false;
  }
  asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(asyncReducers));
  return store;
};

export type AppDispatch = ThunkDispatch<RootState, void, Action>;

// Use these in ts components instead of useDispatch() and useSelector()
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
