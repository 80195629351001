import { Box, Button, ButtonProps, CircularProgress, Fade } from '@mui/material';
import classNames from 'classnames';

export interface ProgressButtonProps extends Omit<ButtonProps, 'variant'> {
  loading?: boolean;
}

function ProgressButton({
  children,
  color = 'primary',
  loading = false,
  sx,
  ...restProps
}: ProgressButtonProps): JSX.Element {
  return (
    <Button
      className={classNames({ loading })}
      variant="contained"
      color={color}
      {...restProps}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        '&.loading:disabled': {
          color: `${color}.contrastText`,
          backgroundColor: `${color}.main`,
        },
        '& .MuiButton-startIcon, & .MuiButton-endIcon': {
          transition: 'opactiy 300ms',
        },
        '&.loading .MuiButton-startIcon, &.loading .MuiButton-endIcon': {
          opacity: 0,
        },
        ...sx,
      }}
    >
      <Fade in={!loading}>
        <Box>{children}</Box>
      </Fade>
      <Fade in={loading}>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'middle',
            alignContent: 'center',
            color: `${color}.contrastText`,
          }}
        >
          <CircularProgress color="inherit" size={24} />
        </Box>
      </Fade>
    </Button>
  );
}

export default ProgressButton;
