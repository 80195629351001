import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CardGroup, GetCardByHighnoteIdInput, GetCardsPayload, GetCardByHighnoteIdQuery } from 'API';
import { format } from 'date-fns';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { getCardById } from 'app/services/cardService';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { getCardByHighnoteId } from 'graphql/queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';

// Define the initial state
export type GroupCardsState = {
  groupCards: GetCardsPayload[]; // Define the exact card type here if available
  loading: boolean;
  error: string | null;
};

const initialState: GroupCardsState = {
  groupCards: [],
  loading: false,
  error: null,
};

// Async thunk for fetching group cards
export const fetchGroupCards = createAsyncThunk(
  'groupCards/fetchGroupCards',
  async ({ cardGroup }: { cardGroup: CardGroup }, { rejectWithValue }) => {
    try {
      const formattedDate = format(new Date(), 'yyyy-MM');
      const promises =
        cardGroup.paymentCards?.items.filter(isNotNullOrUndefined).map((paymentCard) =>
          getCardById({
            input: {
              id: paymentCard.paymentCardId,
              paidolId: cardGroup.paidolId,
              yearAndMonth: formattedDate,
            },
          })
        ) || [];
      const results = (await Promise.all(promises)).filter(isNotNullOrUndefined);
      return results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCardByHightnoteId = createAsyncThunk(
  'cards/paymentCards/getCardByHightnoteId',
  async (input: GetCardByHighnoteIdInput) => {
    return (
      API.graphql(
        graphqlOperation(getCardByHighnoteId, {
          input,
        })
      ) as Promise<GraphQLResult<GetCardByHighnoteIdQuery>>
    ).then((result) => {
      return result.data?.getCardByHighnoteId as GetCardsPayload;
    });
  }
);

// Slice for group cards
const groupCardsSlice = createSlice({
  name: 'groupCards',
  initialState,
  reducers: {
    resetGroupCards: (state) => {
      state.groupCards = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchGroupCards
    builder
      .addCase(fetchGroupCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroupCards.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.groupCards = action.payload;
      })
      .addCase(fetchGroupCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    builder.addCase(getCardByHightnoteId.fulfilled, (state, action) => {
      if (action.payload) {
        state.groupCards = state.groupCards.map((card) => {
          if (card.id === action.payload.id) {
            return action.payload;
          }
          return card;
        });
      }
    });
  },
});
export const groupCardsSelector = (state: RootState): GroupCardsState =>
  state?.team?.groupCards || initialState;

export const { resetGroupCards } = groupCardsSlice.actions;

export default groupCardsSlice.reducer;
