import { useMemo, useRef } from 'react';
import { Autocomplete, Box, Chip, TextField, styled } from '@mui/material';
import HideSourceIconBase from '@mui/icons-material/HideSource';
import ClearIcon from '@mui/icons-material/Clear';
import { FilterOption, ColumnFilter } from 'app/store/searchAndFilterSlice';

const HideSourceIcon = styled(HideSourceIconBase)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
}));

const CustomChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '& .MuiChip-deleteIcon': {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  '& .MuiChip-label': {
    color: theme.palette.text.secondary,
  },
}));

export const TextCriteriaContent = ({
  selectedFilterOptions,
  onSelectedFilterOptionsChange,
  filter,
}: {
  selectedFilterOptions: FilterOption[];
  onSelectedFilterOptionsChange: (newOptions: FilterOption[], key: string) => void;
  filter: ColumnFilter;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    if (!filter.options) return [];
    return filter.options.filter((opt) => !selectedFilterOptions.some((c) => c.id === opt.id));
  }, [selectedFilterOptions, filter]);

  return (
    <>
      <Box className="flex gap-4 flex-wrap mb-16">
        {selectedFilterOptions.map((option, i) => {
          return (
            <CustomChip
              icon={<HideSourceIcon />}
              deleteIcon={<ClearIcon fontSize="small" color="info" />}
              key={i}
              color="primaryLighter"
              label={option.label}
              onDelete={() => {
                const newValues = [...selectedFilterOptions];
                newValues.splice(
                  newValues.findIndex(function (val) {
                    return val.id === option.id;
                  }),
                  1
                );
                onSelectedFilterOptionsChange(newValues, filter.key);
              }}
            />
          );
        })}
      </Box>
      <Autocomplete
        options={filteredOptions}
        value={null}
        onChange={(_, value, reason) => {
          if (reason === 'selectOption' && value) {
            onSelectedFilterOptionsChange([...selectedFilterOptions, value], filter.key);
            inputRef.current?.blur();
          }
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <Box className="flex items-center gap-8 border border-solid border-grey-300 rounded-lg px-8">
            <HideSourceIcon color="primary" />
            <TextField
              {...params}
              variant="standard"
              placeholder={filter.name}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          </Box>
        )}
      />
    </>
  );
};
