import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, SelectChangeEvent, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { CriteriaRuleSelect } from './CriteriaRuleSelect';
import { CurrencyInputBox } from './CurrencyInputBox';
import { TextCriteriaContent } from './TextCriteriaContent';
import { FilterOption, ColumnFilter } from 'app/store/searchAndFilterSlice';
import { useTranslation } from 'react-i18next';

export type CriteriaRuleOption = { value: string; label: string };

export interface FilterChangeProps {
  selectedOptions?: FilterOption[];
  minNumber?: number;
  maxNumber?: number;
  key: string;
  active?: boolean;
}

interface CriteriaColumnItemProps {
  filter: ColumnFilter;
  isLast: boolean;
  onRuleChange: (event: SelectChangeEvent, key: string) => void;
  onFilterChange: (props: FilterChangeProps) => void;
}

export const CriteriaColumnItem = ({
  filter,
  isLast,
  onRuleChange,
  onFilterChange,
}: CriteriaColumnItemProps) => {
  const handleCriteriaRuleChange = (event: SelectChangeEvent) => {
    onRuleChange(event, filter.key);
  };

  const { t } = useTranslation();
  const theme = useTheme();

  const handleMinNumberChange = (value: string) => {
    if (!value) return;
    const numericValue = Number(value.replace('$', '')) * 100;
    onFilterChange({
      key: filter.key,
      minNumber: numericValue,
      maxNumber: filter.maxNumber,
      active: true,
    });
  };

  const handleMaxNumberChange = (value: string) => {
    if (!value) return;
    const numericValue = Number(value.replace('$', '')) * 100;
    onFilterChange({
      key: filter.key,
      minNumber: filter.minNumber,
      maxNumber: numericValue,
      active: true,
    });
  };

  const handleSelectedOptionsChange = (newOptions: FilterOption[], key: string) => {
    onFilterChange({
      key,
      selectedOptions: newOptions,
      active: true,
    });
  };

  const handleClearFilter = (key: string) => {
    onFilterChange({
      key,
      selectedOptions: [],
      active: false,
    });
  };

  const criteriaRuleOptions = useMemo(() => {
    switch (filter.type) {
      case 'string':
        return [
          {
            value: 'allExcept',
            label: `All ${filter.name} except`,
          },
          {
            value: 'noExcept',
            label: `No ${filter.name} except`,
          },
        ];
      case 'number':
        return [
          {
            value: 'range',
            label: `Range`,
          },
          {
            value: 'moreThan',
            label: `More than`,
          },
          {
            value: 'lessThan',
            label: `Less than`,
          },
        ];
      default:
        return [];
    }
  }, [filter]);

  return (
    <Box
      sx={{
        borderBottomWidth: isLast ? 0 : 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[300],
      }}
      className="flex flex-col py-8"
    >
      <Box className="flex items-center justify-between">
        <Typography variant="h4">{filter.name}</Typography>

        {filter.active ? (
          <Button className="gap-8" onClick={() => handleClearFilter(filter.key)}>
            <ClearIcon fontSize="small" style={{ width: 14 }} />
            {t('clear')}
          </Button>
        ) : (
          <Button
            className="gap-8"
            onClick={() =>
              onFilterChange({
                key: filter.key,
                active: true,
              })
            }
          >
            <AddIcon fontSize="small" style={{ width: 14 }} />
            {t('addCriteria')}
          </Button>
        )}
      </Box>
      {filter.active && (
        <Box className="flex flex-col p-16">
          {filter.type !== 'date' && (
            <CriteriaRuleSelect
              criteriaRule={filter.rule}
              onCriteriaRuleChange={handleCriteriaRuleChange}
              options={criteriaRuleOptions}
            />
          )}

          {!!filter.rule &&
            filter.selectedValues &&
            (filter.type === 'string' ? (
              <TextCriteriaContent
                selectedFilterOptions={filter.selectedValues}
                onSelectedFilterOptionsChange={handleSelectedOptionsChange}
                filter={filter}
              />
            ) : filter.type === 'number' ? (
              <>
                {filter.rule === 'range' && (
                  <Box className="flex items-center gap-16">
                    <CurrencyInputBox
                      defaultValue={filter.minNumber ? filter.minNumber / 100 : ''}
                      onChange={handleMinNumberChange}
                    />

                    <Typography fontWeight={400} color={'primary'}>
                      to
                    </Typography>

                    <CurrencyInputBox
                      defaultValue={filter.maxNumber ? filter.maxNumber / 100 : ''}
                      onChange={handleMaxNumberChange}
                    />
                  </Box>
                )}
                {filter.rule === 'moreThan' && (
                  <Box className="flex items-center">
                    <CurrencyInputBox
                      defaultValue={filter.minNumber ? filter.minNumber / 100 : ''}
                      onChange={handleMinNumberChange}
                    />
                  </Box>
                )}
                {filter.rule === 'lessThan' && (
                  <Box className="flex items-center">
                    <CurrencyInputBox
                      defaultValue={filter.maxNumber ? filter.maxNumber / 100 : ''}
                      onChange={handleMaxNumberChange}
                    />
                  </Box>
                )}
              </>
            ) : (
              <></>
            ))}
        </Box>
      )}
    </Box>
  );
};
