import { Button, Grid } from '@mui/material';
import AddUserForm from 'app/pages/team/users/AddUserForm';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import { useTranslation } from 'react-i18next';

interface AddTeamMembersFormProps {
  handleNext: () => void;
}

function AddTeamMembersForm({ handleNext }: Readonly<AddTeamMembersFormProps>) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        pt: 3,
      }}
    >
      <Grid item xs={12}>
        <ContainerComponent
          title={t('AddTeamMemberFirstRun.title')}
          description={t('AddTeamMemberFirstRun.description')}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddUserForm
          isStepperForm={true}
          renderButtons={({ isLoading, isValid, addPaidolUser }) => (
            <>
              <Button onClick={() => handleNext()} size="small">
                {t('illDoThisLater')}
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={isLoading || !isValid}
                onClick={async () => {
                  const status: any = await addPaidolUser();
                  if (!isLoading && status === 'fulfilled') {
                    // Adding a small delay before navigating to the next step
                    setTimeout(() => {
                      handleNext();
                    }, 3500); // Adjust the delay as needed (300ms in this example)
                  }
                }}
              >
                {t('inviteTeamMember')}
              </Button>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default AddTeamMembersForm;
