/* eslint-disable react/forbid-elements */
import { DialogActions, DialogActionsProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

const ResponsiveDialogActions = forwardRef(
  ({ sx, ...props }: DialogActionsProps, ref: Ref<HTMLDivElement>) => {
    return (
      <DialogActions
        {...props}
        sx={{
          m: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 1,
          '& button': {
            mt: { xs: 1, sm: 'inherit' },
            width: { xs: '100%', sm: 'inherit' },
          },
          ...sx,
        }}
      />
    );
  }
);

export default ResponsiveDialogActions;
