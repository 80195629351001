import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { HNAddress } from 'API';
import { selectSpecificCardSlice } from 'app/pages/store/specificCardSlice';
import { BorderedBox } from 'app/shared-components/layout/BorderedBox';
import { useAppSelector } from 'app/store';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COPIED_TO_CLIPBOARD } from 'util/constants';

type BillingAddressType = Omit<HNAddress, 'countryCodeAlpha3' | 'region' | '__typename'>;

const AddressItemBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  borderRadius: '8px',
  paddingLeft: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  whiteSpace: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  gap: 2,
}));

export const BillingAddress = () => {
  const { t } = useTranslation();

  const BillingAddressLabelMapping: Record<keyof BillingAddressType, string> = {
    streetAddress: t('address'),
    extendedAddress: t('apartmentSuiteEtc'),
    locality: t('cityOrTown'),
    postalCode: t('zip'),
  };

  const { paymentCardDetails } = useAppSelector(selectSpecificCardSlice);

  const [openTooltip, setOpen] = useState<Record<keyof BillingAddressType, boolean>>({
    streetAddress: false,
    extendedAddress: false,
    locality: false,
    postalCode: false,
  });

  const billingAddress = useMemo(() => {
    return paymentCardDetails?.paymentCardDetails?.paymentCardHolderDetails?.billingAddressOnCard;
  }, [paymentCardDetails]);

  const onCopy = (field: string) => {
    setOpen({ ...openTooltip, [field]: true });
    const fieldValue = document.getElementById(field)?.innerText;
    fieldValue && navigator.clipboard.writeText(fieldValue);
  };

  return (
    <BorderedBox sx={{ gap: 2, mx: 2, maxWidth: '300px' }}>
      <MarkunreadMailboxOutlinedIcon color="primary" />
      <Box className="flex-1">
        <Typography fontSize="16px" fontWeight={500} sx={{ color: 'primary.main', mb: 2 }}>
          {t('billingAddress')}
        </Typography>
        {renderAddressItem('streetAddress')}
        {billingAddress?.extendedAddress && renderAddressItem('extendedAddress')}
        {renderAddressItem('locality')}
        {renderAddressItem('postalCode', 'region')}
      </Box>
    </BorderedBox>
  );

  function renderAddressItem(field: keyof BillingAddressType, prefixField?: 'region') {
    return (
      <Box className="mb-8">
        <Typography fontSize="12px" fontWeight={400} sx={{ color: 'primary.main', mb: 0.5 }}>
          {BillingAddressLabelMapping[field]}
        </Typography>
        <Box className="flex items-center gap-8 flex-1">
          {!!prefixField && (
            <Typography fontSize={'16px'} fontWeight={600}>
              {billingAddress?.[prefixField]}
            </Typography>
          )}
          <Tooltip
            title={COPIED_TO_CLIPBOARD}
            placement="top"
            leaveDelay={500}
            open={!!openTooltip[field]}
            onClose={() => setOpen({ ...openTooltip, [field]: false })}
            arrow
          >
            <AddressItemBox>
              <Typography id={field} fontSize={'16px'} fontWeight={600} noWrap>
                {billingAddress?.[field]}
              </Typography>

              <IconButton onClick={() => onCopy(field)}>
                <ContentCopyOutlinedIcon color="primary" fontSize="small" />
              </IconButton>
            </AddressItemBox>
          </Tooltip>
        </Box>
      </Box>
    );
  }
};
