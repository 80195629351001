import { Logger } from 'aws-amplify';
import { LOG_TYPE } from '@aws-amplify/core/lib-esm/Logger';

let sharedInstance: Logger;

// Use outside of a Component
export default function logger(): Logger {
  if (!sharedInstance) {
    const level = process.env.REACT_APP_ENV === 'production' ? LOG_TYPE.ERROR : LOG_TYPE.INFO;
    sharedInstance = new Logger('app_logger', level);
  }

  return sharedInstance;
}

// Use w/in a Component
export function useLogger(): Logger {
  return logger();
}
