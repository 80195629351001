import { createSvgIcon } from '@mui/material';

const BudgetIcon = createSvgIcon(
  <>
    <path d="M18.6667 5.64706L12 0L5.33333 9.88235L0 5.64706V24H24V5.64706H18.6667ZM21.3333 19.6941L12 12L6.66667 19.7647L2.66667 16.3765V11.2941L5.92 13.8776L12.5333 4.06588L17.7333 8.47059H21.3333V19.6941Z" />
  </>,
  'Budget'
);

export default BudgetIcon;
