import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import BudgetIcon from '@mui/icons-material/AccountBalance'; // Replace with your Budget icon import
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { MerchantSpendRule } from 'API';
import { useAppSelector } from 'app/store';
import { selectSpendRulesSlice } from 'app/pages/store/spendRulesSlice';
interface MaximumTransactionAmountProps {
  selectedSpendRule?: MerchantSpendRule;
}

const MaximumTransactionAmountSpendRule: React.FC<MaximumTransactionAmountProps> = ({
  selectedSpendRule,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const { rules: highnoteRules } = useAppSelector(selectSpendRulesSlice);
  const amountLimitId = selectedSpendRule?.amountLimitSpendRuleId;

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: '16px',
        background: 'var(--Blues-Highlight-Blue, #F2F6FF)',
      }}
    >
      <Controller
        name="maximumTransactionAmount"
        control={control}
        rules={{
          min: { value: 0, message: t('Minimum amount should be 0') },
          validate: (value) => !value || value > 0 || t('Amount must be greater than 0'),
        }}
        render={({ field }) => (
          <TextField
            {...field}
            type="number"
            error={!!errors.maximumTransactionAmount}
            helperText={
              typeof errors?.maximumTransactionAmount?.message === 'string'
                ? errors.maximumTransactionAmount.message
                : undefined
            }
            label={t('Maximum transaction amount')}
            placeholder={t('No maximum')}
            id="maximumTransactionAmount"
            variant="outlined"
            defaultValue={
              (amountLimitId ? (highnoteRules.get(amountLimitId)?.maximumAmount?.value ?? 0) / 100 : '') ?? ''
            }
            disabled={
              watch('spendRuleSelector') === 'existing' || !!selectedSpendRule?.amountLimitSpendRuleId
            }
            fullWidth
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">
                  <BudgetIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default MaximumTransactionAmountSpendRule;
