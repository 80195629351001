import { Role } from 'API';
import { useUserHasRole } from 'app/shared-components/auth/AuthProvider';

export interface RequireUserRoleProps {
  role: Role;
  LoadingComponent?: JSX.Element;
  FallbackComponent?: JSX.Element;
  children: React.ReactNode;
}

function RequireUserRole({
  role,
  LoadingComponent,
  FallbackComponent,
  children,
}: RequireUserRoleProps): JSX.Element {
  const userHasRole = useUserHasRole(role);

  if (LoadingComponent && userHasRole === undefined) {
    return LoadingComponent;
  }

  if (FallbackComponent && !userHasRole) {
    return FallbackComponent;
  }

  if (userHasRole) {
    return <>{children}</>;
  }

  return <></>;
}

export type RequireAdministratorProps = Omit<RequireUserRoleProps, 'role'>;

export function RequireAdministrator(props: RequireAdministratorProps): JSX.Element {
  return <RequireUserRole role={Role.ADMINISTRATOR} {...props} />;
}

export function RequireGroupAdministrator(props: RequireAdministratorProps): JSX.Element {
  return <RequireUserRole role={Role.GROUP_ADMINISTRATOR} {...props} />;
}

export function RequireMember(props: RequireAdministratorProps): JSX.Element {
  return <RequireUserRole role={Role.MEMBER} {...props} />;
}

export default RequireUserRole;
