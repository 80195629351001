import { Button, Grid } from '@mui/material';
import { ConnectFundingAccountWidget } from 'app/pages/cards/funding/ConnectFundingAccountWidget';
import { useTranslation } from 'react-i18next';
import ContainerComponent from 'app/shared-components/container-component/container-component';

interface AddMemeberFormProps {
  handleNext: () => void;
}

const ConnectBankForm = ({ handleNext }: AddMemeberFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        pt: 3,
      }}
    >
      <Grid item xs={12}>
        <ContainerComponent
          title={t('financialAccountFirstRun.title')}
          description={t('financialAccountFirstRun.description')}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ConnectFundingAccountWidget messageType="stepper" />
        <Button variant="text" size="small" onClick={handleNext} sx={{ mt: 5 }}>
          {t('illDoThisLater')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConnectBankForm;
