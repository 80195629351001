import { CardGroupBudgetSpendByMonth, HighnoteProductType } from 'API';
import { format } from 'date-fns';

export const getMonthlySpendFromMonthlyBudgets = (
  items: (CardGroupBudgetSpendByMonth | null)[] | undefined
): number => {
  const currentYearMonth = format(new Date(), 'yyyy-MM');
  return (
    (items?.filter((item) => item !== null) as CardGroupBudgetSpendByMonth[])?.find(
      (item) => item.yearAndMonth === currentYearMonth
    )?.amount ?? 0
  );
};

export const getCardTypeDescription = (cardType?: HighnoteProductType) =>
  cardType === HighnoteProductType.CREDIT ? 'Business Credit' : 'Business Debit';
