import { Box, SxProps, Theme } from '@mui/material';

interface SideSheetContentProps {
  children: React.ReactNode;
  padding?: number;
  sx?: SxProps<Theme>;
}

function SideSheetContent({ children, padding, sx }: SideSheetContentProps): JSX.Element {
  return (
    <Box
      sx={{
        p: 2,
        minWidth: { xs: '100vw', md: 'unset' },
        flexGrow: 1,
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default SideSheetContent;
