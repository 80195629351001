import { combineReducers } from '@reduxjs/toolkit';
import unreadMessages from 'app/pages/overview/store/unreadMessagesSlice';
import type { UnreadMessagesState } from 'app/pages/overview/store/unreadMessagesSlice';
import priorityActions from 'app/pages/overview/store/priorityActionsSlice';
import type { PriorityActionsState } from 'app/pages/overview/store/priorityActionsSlice';

export interface OverviewState {
  priorityActions: PriorityActionsState;
  unreadMessages: UnreadMessagesState;
}

const reducer = combineReducers({
  priorityActions,
  unreadMessages,
});

export default reducer;
