import { useRef, useEffect, useState } from 'react';
import { debounce as lodashDebounce } from 'lodash';

// Adapted from https://github.com/louisgv/react-scroll-bottom-hook/blob/master/src/useScrollBottom.ts

interface UseScrollBottomProps {
  offset?: number;
  debounce?: number;
  ref?: any;
}

const useScrollBottom = ({ offset = 0, debounce = 100, ref }: UseScrollBottomProps = {}): [
  boolean,
  React.Ref<HTMLElement>
] => {
  const [isBottom, setIsBottom] = useState(false);
  const scrollRef = useRef<HTMLElement>(null);
  const innerScrollRef = ref || scrollRef;
  const onScroll = lodashDebounce(() => {
    if (innerScrollRef.current) {
      setIsBottom(
        innerScrollRef.current.scrollTop >=
          innerScrollRef.current.scrollHeight - innerScrollRef.current.clientHeight - offset
      );
    }
  }, debounce);

  useEffect(() => {
    const localRef = innerScrollRef.current;

    localRef?.addEventListener('scroll', onScroll);

    return () => {
      localRef?.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return [isBottom, innerScrollRef];
};

export default useScrollBottom;
