import { Box } from '@mui/system';
import { HighnoteProductType, HNUserError } from 'API';
import { selectFundingSlice } from 'app/pages/store/fundingSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import { useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import Permission from 'app/pages/review/Permission';
import Loading from 'app/shared-components/util/Loading';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AdditionalDocuments from './AdditionalDocuments';

function ReviewOnboard(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { fundingBankAccount } = useAppSelector(selectFundingSlice);
  const [hasConnectedFundingBankAccount, setHasConnectedFundingBankAccount] = useState(false);
  const { integration, application, hasSubmittedApplication, hasExternalAccountId, isOnboardedToPcards } =
    useAppSelector(selectReviewOnboardSlice);

  const isDebitProduct = useMemo(() => {
    return integration?.highnoteProduct?.type === HighnoteProductType.DEBIT;
  }, [integration]);

  //NOTE: This function may not be relavant for current onboarding procedures
  const afterTryingToConnectFundingBankAccount = useCallback(() => {
    if (!hasConnectedFundingBankAccount) {
      const paidolId = selectedPaidol?.id;
      const businessAccountHolderId = integration?.businessAccountHolderId;
      const userId = user?.sub;

      if (!businessAccountHolderId || !userId || !paidolId) {
        throw new Error('Missing required IDs: HN business account holder ID, user ID, or Paidol ID');
      }

      const errors = (fundingBankAccount as HNUserError).errors;
      if (errors && errors.length > 0) {
        const errorDescription = errors[0].description
          ?.split('.')
          .map((sentence) => {
            const trimmedSentence = sentence.trim();
            return (
              trimmedSentence.charAt(0).toUpperCase() +
              trimmedSentence.slice(1) +
              (trimmedSentence.slice(-1) === '.' ? '' : '. ')
            );
          })
          .join('');

        enqueueSnackbar(`Error adding bank account. ${errorDescription}`, {
          variant: 'error',
          autoHideDuration: 10000,
        });
        return;
      }

      if (!integration.hasInitialFunding && isDebitProduct) {
        setHasConnectedFundingBankAccount(true);

        return;
      }
      setHasConnectedFundingBankAccount(true);
    }
  }, [
    enqueueSnackbar,
    fundingBankAccount,
    hasConnectedFundingBankAccount,
    integration?.businessAccountHolderId,
    integration?.hasInitialFunding,
    isDebitProduct,
    selectedPaidol?.id,
    user?.sub,
  ]);

  useEffect(() => {
    if (fundingBankAccount && integration) {
      afterTryingToConnectFundingBankAccount();
    }
  }, [afterTryingToConnectFundingBankAccount, fundingBankAccount, integration, isOnboardedToPcards]);

  useDeepCompareEffect(() => {
    if (integration?.isApplicationAccepted === false && application?.needDocuments === true) {
      navigate('/review/additionalDocuments', { replace: true });
    } else if (integration?.isApplicationAccepted === false) {
      navigate('/review/permission', { replace: true });
    }
  }, [navigate, integration?.isApplicationAccepted, application?.needDocuments]);

  return (
    <Box
      sx={{
        position: 'relative',
        padding: 4,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Routes>
          <Route index element={<Loading />} />
          <Route path="permission" element={<Permission />} />
          <Route path="additionalDocuments" element={<AdditionalDocuments />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default ReviewOnboard;
