import { Box, styled } from '@mui/material';

export const BorderedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.primary.light,
  borderRadius: '16px',
  padding: '24px',
  flex: 1,
}));
