import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  minWidth: '100%',
  minHeight: '194px',
  background: 'var(--Gradient, linear-gradient(270deg, #324C80 52.86%, #141F33 84.63%))',

  '& > *': {
    flex: 1,
    marginLeft: '24px',
  },
});
