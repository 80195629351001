import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/store';
import { Box, Typography } from '@mui/material';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import FinicityIntegrationPaidolContainer from 'app/shared-components/util/FinicityIntegration/FinicityIntegrationPaidolContainer';
import theme from 'app/theme';
import { WidgetContainer } from 'app/shared-components/ui-components/WidgetContainer';

interface ConnectFundingWidgetProps {
  messageType: 'funding' | 'stepper';
}

export const ConnectFundingAccountWidget = ({ messageType }: ConnectFundingWidgetProps) => {
  const { selectedPaidol: paidol } = useAppSelector(selectUserCompanies);
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { t } = useTranslation();

  return (
    <>
      {integration && paidol && (
        <WidgetContainer
          sx={{
            justifyContent: 'space-between',
            alignItems: messageType === 'funding' ? 'center' : 'flex-start',
            width: '565px',
            p: 3,
            gap: '24px',
          }}
        >
          <FinicityIntegrationPaidolContainer
            integration={integration}
            paidol={paidol}
            hasConnectedFinancialAccount={Boolean(integration.externalFinancialBankAccountId)}
            stylingType="onboarding"
          />
          <Box sx={{ width: '280px' }}>
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.primary.main,
                pb: 1,
              }}
            >
              {t('connectFundingWidget.header')}
            </Typography>
            <Typography>
              {messageType === 'funding' ? t('connectFundingWidget.body2') : t('connectFundingWidget.body1')}
            </Typography>
            {messageType === 'funding' && (
              <Typography sx={{ pt: 2 }}>{t('connectFundingWidget.body1')}</Typography>
            )}
          </Box>
        </WidgetContainer>
      )}
    </>
  );
};
