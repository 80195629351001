import { SxProps, TableCell, TableSortLabel, Theme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DropdownFilterChip } from 'app/shared-components/layout/action-bar/DropdownComponent';
import { useAppSelector } from 'app/store';
import { selectSearchAndFilter } from 'app/store/searchAndFilterSlice';
import { getPageName } from 'util/helpers';

export interface ResponsiveTableHeadCellProps {
  id: string;
  label: React.ReactNode;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  disablePadding?: boolean;
  sortable?: boolean;
  orderBy?: string;
  sortOrder?: 'asc' | 'desc';
  width?: string | number | undefined;
  sx?: SxProps<Theme>;
  searchable?: boolean;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
}

function ResponsiveTableHeadCell({
  id,
  label,
  align,
  disablePadding = false,
  sortable = false,
  orderBy,
  sortOrder,
  width,
  sx,
  searchable,
  onRequestSort,
}: ResponsiveTableHeadCellProps): JSX.Element {
  const pageName = getPageName(window.location.pathname);
  const tableFilters = useAppSelector(selectSearchAndFilter)[pageName];
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableCell
      sortDirection="asc"
      key={id}
      align={align}
      padding={disablePadding ? 'none' : 'normal'}
      width={width}
      sx={{ ...{ ...sx, minWidth: searchable ? '300px' : 'auto' } }}
    >
      {sortable && (
        <TableSortLabel
          IconComponent={ArrowDropDownIcon}
          active={orderBy === id}
          direction={orderBy === id ? sortOrder : 'asc'}
          onClick={createSortHandler(id)}
        >
          {label}
        </TableSortLabel>
      )}
      {!sortable && !searchable && label}
      {!!searchable && !!tableFilters && <DropdownFilterChip filter={tableFilters.columnFilters[id]} />}
    </TableCell>
  );
}

export default ResponsiveTableHeadCell;
