import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  SearchHighnotePaymentCardsQuery,
  HighnotePaymentCard,
  HNPaymentCardStatus,
  ReimbursementTransaction,
  ReimbursementTransactionStatus,
  GetHighnotePaymentCardQuery,
  ListReimbursementTransactionsByPaidolIdQuery,
} from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import { GetHighnotePaymentCard, SearchHighnotePaymentCards, SearchHighnoteTransactions } from './queries';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import { reimbursementTransactionsByPaidolId } from 'graphql_custom/queries';

const initialState = {
  loading: false,
  inactiveCards: [] as HighnotePaymentCard[],
  unactivatedCards: [] as HighnotePaymentCard[],
  reimbursements: [] as ReimbursementTransaction[],
  missingJobCodes: 0,
};

export type priorityActionsState = typeof initialState;

export const getInactiveCards = createAsyncThunk(
  'cards/priorityActions/getInactiveCards',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(SearchHighnotePaymentCards, {
          filter: {
            status: { eq: HNPaymentCardStatus.SUSPENDED },
            paidolId: { eq: paidolId },
          },
        })
      ) as Promise<GraphQLResult<SearchHighnotePaymentCardsQuery>>
    ).then((result) => result.data?.searchHighnotePaymentCards?.items as Array<HighnotePaymentCard>);
  }
);

export const getUnactivatedCards = createAsyncThunk(
  'cards/priorityActions/getUnactivedCards',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(SearchHighnotePaymentCards, {
          filter: {
            status: { eq: HNPaymentCardStatus.ACTIVATION_REQUIRED },
            paidolId: { eq: paidolId },
          },
        })
      ) as Promise<GraphQLResult<SearchHighnotePaymentCardsQuery>>
    ).then((result) => result.data?.searchHighnotePaymentCards?.items as Array<HighnotePaymentCard>);
  }
);

export const getMissingJobCodes = createAsyncThunk(
  'cards/prioityActions/getMissingJobCodes',
  async (paidolId: string, { dispatch }) => {
    const result = (await API.graphql(
      graphqlOperation(SearchHighnoteTransactions, {
        filter: {
          jobCodeId: { exists: false },
          paidolId: { eq: paidolId },
        },
      })
    )) as {
      data: {
        searchHighnoteTransactions: {
          total: number;
        };
      };
    };

    return result.data?.searchHighnoteTransactions?.total || 0;
  }
);

export const getReimbursements = createAsyncThunk(
  'cards/priorityActions/getReimbursements',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(reimbursementTransactionsByPaidolId, {
          paidolId: paidolId,
          filter: {
            status: { eq: ReimbursementTransactionStatus.REQUESTED },
          },
        })
      ) as Promise<GraphQLResult<ListReimbursementTransactionsByPaidolIdQuery>>
    ).then(
      (result) =>
        result.data?.listReimbursementTransactionsByPaidolId?.items as Array<ReimbursementTransaction>
    );
  }
);

export const getHighnotePaymentCardDetails = createAsyncThunk(
  'cards/priorityActions/getHighnotePaymentCardDetails',
  async (paymentCardId: string) => {
    return (
      API.graphql(
        graphqlOperation(GetHighnotePaymentCard, {
          paymentCardId,
        })
      ) as Promise<GraphQLResult<GetHighnotePaymentCardQuery>>
    ).then((result) => result.data?.getHighnotePaymentCard);
  }
);

const priorityActionsSlice = createSlice({
  name: 'cards/priorityActions',
  initialState,
  reducers: {
    resetPriorityActionsSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getInactiveCards.fulfilled, (state, action) => {
      if (action.payload) {
        state.inactiveCards = action.payload;
      }
    });

    builder.addCase(getUnactivatedCards.fulfilled, (state, action) => {
      if (action.payload) {
        state.unactivatedCards = action.payload;
      }
    });

    builder.addCase(getMissingJobCodes.fulfilled, (state, action) => {
      if (action.payload) {
        state.missingJobCodes = action.payload;
      }
    });

    builder.addCase(getReimbursements.fulfilled, (state, action) => {
      if (action.payload) {
        state.reimbursements = action.payload;
      }
    });
  },
});

export const { resetPriorityActionsSlice } = priorityActionsSlice.actions;

export const selectPriorityActionsSlice = (state: RootState) => state.cards?.priorityActions ?? initialState;

export default priorityActionsSlice.reducer;
