import { Box } from '@mui/material';
import { determineEnvironment } from 'app/pages/onboard/utils';

interface EnvironmentStripeProps {
  children?: React.ReactNode;
}

function EnvironmentStripe({ children }: EnvironmentStripeProps): JSX.Element {
  const environment = determineEnvironment();
  const environmentName = environment !== 'production' && environment;

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: '100vw',
        overflow: 'hidden',
      }}
    >
      {environmentName && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            textTransform: 'uppercase',
            padding: '5px 0',
            color: '#FFF',
            backgroundColor: '#EB5757',
          }}
        >
          {environmentName} environment
        </Box>
      )}
      {children}
    </Box>
  );
}

export default EnvironmentStripe;
