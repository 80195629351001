import { LocalStorageTokens } from 'app/hooks/useFetchProcoreAuthToken';
import { PROCORE_TOKENS_KEY } from 'app/pages/store/procoreSlice';
import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const PROCORE_URL = `${process.env.REACT_APP_PROCORE_SIGN_IN_URL}${window.location.href}`;

export function useProcoreIntegration() {
  const [tokens] = useLocalStorage<LocalStorageTokens>(PROCORE_TOKENS_KEY, {
    accessToken: '',
    refreshToken: '',
  });

  const loading = useMemo(
    () =>
      Boolean(
        new URL(window.location.href).searchParams.get('code') && !tokens.accessToken && !tokens.refreshToken
      ),
    [tokens]
  );

  const connected = useMemo(() => Boolean(tokens.accessToken && tokens.refreshToken), [tokens]);

  return {
    loading,
    connected,
    procoreUrl: PROCORE_URL,
  };
}
