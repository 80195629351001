import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IssuePaymentCardMutation, IssuePaymentCardMutationVariables } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import { issuePaymentCard } from 'graphql/mutations';

export interface IssueCardState {
  loading: boolean;
}

const initialState: IssueCardState = {
  loading: false,
};

export const issuePaymentCardForFinancialAccount = createAsyncThunk(
  'cards/card/issuePaymentCard',
  async ({ input }: IssuePaymentCardMutationVariables, { rejectWithValue }) => {
    try {
      return (
        API.graphql(graphqlOperation(issuePaymentCard, { input })) as Promise<
          GraphQLResult<IssuePaymentCardMutation>
        >
      ).then((results) => results.data?.issuePaymentCard);
    } catch (error: Error | unknown) {
      return rejectWithValue(error);
    }
  }
);

const issueCardSlice = createSlice({
  name: 'cards/card',
  initialState,
  reducers: {
    resetIssueCardSlice: () => initialState,
  },
});

export const { resetIssueCardSlice } = issueCardSlice.actions;

export default issueCardSlice.reducer;
