/* eslint-disable react/forbid-elements */
import { Dialog, DialogProps, Slide, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import useIsMobile from 'app/hooks/useIsMobile';
import { forwardRef, Ref } from 'react';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const ResponsiveDialog = forwardRef(
  ({ sx, ...props }: DialogProps, ref: Ref<HTMLDivElement>): JSX.Element => {
    const theme = useTheme();
    const fullScreen = useIsMobile();

    return (
      <Dialog
        {...props}
        sx={{ marginTop: { xs: 16, sm: undefined }, ...sx }}
        ref={ref}
        fullScreen={fullScreen}
        TransitionComponent={fullScreen ? Transition : undefined}
      />
    );
  }
);

export default ResponsiveDialog;
