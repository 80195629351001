import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import withTransientProps from 'app/shared-components/util/withTransientProps';

type BorderLinearProgressColorType = 'GREEN' | 'RED' | 'YELLOW';

export const BorderLinearProgress = styled(
  LinearProgress,
  withTransientProps
)<{ $color?: BorderLinearProgressColorType; $height?: number }>(({ theme, $color, $height = 6 }) => {
  const colorMap = {
    GREEN: theme.palette.success.main,
    RED: theme.palette.error.main,
    YELLOW: theme.palette.warning.main,
  };

  return {
    height: $height,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#BDBDBD',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: $color ? colorMap[$color] : '#27AE60',
    },
  };
});
