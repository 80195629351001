import { CircularProgress, Fade } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';

interface LoadingProps {
  fullPage?: boolean;
}

function Loading({ fullPage = true }: LoadingProps): JSX.Element {
  const [shouldAppear, setShouldAppear] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldAppear(true);
    }, 500);

    return () => {
      setShouldAppear(false);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={
        fullPage
          ? {
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }
          : {
              display: 'flex',
              position: 'relative',
              width: '100%',
              height: '100%',
              flexGrow: 1,
            }
      }
    >
      <Box sx={{ margin: 'auto' }}>
        <Fade in={shouldAppear}>
          <CircularProgress size={fullPage ? 100 : 32} />
        </Fade>
      </Box>
    </Box>
  );
}

export default Loading;
