import useIsMobile from 'app/hooks/useIsMobile';
import { ReactNode } from 'react';

export interface MobileViewProps {
  children: ReactNode;
}

function MobileView({ children }: MobileViewProps): JSX.Element {
  const isMobile = useIsMobile();

  return <>{isMobile && children}</>;
}

export default MobileView;
