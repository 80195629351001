import { useState, useEffect } from 'react';
import { HNPaymentCardClientTokenPermission } from 'API';
import { SecureInputsRenderedFields, renderFields, SecureInputsError } from '@highnoteplatform/secure-inputs';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  activatePaymentCard,
  generatePaymentCardClientToken,
  selectSpecificCardSlice,
  updateHighnotePaymentCard,
} from 'app/pages/store/specificCardSlice';
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ResponsiveButtonContainer from 'app/shared-components/layout/ResponsiveButtonContainer';
import { PinWrapper } from './Style';
import { LoadingButton } from '@mui/lab';

interface Props {
  paymentCardId: string;
  isPhysical?: boolean;
  btnLoading?: boolean;
  setBtnLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const PinSDK = ({ paymentCardId, isPhysical, btnLoading, setBtnLoading, onCancel, onSubmit }: Props) => {
  const dispatch = useAppDispatch();

  const { status } = useAppSelector(selectSpecificCardSlice);

  const [sdk, setSdk] = useState<SecureInputsRenderedFields>();
  const [checked, setChecked] = useState(false);
  const [checkedPhysical, setCheckedPhysical] = useState(false);
  const [token, setToken] = useState('');

  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [loadingToken, setLoadingToken] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const ViewSecureInputsSDK = async () => {
    return await renderFields({
      environment: process.env.REACT_APP_ENV === 'production' ? 'live' : 'test',
      elements: {
        pin: {
          clientToken: token,
          paymentCardId: paymentCardId,
          selector: '#pin',
          styles: {
            fontSize: '14px',
            fontWeight: '400',
            cursor: 'pointer',
          },
        },
      },

      onInput: (field) => {
        // eslint-disable-next-line no-console
        console.log('onInput:', field);
      },

      onSuccess: () => {
        setLoadingSave(false);
        setError(false);
        onSubmit && onSubmit();
        setMessage('PIN changed successfully.');
      },

      onError: (error: SecureInputsError) => {
        setLoadingSave(false);

        switch (error.name) {
          case 'SecureInputsFieldInputError':
            // Handle user input errors such as invalid pin
            setMessage(error.message);
            setError(true);
            break;

          default:
            // eslint-disable-next-line no-console
            console.warn(error);
        }
      },
    });
  };

  useEffect(() => {
    dispatch(
      generatePaymentCardClientToken({
        paymentCardId: paymentCardId,
        permissions: [HNPaymentCardClientTokenPermission.SET_PAYMENT_CARD_PIN],
      })
    )
      .unwrap()
      .then((response) => setToken(response));
  }, [dispatch, paymentCardId]);

  useEffect(() => {
    setLoadingToken(true);

    if (token) {
      ViewSecureInputsSDK()
        .then((response) => setSdk(response))
        .finally(() => {
          setLoadingToken(false);
        });
    }
    return () => {
      sdk?.unmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const activateCard = async () => {
    if (status === 'ACTIVATION_REQUIRED') {
      await dispatch(activatePaymentCard({ paymentCardId })).unwrap();
    }
  };

  const handleSubmit = async () => {
    try {
      setLoadingSave(true);
      setBtnLoading && setBtnLoading(true);

      await activateCard();

      sdk?.submit();

      await dispatch(updateHighnotePaymentCard({ paymentCardId, hasPin: true }));
    } finally {
      setBtnLoading && setBtnLoading(false);
    }
  };

  return (
    <PinWrapper className="has-float-label">
      {!loadingToken ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body1" fontSize="1.2rem" fontWeight="400">
            Card PIN
          </Typography>
        </Box>
      ) : null}

      <div id="pin"></div>

      {loadingToken ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={256}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <>
          <Typography variant="body1" fontSize="1.2rem" fontWeight="400">
            Must be a 4-digit numeric PIN
          </Typography>
          <Typography
            height="2rem"
            variant="body1"
            fontSize="1.2rem"
            fontWeight="400"
            mb={1}
            color={error ? 'error.main' : 'success.main'}
          >
            {message}
          </Typography>
          {isPhysical && (
            <FormGroup sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedPhysical}
                    onChange={() => setCheckedPhysical((checkedPhysical) => !checkedPhysical)}
                  />
                }
                label="This card is physically in my possession"
              />
            </FormGroup>
          )}
          <FormGroup sx={{ mb: 4 }}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={() => setChecked((checked) => !checked)} />}
              label="I understand that I need to remember my PIN as Speedchain can't retrieve it for me"
            />
          </FormGroup>

          <ResponsiveButtonContainer>
            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <LoadingButton
              loading={btnLoading}
              variant="contained"
              sx={{ px: 4 }}
              size="medium"
              onClick={handleSubmit}
              disabled={!checked || (isPhysical && !checkedPhysical) || loadingSave}
            >
              Save
            </LoadingButton>
          </ResponsiveButtonContainer>
        </>
      )}
    </PinWrapper>
  );
};

export default PinSDK;
