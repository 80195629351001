import useSidesheetById from 'app/hooks/useSidesheetById';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import AssignCardForm from './AssignCardForm';

export type AssignCardSidesheetParams = {
  paymentCardId: string;
  onSuccess: () => void;
};

function AssignCardSidesheet() {
  const sidesheet = useSidesheetById(SidesheetRoutes.AssignCard);
  const { closeSidesheet } = useSidesheet();

  const handleOnClose = () => closeSidesheet(SidesheetRoutes.AssignCard);

  const params: AssignCardSidesheetParams = sidesheet.params;

  return (
    <AssignCardForm
      onClose={handleOnClose}
      selectedCardId={params.paymentCardId}
      onSuccess={params.onSuccess}
    />
  );
}

export default AssignCardSidesheet;
