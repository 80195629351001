import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { BoldText } from 'app/shared-components/util/BoldText';

export const CriteriaRuleSelect = ({
  criteriaRule,
  onCriteriaRuleChange,
  options,
}: {
  criteriaRule: string;
  onCriteriaRuleChange: (event: SelectChangeEvent) => void;
  options: { value: string; label: string }[];
}) => {
  return (
    <FormControl variant="standard" className="mb-16" fullWidth>
      <Select displayEmpty value={criteriaRule} onChange={onCriteriaRuleChange} disableUnderline>
        <MenuItem disabled value="">
          <em>No restrictions</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box className="flex gap-4">
              <BoldText>{option.label}</BoldText>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
