import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/store';
import { signOut } from 'app/store/authSlice';
import { closeDrawer, useNavigationState } from 'app/store/navigationSlice';
import { selectUserProfile } from 'app/store/userProfileSlice';
import CompanySwitcher from 'app/shared-components/layout/CompanySwitcher';
import { NavigationItem } from './NavBar';
import theme from 'app/theme';

const iconProps = {
  fontSize: 'small' as const,
  htmlColor: theme.palette.primary.main,
};

const typographyProps = {
  variant: 'small' as const,
  color: theme.palette.text.primary,
};

interface MobileNavBarProps {
  navigationElements: Array<NavigationItem>;
}

function MobileNavBarButton(item: NavigationItem): JSX.Element {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeDrawer());
  };
  const hasAccess = item.hasAccess || item.hasAccess === undefined;

  return (
    <>
      {hasAccess && (
        <ListItemButton key={item.id} component={Link} href={item.href} onClick={onClose}>
          <ListItemIcon>
            <item.IconComponent {...iconProps} />
          </ListItemIcon>
          <ListItemText primary={item.label} primaryTypographyProps={typographyProps} />
        </ListItemButton>
      )}
    </>
  );
}

function MobileNavBar({ navigationElements }: MobileNavBarProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { drawerOpen } = useNavigationState();
  const { profile, picture } = useAppSelector(selectUserProfile);

  const onClose = () => {
    dispatch(closeDrawer());
  };

  const onLogout = () => {
    dispatch(signOut());
  };

  return (
    <Drawer anchor="left" open={drawerOpen} onClose={onClose}>
      <Box sx={{ minWidth: '80vw' }}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Avatar
            alt="user photo"
            src={picture !== '' ? picture : 'assets/images/avatars/profile.jpg'}
            sx={{ width: 32, height: 32 }}
          />
          {profile && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="large">
                <strong>
                  {profile.first_name} {profile.last_name}
                </strong>
              </Typography>
            </Box>
          )}
          <CompanySwitcher sx={{ mt: 1, mb: 1 }} />
        </Box>
        <List sx={{ ml: 2 }}>
          <Divider />
          {navigationElements.map((item) => (
            <MobileNavBarButton key={item.id} {...item} />
          ))}

          <Divider />
          <ListItemButton component={Link} href="/profile" onClick={onClose}>
            <ListItemIcon>
              <PersonIcon {...iconProps} />
            </ListItemIcon>
            <ListItemText primary="Profile" primaryTypographyProps={typographyProps}>
              Profile
            </ListItemText>
          </ListItemButton>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <ExitToAppIcon {...iconProps} />
            </ListItemIcon>
            <ListItemText primary="Logout" primaryTypographyProps={typographyProps}>
              Profile
            </ListItemText>
          </ListItemButton>
        </List>
      </Box>
    </Drawer>
  );
}

export default MobileNavBar;
