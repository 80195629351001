import type { RootState } from 'app/store/rootReducer';
import { createSlice } from '@reduxjs/toolkit';

export interface DialogState {
  isOpen: boolean;
  options: {
    children: React.ReactNode;
  };
}

const initialState: DialogState = {
  isOpen: false,
  options: {
    children: 'Hi',
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    resetDialogSlice: () => initialState,
    openDialog: (state, action) => {
      state.isOpen = true;
      state.options = action.payload;
    },
    closeDialog: (state) => {
      state.isOpen = false;
    },
  },
});

export const { resetDialogSlice, openDialog, closeDialog } = dialogSlice.actions;

export const selectDialog = (state: RootState): DialogState => state.dialog as DialogState;

export default dialogSlice.reducer;
