import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { HighnoteProductType, Role } from 'API';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';
import { NavigationItem } from 'app/shared-components/layout/navbar/NavBar';
import { useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//import PayablesIcon from 'app/shared-components/icons/PayablesIcon';

export const useNavigationMenu = (filterById?: string) => {
  const {
    'walletFlags.isWalletEnabled': showWallet,
    'firstRunStateFlags.isHomeTabEnabled': showHomeTab,
    'firstRunStateFlags.isStepperEnabled': isStepperEnabled,
    'reimbursementsFlags.showReimbursements': showReimbursements,
    'flexCoding.enabled': isFlexCodingEnabled,
  } = useIsEnabled([
    { featureName: 'walletFlags', flagName: 'isWalletEnabled' },
    { featureName: 'firstRunStateFlags', flagName: 'isHomeTabEnabled' },
    { featureName: 'firstRunStateFlags', flagName: 'isStepperEnabled' },
    { featureName: 'reimbursementsFlags', flagName: 'showReimbursements' },
    { featureName: 'flexCoding', flagName: 'enabled' },
  ]);

  const { t } = useTranslation();
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidol, userRole } = useAppSelector(selectUserCompanies);
  const isReimbursementsEnabled = !!selectedPaidol?.reimbursementSettings?.isEnabled;

  const isCardholder = Role.ADMINISTRATOR !== userRole;
  const isAdministrator = Role.ADMINISTRATOR === userRole;
  const isGroupAdministrator = Role.GROUP_ADMINISTRATOR === userRole;

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const isStepperComplete = selectedPaidol?.firstRunChecklist?.isStepperComplete && isStepperEnabled;
  const isWelcomeDismissed = selectedPaidol?.firstRunChecklist?.dismissWelcomePage;
  const isAdminAccess = isAdministrator && (!isStepperEnabled || isStepperComplete);

  const navigationElements: Array<NavigationItem> = useMemo(
    () =>
      [
        {
          id: 'overview',
          label: t('home'),
          href: '/overview',
          IconComponent: TrackChangesIcon,
          hasAccess: true,
          tabs: [
            {
              label: t('welcome'),
              href: '/overview',
              hasAccess: Boolean(isAdministrator && !isWelcomeDismissed && showHomeTab),
            },
            {
              label: t('insights'),
              href: isWelcomeDismissed ? '/overview' : '/overview/insights',
              hasAccess: isAdministrator,
            },
            {
              label: t('transactions'),
              href: '/overview/transactions',
              hasAccess: Boolean(isAdministrator || isGroupAdministrator),
            },
            {
              label: t('myTransactions'),
              href: '/overview/my-transactions',
              hasAccess: isCardholder,
            },
            {
              label: t('reimbursements'),
              href: '/overview/reimbursements',
              hasAccess: Boolean(showReimbursements && isAdministrator),
            },
            {
              label: t('myReimbursements'),
              href: '/overview/my-reimbursements',
              hasAccess: Boolean(
                (isCardholder || isGroupAdministrator) && showReimbursements && isReimbursementsEnabled
              ),
            },
          ],
        },
        {
          id: 'cards',
          label: t('cards'),
          href: '/cards',
          IconComponent: CreditCardIcon,
          hasAccess: true,
          tabs: [
            {
              label: t('cardManagement'),
              href: '/cards/payment-cards',
              hasAccess: Boolean(isAdministrator),
            },
            {
              label: t('myCards'),
              href: '/cards/my-cards',
              hasAccess: isCardholder,
            },
            {
              label: t('spendRulesTab'),
              href: '/cards/spend-rules',
              hasAccess: isAdministrator,
            },
            {
              label: t('manageCredit'),
              href: '/cards/credit',
              hasAccess: isAdministrator && integration?.highnoteProduct?.type === HighnoteProductType.CREDIT,
            },
            {
              label: t('debitFunding'),
              href: '/cards/funding',
              hasAccess: isAdministrator && integration?.highnoteProduct?.type === HighnoteProductType.DEBIT,
            },
          ],
        },

        /* Not being used. Vince said to comment it out! */
        /* {
          id: 'payables',
          label: t('payables'),
          href: '/payables',
          IconComponent: PayablesIcon,
          hasAccess: Boolean(
            !isStepperComplete &&
              !!selectedPaidol?.enablePayables &&
              !isCardholder &&
              !!integration?.externalFinancialBankAccountId
          ),
          tabs: [
            { label: t('payable'), href: '/payables' },
            { label: t('paymentFileUpload'), href: '/payables/file-upload' },
          ],
        }, */
        {
          id: 'team',
          label: 'Organization',
          href: '/team',
          IconComponent: PeopleIcon,
          hasAccess: true,
          tabs: [
            { label: t('teamMembers'), href: '/team/members' },
            {
              label: t('groups'),
              href: '/team/groups',
              hasAccess: Boolean(isAdministrator || isGroupAdministrator),
            },
            { label: 'Speedchat', href: '/team/speedchat', hasAccess: isCardholder },
          ],
        },
        {
          id: 'wallet',
          label: 'Wallet',
          href: '/wallet',
          IconComponent: AccountBalanceWalletIcon,
          hasAccess: Boolean(isAdminAccess && showWallet),
          tabs: [{ label: 'Speedchain account', href: '/wallet/speedchain-account' }],
        },
        {
          id: 'settings',
          label: t('settings'),
          href: '/settings',
          IconComponent: SettingsIcon,
          hasAccess: Boolean(isAdminAccess || isConstructionType),
          tabs: [
            {
              label: t('App Settings'),
              href: '/settings/app-settings',
              hasAccess: isAdministrator,
            },
            {
              label: t('connections'),
              href: '/settings/connectivity',
              hasAccess: isConstructionType,
            },
            {
              label: 'Job Codes',
              href: '/settings/job-codes',
              hasAccess:
                !isConstructionType && !isFlexCodingEnabled && !!selectedPaidol?.jobCodeSettings?.isEnabled,
            },
            {
              label: t('flexCoding.title'),
              href: '/settings/flex-coding',
              hasAccess:
                !isConstructionType && isFlexCodingEnabled && !!selectedPaidol?.flexCodingSettings?.isEnabled,
            },
          ],
        },
        /* We will hide the Style menu option for now */
        // {
        //   id: 'style',
        //   label: 'Style Guide',
        //   href: '/style',
        //   IconComponent: StyleIcon,
        //   hasAccess: Boolean(showStyleGuide && isAdministrator && isStepperComplete),
        // },
      ].filter((f) => f.hasAccess || f.hasAccess === undefined),
    [
      t,
      showWallet,
      showHomeTab,
      isCardholder,
      isAdminAccess,
      selectedPaidol?.jobCodeSettings?.isEnabled,
      selectedPaidol?.flexCodingSettings?.isEnabled,
      isAdministrator,
      showReimbursements,
      isConstructionType,
      isWelcomeDismissed,
      isGroupAdministrator,
      isReimbursementsEnabled,
      integration?.highnoteProduct?.type,
      isFlexCodingEnabled,
    ]
  );

  const filteredTabs = useMemo(
    () =>
      filterById
        ? navigationElements
            .find((f) => filterById === f.id)
            ?.tabs?.filter((f) => f.hasAccess || f.hasAccess === undefined) || []
        : [],
    [filterById, navigationElements]
  );

  return { navigationElements, filteredTabs };
};
