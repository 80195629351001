import { Role } from 'API';
import { NewCardSheetProps, useIssueOrUpdateCard } from 'app/hooks/useIssueOrUpdateCard';
import useSidesheetById from 'app/hooks/useSidesheetById';
import { selectAllGroups } from 'app/pages/store/groupsSlice';
import { selectAllSpendRules } from 'app/pages/store/spendRulesSlice';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import { useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useMemo } from 'react';
import NewCardForm from './NewCardForm';

function NewCardSheet(): JSX.Element {
  const sidesheet = useSidesheetById(SidesheetRoutes.NewCard);
  const params: NewCardSheetProps = sidesheet.params;
  const { closeSidesheet } = useSidesheet();
  const merchantSpendRules = useAppSelector(selectAllSpendRules);
  const { paidolUsers, userRole } = useAppSelector(selectUserCompanies);
  const managedGroups = useMemo(
    () => paidolUsers[0].managedGroups?.items.map((item) => item?.cardGroup),
    [paidolUsers]
  );

  const handleClose = useCallback(() => {
    closeSidesheet(SidesheetRoutes.NewCard);
  }, [closeSidesheet]);

  const { formType } = params;

  const { onSaveCard, loading } = useIssueOrUpdateCard({ ...params, handleClose });

  const groups = useAppSelector(selectAllGroups);

  return (
    <>
      <SideSheetHeader
        title={
          formType === 'ISSUE_CARD'
            ? 'New card'
            : formType === 'UPDATE_CARD'
            ? 'Edit card'
            : 'Order physical card'
        }
        onClose={handleClose}
      />
      <SideSheetContent>
        <NewCardForm
          formType={formType}
          paymentCard={params.selectedPaymentCard}
          loading={loading}
          groups={userRole === Role.GROUP_ADMINISTRATOR ? managedGroups : groups}
          rules={merchantSpendRules}
          onSubmit={onSaveCard}
          onCancel={handleClose}
        />
      </SideSheetContent>
    </>
  );
}

export default NewCardSheet;
