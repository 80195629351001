import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  AttachSpendRulesToGroupCardsInput,
  AttachSpendRulesToGroupCardsMutation,
  CardGroup,
  CreateCardGroupInput,
  CreateCardGroupMutation,
  CreatePaidolUserToCardGroupMutation,
  DeletePaidolUserToCardGroupMutation,
  HighnotePaymentCard,
  ListPaidolUserToCardGroupsQuery,
  PaidolUser,
  SearchHighnotePaymentCardsQuery,
  UpdateCardGroupInput,
  UpdateCardGroupMutation,
  UpdateHighnotePaymentCardInput,
  UpdateHighnotePaymentCardMutation,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import {
  attachSpendRulesToGroupCards,
  createPaidolUserToCardGroup,
  deletePaidolUserToCardGroup,
  updateCardGroup,
  updateHighnotePaymentCard,
} from 'graphql/mutations';
import { listPaidolUserToCardGroups } from 'graphql/queries';
import { isNotNullOrUndefined } from 'util/typeGuards';
import {
  GetGroupsForPaidolId,
  GetGroupsForPaidolIdQuery,
  SearchHighnotePaymentCards,
  createCardGroup,
} from './queries';

const groupsEntityAdapter = createEntityAdapter<CardGroup>();

const initialState = groupsEntityAdapter.getInitialState({
  loading: false,
  editPending: false,
  nextToken: undefined as string | undefined,
  selectedGroupId: undefined as string | undefined,
  users: {},
});

export type GroupsState = typeof initialState;

export interface GetGroupsArgs {
  paidolId: string;
  nextToken?: string;
  limit?: number;
}

export const getUsers = createAsyncThunk('cards/groups/getUsers', async (paidolId: string) => {
  return (
    API.graphql(
      graphqlOperation(SearchHighnotePaymentCards, {
        filter: {
          or: [{ cardGroupId: { exists: false } }, { cardGroupId: { eq: 'NO_GROUP' } }],
          paidolId: { eq: paidolId },
          status: { eq: 'ACTIVE' },
        },
      })
    ) as Promise<GraphQLResult<SearchHighnotePaymentCardsQuery>>
  ).then((result) => result.data?.searchHighnotePaymentCards?.items as Array<HighnotePaymentCard>);
});

export const getGroups = createAsyncThunk(
  'cards/groups/getGroups',
  async ({ paidolId, nextToken, limit }: GetGroupsArgs) => {
    return (
      API.graphql(graphqlOperation(GetGroupsForPaidolId, { paidolId, nextToken, limit })) as Promise<
        GraphQLResult<GetGroupsForPaidolIdQuery>
      >
    ).then((results) => results.data?.listCardGroupsByPaidolId);
  }
);

export const newGroup = createAsyncThunk('cards/groups/newGroup', async (input: CreateCardGroupInput) => {
  return (
    API.graphql(graphqlOperation(createCardGroup, { input })) as Promise<
      GraphQLResult<CreateCardGroupMutation>
    >
  ).then((results) => results.data?.createCardGroup as CardGroup | undefined);
});

type GroupAdminsInput = { cardGroupId: string; groupAdmins: Partial<PaidolUser>[] };

export const addGroupAdmins = createAsyncThunk(
  'cards/groups/addGroupAdmins',
  async (input: GroupAdminsInput) => {
    const existingAdminsResult = (await API.graphql(
      graphqlOperation(listPaidolUserToCardGroups, { filter: { cardGroupId: { eq: input.cardGroupId } } })
    )) as GraphQLResult<ListPaidolUserToCardGroupsQuery>;

    const existingAdmins = existingAdminsResult.data?.listPaidolUserToCardGroups?.items || [];

    await Promise.all(
      existingAdmins.map(
        (admin) =>
          API.graphql(
            graphqlOperation(deletePaidolUserToCardGroup, {
              input: { id: admin?.id },
            })
          ) as Promise<GraphQLResult<DeletePaidolUserToCardGroupMutation>>
      )
    );

    const result = await Promise.all(
      input.groupAdmins.map(
        (admin) =>
          API.graphql(
            graphqlOperation(createPaidolUserToCardGroup, {
              input: { cardGroupId: input.cardGroupId, paidolUserId: admin.id },
            })
          ) as Promise<GraphQLResult<CreatePaidolUserToCardGroupMutation>>
      )
    );

    return result.map(
      (r) => r.data?.createPaidolUserToCardGroup
    ) as Array<CreatePaidolUserToCardGroupMutation>;
  }
);

export const removeGroupAdmins = createAsyncThunk(
  'cards/groups/removeGroupAdmins',
  async (input: GroupAdminsInput) => {
    const result = await Promise.all(
      input.groupAdmins.map(
        (admin) =>
          API.graphql(
            graphqlOperation(deletePaidolUserToCardGroup, {
              input: { cardGroupId: input?.cardGroupId, paidolUserId: admin?.id },
            })
          ) as Promise<GraphQLResult<DeletePaidolUserToCardGroupMutation>>
      )
    );

    return result.map(
      (r) => r.data?.deletePaidolUserToCardGroup
    ) as Array<DeletePaidolUserToCardGroupMutation>;
  }
);

export const updateGroup = createAsyncThunk(
  'cards/groups/updateGroup',
  async (input: UpdateCardGroupInput) => {
    return (
      API.graphql(graphqlOperation(updateCardGroup, { input })) as Promise<
        GraphQLResult<UpdateCardGroupMutation>
      >
    ).then((results) => results.data?.updateCardGroup as CardGroup | undefined);
  }
);

export const updateHNPaymentCard = createAsyncThunk(
  'cards/groups/updateHighnotePaymentCard',
  async (input: UpdateHighnotePaymentCardInput) => {
    return (
      API.graphql(graphqlOperation(updateHighnotePaymentCard, { input })) as Promise<
        GraphQLResult<UpdateHighnotePaymentCardMutation>
      >
    ).then((results) => results.data?.updateHighnotePaymentCard as HighnotePaymentCard | undefined);
  }
);

export const attachSpendRules = createAsyncThunk(
  'cards/groups/attachSpendRulesToGroupCards',
  async (input: AttachSpendRulesToGroupCardsInput) => {
    const result = (await API.graphql(graphqlOperation(attachSpendRulesToGroupCards, { input }))) as Promise<
      GraphQLResult<AttachSpendRulesToGroupCardsMutation>
    >;
    return result;
  }
);

const groupsSlice = createSlice({
  name: 'cards/groups',
  initialState,
  reducers: {
    resetGroupsSlice: () => initialState,
    setSelectedGroupId: (state, action) => {
      state.selectedGroupId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(attachSpendRules.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(attachSpendRules.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(attachSpendRules.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(getGroups.fulfilled, (state, action) => {
      if (action.payload?.items) {
        groupsEntityAdapter.addMany(state, action.payload.items);
      }
      state.nextToken = action.payload?.nextToken ?? undefined;
      state.loading = false;
    });

    builder.addCase(getGroups.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.users = action.payload.reduce((prev, next) => {
          const users = next.paidolUsers?.items;
          if (users?.length) {
            prev[next?.paymentCardId] = users
              .map((u) => u?.paidolUser)
              .filter((u) => isNotNullOrUndefined(u))
              .map((u) => {
                return (
                  (next?.last4 ? `*${next?.last4} ` : '') +
                  (u?.user ? `${u!.user!.first_name} ${u!.user!.last_name}` : `${u!.email ?? 'Unknown'}`)
                );
              });
          }
          return prev;
        }, {} as Record<string, Array<string>>);
      }
    });
    builder.addCase(addGroupAdmins.fulfilled, (state) => {
      state.editPending = false;
    });
    builder.addCase(addGroupAdmins.rejected, (state) => {
      state.editPending = false;
    });
    builder.addCase(addGroupAdmins.pending, (state) => {
      state.editPending = true;
    });
    builder.addCase(updateHNPaymentCard.fulfilled, (state) => {
      state.editPending = false;
    });
    builder.addCase(updateHNPaymentCard.rejected, (state) => {
      state.editPending = false;
    });
    builder.addCase(updateHNPaymentCard.pending, (state) => {
      state.editPending = true;
    });
    builder.addMatcher(isAnyOf(newGroup.pending, updateGroup.pending), (state) => {
      state.editPending = true;
    });

    builder.addMatcher(isAnyOf(newGroup.fulfilled, updateGroup.fulfilled), (state, action) => {
      if (action.payload) {
        groupsEntityAdapter.upsertOne(state, action.payload);
      }
      state.editPending = false;
    });

    builder.addMatcher(isAnyOf(newGroup.rejected, updateGroup.rejected), (state) => {
      state.editPending = false;
    });
  },
});

export const { resetGroupsSlice, setSelectedGroupId } = groupsSlice.actions;

export const selectGroupsSlice = (state: RootState) => state.cards?.groups ?? initialState;

export const { selectAll: selectAllGroups, selectById: selectGroupById } =
  groupsEntityAdapter.getSelectors(selectGroupsSlice);

export const selectSelectedGroup = (state: RootState) => {
  if (state.cards?.groups.selectedGroupId) {
    return selectGroupById(state, state.cards?.groups.selectedGroupId);
  }

  return undefined;
};

export default groupsSlice.reducer;
