import { GraphQLResult } from '@aws-amplify/api-graphql';
import { GetCardByHighnoteIdQuery, GetCardByHighnoteIdQueryVariables, GetCardsPayload } from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import { getCardByHighnoteId } from 'graphql/queries';

export const getCardById = async ({ input }: GetCardByHighnoteIdQueryVariables) => {
  const variables = { input };

  return (
    API.graphql(graphqlOperation(getCardByHighnoteId, variables)) as Promise<
      GraphQLResult<GetCardByHighnoteIdQuery>
    >
  ).then((result) => {
    return result.data?.getCardByHighnoteId as GetCardsPayload | undefined;
  });
};
