import { Box, SxProps, Theme, styled } from '@mui/material';

interface WidgetContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const WidgetStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '1rem',
  boxShadow: theme.shadows[1],
}));

export const WidgetContainer = ({ children, sx }: WidgetContainerProps): JSX.Element => {
  return <WidgetStyled sx={sx}>{children}</WidgetStyled>;
};
