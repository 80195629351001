import {
  HNExternalFinancialBankAccount,
  Paidol,
  PaidolHighnoteIntegration,
  PaidolUser,
  PlaidLinkToken,
  PlaidProcessorToken,
} from 'API';

export function isNotNullOrUndefined<T>(arg: T | null | undefined): arg is T {
  return arg !== null && arg !== undefined;
}

export function isPaidolUser(paidolUser: { __typename: 'PaidolUser' } | null): paidolUser is PaidolUser {
  return paidolUser !== null;
}

export function isPaidol(paidol: { __typename: 'Paidol' } | null | undefined | void): paidol is Paidol {
  return typeof paidol === 'object' && paidol !== null;
}

export function isPaidolHighnoteIntegration(
  integration: { __typename: 'PaidolHighnoteIntegration' } | unknown
): integration is PaidolHighnoteIntegration {
  return typeof integration === 'object' && integration !== null;
}

export function isPlaidLinkToken(token: { __typename: 'PlaidLinkToken' } | unknown): token is PlaidLinkToken {
  return typeof token === 'object' && token !== null;
}

export function isPlaidProcessorToken(
  token: { __typename: 'PlaidProcessorToken' } | unknown
): token is PlaidProcessorToken {
  return typeof token === 'object' && token !== null;
}

export function isHNExternalFinancialBankAccount(
  record: { __typename: 'HNExternalFinancialBankAccount' } | unknown
): record is HNExternalFinancialBankAccount {
  return typeof record === 'object' && record !== null;
}
