import { Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
import { Box } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { SHIPPING_METHODS } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { PaymentCardDetails } from 'app/pages/store/specificCardSlice';
import moment from 'moment';
import theme from 'app/theme';

interface ShippingStatusProps {
  paymentCardDetails?: PaymentCardDetails;
}

function ShippingStatus({ paymentCardDetails }: ShippingStatusProps): JSX.Element {
  const physicalCardOrders = paymentCardDetails?.paymentCardDetails?.physicalPaymentCardOrders;

  const physicalPaymentCardOrders = physicalCardOrders ? physicalCardOrders[0] : null;

  const orderStatus = physicalPaymentCardOrders?.orderState?.status?.toLowerCase()
    ? capitalize(physicalPaymentCardOrders?.orderState?.status?.toLowerCase())
    : null;

  if (!physicalPaymentCardOrders || !orderStatus) return <></>;

  if (orderStatus === 'Shipped')
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '442.5px',
          p: '24px',
          m: 3,
          md: { ml: 10 },
          backgroundColor: 'rgba(136, 83, 3, 0.05)',
          borderRadius: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box mr={1} color={'#885303'}>
            <LocalShippingIcon />
            <ArrowRightAltIcon />
          </Box>
          <Typography typography="secondary" variant="h4">
            Card {orderStatus}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            gap: '10px',
            mt: 3,
            color: theme.palette.primary.main,
            fontWeight: 400,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box mr={1}>
              <CalendarMonthIcon />
            </Box>
            {`${orderStatus} on ${moment(
              physicalPaymentCardOrders?.paymentCardShipment?.courier?.tracking?.actualShipDateLocal ??
                physicalPaymentCardOrders?.paymentCardShipment?.requestedShipDate
            ).format('MMM D, YYYY')}
            `}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box mr={1}>
              <LocalShippingIcon />
            </Box>
            {physicalPaymentCardOrders?.paymentCardShipment?.courier?.method?.replace('_', ' ') ?? 'N/A'}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box mr={1}>
              <EmailIcon />
            </Box>
            {physicalPaymentCardOrders?.paymentCardShipment?.courier?.method
              ? SHIPPING_METHODS[physicalPaymentCardOrders?.paymentCardShipment?.courier?.method] ?? 'N/A'
              : 'N/A'}
          </Box>
          {!!physicalPaymentCardOrders?.paymentCardShipment?.courier?.tracking?.trackingNumber ?? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box mr={1}>
                <SearchIcon />
              </Box>
              {physicalPaymentCardOrders?.paymentCardShipment?.courier?.tracking?.trackingNumber}
            </Box>
          )}
        </Box>
      </Box>
    );
  else return <></>;
}

export default ShippingStatus;
