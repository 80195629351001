import { AccountTypeEnum, BankAccounts, DefaultsEnum } from 'API';
import { cond, filter, flow, map, some, stubTrue, take } from 'lodash/fp';

type BankAccountEntry = Omit<BankAccounts, '__typename'>;

export const bankAccountDefault: BankAccountEntry = {
  accountType: AccountTypeEnum.checking,
  accountNumber: '',
  routingNumber: '',
  defaults: [DefaultsEnum.ACH, DefaultsEnum.VCN],
  isActive: true,
};

const bankAccountDefaultArrFn = () => [bankAccountDefault];
const someActiveBankAccount = (account: BankAccountEntry) => !!account.isActive;

export const getActiveOnlyBankAccountOrDefault = flow(
  cond([
    [some(['isActive', true]), filter(someActiveBankAccount)],
    [stubTrue, bankAccountDefaultArrFn],
  ]),
  take(1)
);

const markAccountActiveFn = (x: BankAccountEntry) => {
  x.bankId = ''; // when bank account is updated, make ID = null, so lambda function can understand this is a new ID.
  x.isActive = bankAccountDefault.isActive;
  x.defaults = bankAccountDefault.defaults;
  return x;
};

export const makeAndGetActiveAccount = flow(map(markAccountActiveFn), take(1));

export const newCompanyObject = {
  name: '',
  company_id: '',
  legal_name: '',
  dba: '',
  ein: '',
  mcc: '',
  corp_type: '',
  industry: '',
  company_email: '',
  customer_facing_email: '',
  company_phone: '',
  website: '',
  priority_buyer_id: '',
  customer_facing_address: {
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    postal_code: '',
  },
  company_address: {
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    postal_code: '',
  },
  legal_address: {
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    postal_code: '',
  },
  logo: '',
  achEnabled: false,
  virtualCardEnabled: false,
  bankAccounts: [bankAccountDefault],
  useExistingBuyer: true,
  enablePayables: false,
  enablePCards: false,
  hnBusinessProfileId: undefined,
  isHnProfileValid: false,
  productId: undefined,
};

export type NewCompanyFormData = typeof newCompanyObject;
