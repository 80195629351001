export const hnCreateAccountHolderCardProductApplication = /* GraphQL */ `
  mutation HnCreateAccountHolderCardProductApplication(
    $input: HNCreateAccountHolderCardProductApplicationInput!
  ) {
    hnCreateAccountHolderCardProductApplication(input: $input) {
      __typename
      ... on HNAccountHolderCardProductApplication {
        id
        applicationState {
          status
        }
        adverseActionReasons {
          code
          description
        }
        cardProduct {
          id
          name
          usage
          vertical
          commercial
        }
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          path
          code
          description
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
  }
`;

export const CreateMerchantSpendRule = /* GraphQL */ `
  mutation CreateMerchantSpendRule($createMerchantSpendRuleInput: CreateMerchantSpendRuleInput!) {
    createMerchantSpendRule(input: $createMerchantSpendRuleInput) {
      authPaidolId

      createdAt
      merchantCategorySpendRuleId
      merchantCountrySpendRuleId
      amountLimitSpendRuleId
      name
      id
      paidolId
    }
  }
`;

export const CreateMerchantCategorySpendRule = /* GraphQL */ `
  mutation CreateMerchantCategorySpendRule($merchantCategoryInput: HNCreateMerchantCategorySpendRuleInput!) {
    hnCreateMerchantCategorySpendRule(input: $merchantCategoryInput) {
      __typename
      ... on HNMerchantCategorySpendRule {
        id
        name
        version
        allowed
        blocked
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
  }
`;

export const CreateMerchantCountrySpendRule = /* GraphQL */ `
  mutation CreateMerchantCountrySpendRule($merchantCountryInput: HNCreateMerchantCountrySpendRuleInput!) {
    hnCreateMerchantCountrySpendRule(input: $merchantCountryInput) {
      __typename
      ... on HNMerchantCountrySpendRule {
        id
        name
        version
        allowed
        blocked
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
  }
`;

export const CreateAmountLimitSpendRule = /* GraphQL */ `
  mutation CreateAmountLimitSpendRule($amountLimitInput: HNCreateAmountLimitSpendRuleInput!) {
    hnCreateAmountLimitSpendRule(input: $amountLimitInput) {
      __typename
      ... on HNAmountLimitSpendRule {
        id
        name
        version
        maximumAmount {
          value
          currencyCode
        }
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
  }
`;

export const CreateHighnoteSpendRule = /* GraphQL */ `
  mutation CreateHighnoteSpendRule(
    $merchantCategoryInput: HNCreateMerchantCategorySpendRuleInput!
    $merchantCountryInput: HNCreateMerchantCountrySpendRuleInput!
    $amountLimitInput: HNCreateAmountLimitSpendRuleInput!
  ) {
    hnCreateMerchantCategorySpendRule(input: $merchantCategoryInput) {
      __typename
      ... on HNMerchantCategorySpendRule {
        id
        name
        version
        allowed
        blocked
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
    hnCreateMerchantCountrySpendRule(input: $merchantCountryInput) {
      __typename
      ... on HNMerchantCountrySpendRule {
        id
        name
        version
        allowed
        blocked
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
    hnCreateAmountLimitSpendRule(input: $amountLimitInput) {
      __typename
      ... on HNAmountLimitSpendRule {
        id
        name
        version
        maximumAmount {
          value
          currencyCode
        }
        createdAt
        updatedAt
      }
      ... on HNUserError {
        errors {
          code
          path
        }
      }
      ... on HNAccessDeniedError {
        message
      }
    }
  }
`;

export const UpdateSpendRule = /* GraphQL */ `
  mutation UpdateSpendRule($updateMerchantSpendRuleInput: UpdateMerchantSpendRuleInput!) {
    updateMerchantSpendRule(input: $updateMerchantSpendRuleInput) {
      authPaidolId
      createdAt
      name
      id
      paidolId
    }
  }
`;

export const UpdateMerchantSpendRulePaymentCards = /* GraphQL */ `
  mutation UpdateMerchantSpendRulePaymentCards($merchantSpendRuleId: ID!, $paymentCards: [String]!) {
    updateMerchantSpendRule(input: { id: $merchantSpendRuleId, paymentCards: $paymentCards }) {
      authPaidolId
      createdAt
      name
      id
      paidolId
      merchantCountrySpendRuleId
    }
  }
`;

export const CreateSpendRuleConfig = /* GraphQL */ `
  mutation CreateSpendRuleConfig($createSpendRuleConfigInput: CreateSpendRuleConfigInput!) {
    createSpendRuleConfig(input: $createSpendRuleConfigInput) {
      id
      authPaidolId
      paidolId
      createdAt
      updatedAt
      ruleId
      ruleType
    }
  }
`;

export const createDwollaFinicityConsentReceipt = /* GraphQL */ `
  mutation CreateDwollaFinicityConsentReceipt($input: CreateFinicityConsentReceiptInput!) {
    createDwollaFinicityConsentReceipt(input: $input) {
      receipt {
        profile
        version
        receiptId
        receiptVersion
        customerId
        partnerId
        products {
          product
          accountId
          accessPeriod {
            type
            startTime
            endTime
          }
        }
      }
      proof {
        signature
        keyId
        timestamp
      }
    }
  }
`;

export const createHighnoteFinicityConsentReceipt = /* GraphQL */ `
  mutation CreateHighnoteFinicityConsentReceipt($input: CreateFinicityConsentReceiptInput!) {
    createHighnoteFinicityConsentReceipt(input: $input) {
      receipt {
        profile
        version
        receiptId
        receiptVersion
        customerId
        partnerId
        products {
          product
          accountId
          accessPeriod {
            type
            startTime
            endTime
          }
        }
      }
      proof {
        signature
        keyId
        timestamp
      }
    }
  }
`;

export const updateTransactionAgave = /* GraphQL */ `
  mutation UpdateHighnoteTransaction(
    $input: UpdateHighnoteTransactionInput!
    $condition: ModelHighnoteTransactionConditionInput
  ) {
    updateHighnoteTransaction(input: $input, condition: $condition) {
      transactionId
      agaveProjectId
      agaveProject {
        id
        source_id
        amount
        completion_date
        name
        number
        start_date
        status
        type
        source_create_time
        source_update_time
        __typename
      }
      agaveCostCodeId
      agaveCostCode {
        id
        source_id
        code
        full_code
        name
        parent_id
        project_id
        source_create_time
        source_update_time
        __typename
      }
      agaveCostTypeId
      agaveCostType {
        id
        source_id
        code
        name
        project_id
        source_create_time
        source_update_time
        __typename
      }
      agaveExpenseId
      agaveExpense {
        id
        source_id
        amount
        amount_due
        ap_payment_ids
        batch
        batch_id
        creator_id
        currency_code
        description
        discount_amount
        discount_date
        due_date
        issue_date
        misc_amount
        net_amount
        number
        project_id
        retention_amount
        retention_percent
        status
        subtotal_amount
        tax_amount
        total_amount
        type
        vendor_id
        source_create_time
        source_update_time
        __typename
      }
      agaveVendor {
        id
        code
        email
        fax
        name
        phone
        status
        tax_number
        terms
        type
        website
        __typename
      }
      agaveVendorId
      erpApprovalStatus
    }
  }
`;
