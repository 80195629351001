import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies, updateChecklist } from 'app/store/userCompaniesSlice';
import { updateDescriptionBanner, selectUserProfile } from 'app/store/userProfileSlice';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';

export type FirstRunChecklistOptions = {
  connectFinancialAccount?: string;
  addAdmin?: string;
  addTeamMember?: string;
  createSpendRule?: string;
  issueCard?: string;
  createGroup?: string;
  setReimbursement?: string;
  integrateERP?: string;
  dismissWelcomePage?: string;
  isStepperComplete?: string;
};

export type FirstRunBannerOptions = {
  insights?: string;
  transactions?: string;
  reimbursements?: string;
  cardsManagement?: string;
  spendRules?: string;
  funding?: string;
  teamMembers?: string;
  groups?: string;
  wallet?: string;
  settings?: string;
  connections?: string;
};

type FirstRunStateProps =
  | {
      operationName: 'completeFirstRunChecklist';
      firstRunValue: keyof FirstRunChecklistOptions;
      initialTrigger?: boolean;
    }
  | {
      operationName: 'completeFirstRunBanner';
      firstRunValue: keyof FirstRunBannerOptions;
      initialTrigger?: boolean;
    };

export const useUpdateFirstRunState = ({
  operationName,
  firstRunValue,
  initialTrigger = false,
}: FirstRunStateProps) => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUserProfile);
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);
  const { 'firstRunStateFlags.isHomeTabEnabled': isHomeTabEnabled } = useIsEnabled([
    { featureName: 'firstRunStateFlags', flagName: 'isHomeTabEnabled' },
  ]);

  const [trigger, setTrigger] = useState(initialTrigger);

  const triggerUpdate = () => {
    setTrigger(true);
  };

  useEffect(() => {
    if (!isHomeTabEnabled) return;

    if (!trigger) return;

    if (operationName === 'completeFirstRunChecklist') {
      dispatch(
        updateChecklist({
          operationName: operationName,
          data: { paidolId: selectedPaidolId || '', firstRunValue: firstRunValue },
        })
      );
    }

    if (operationName === 'completeFirstRunBanner') {
      dispatch(
        updateDescriptionBanner({
          operationName: operationName,
          data: { userId: profile?.id || '', firstRunValue: firstRunValue },
        })
      );
    }
    setTrigger(false);
  }, [firstRunValue, operationName, dispatch, profile, trigger, isHomeTabEnabled, selectedPaidolId]);

  return { trigger, setTrigger, triggerUpdate };
};
