import { useEffect } from 'react';
import {
  init as sentryInit,
  browserTracingIntegration,
  httpClientIntegration,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
 } from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

function parseSampleRate(value) {
  const rate = parseFloat(value);
  return Number.isNaN(rate) || rate < 0 || rate > 1 ? 1.0 : rate;
}

if (process.env.REACT_APP_SENTRY_DSN) {
  sentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_ENVIRONMENT || 'unknown',
    debug: ['true', '1'].includes(process.env.REACT_APP_SENTRY_DEBUG),
    integrations: [
      browserTracingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
      httpClientIntegration(),
    ],
    normalizeDepth: parseInt(process.env.REACT_APP_SENTRY_NORMALIZE_DEPTH ?? '5', 10),
    tracesSampleRate: parseSampleRate(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: parseSampleRate(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: parseSampleRate(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  });
}
