import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { RootState } from 'app/store/rootReducer';
import {
  HNAmount,
  HNExternalBankAccountDetail,
  HNUSBusinessAccountHolder,
  HnGetBusinessAccountHolderQuery,
  PaidolHighnoteIntegration,
  HNNormalBalance,
  HnGenerateDirectDepositDetailClientTokenMutation,
  HNClientToken,
  HnViewDirectDepositDetailsQuery,
  HNDirectDepositDetailRestrictedDetails,
  HnFindFinancialAccountTransfersQueryVariables,
  HnFindFinancialAccountTransfersQuery,
  HNExternallyInitiatedACHTransferConnection,
  HNScheduledTransferConnection,
  HNIntegratorInitiatedACHTransferConnection,
  HNIntegratorInitiatedACHTransferEdge,
  HNExternallyInitiatedACHTransferEdge,
  HNScheduledTransferEdge,
  HnCloseExternalFinancialBankAccountMutation,
  SetThresholdAmount,
  HNBillingCycleConfiguration,
  HNLedger,
  HNAddExternalBankAccountVerifiedThroughFinicityInput,
  HNExternalFinancialBankAccount,
  HnAddExternalBankAccountVerifiedThroughFinicityMutation,
  HNUserError,
  HNAccessDeniedError,
} from 'API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  hnFindFinancialAccountTransfers,
  hnGetFinancialAccount,
  hnViewDirectDepositDetails,
} from './queries';
import {
  hnAddExternalBankAccountVerifiedThroughFinicity,
  hnCloseExternalFinancialBankAccount,
  hnGenerateDirectDepositDetailClientToken,
  setThresholdAmount,
} from 'graphql/mutations';
import { getIntegration } from './reviewOnboardSlice';
import { captureException } from '@sentry/react';

interface ExternalBankAccountDetails extends HNExternalBankAccountDetail {
  name?: string | null;
}

export interface Transactions {
  externalACHTransfers: HNExternallyInitiatedACHTransferConnection;
  incomingScheduledTransfers: HNScheduledTransferConnection;
  integratorACHTransfers: HNIntegratorInitiatedACHTransferConnection;
}

export interface FundingState {
  isLoading?: boolean;
  financialAccount?: HNDirectDepositDetailRestrictedDetails;
  isAddingFundingBankAccount?: boolean;
  fundingBankAccount?: HNExternalFinancialBankAccount | HNUserError | HNAccessDeniedError | null;
  externalBankAccountDetails: ExternalBankAccountDetails;
  directDepositDetailId: string;
  billingCycleConfiguration: HNBillingCycleConfiguration;
  balance?: HNAmount | null;
  creditBalance?: Array<HNLedger | undefined>;
  transactions: Transactions;
}

const initialState: FundingState = {
  isLoading: false,
  isAddingFundingBankAccount: false,
  fundingBankAccount: undefined,
  externalBankAccountDetails: {
    __typename: 'HNExternalBankAccountDetail',
    id: '',
    name: '',
  },
  financialAccount: {
    __typename: 'HNDirectDepositDetailRestrictedDetails',
    number: null,
    routingNumber: null,
    bank: null,
    type: null,
  },
  directDepositDetailId: '',
  billingCycleConfiguration: {
    id: '',
    __typename: 'HNBillingCycleConfiguration',
    paymentDueDayOfMonth: 0,
    billingCycleStartDayOfMonth: 0,
    billingCycleEndDayOfMonth: 0,
    from: '',
    through: null,
  },
  balance: {
    __typename: 'HNAmount',
    value: 0,
    currencyCode: 'USD',
  },
  transactions: {
    externalACHTransfers: {
      __typename: 'HNExternallyInitiatedACHTransferConnection',
      edges: [],
      pageInfo: {
        __typename: 'HNPageInfo',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: '',
      },
    },
    incomingScheduledTransfers: {
      __typename: 'HNScheduledTransferConnection',
      edges: [],
      pageInfo: {
        __typename: 'HNPageInfo',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: '',
      },
    },
    integratorACHTransfers: {
      __typename: 'HNIntegratorInitiatedACHTransferConnection',
      edges: [],
      pageInfo: {
        __typename: 'HNPageInfo',
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: '',
      },
    },
  },
};

export const getFinancialAccountDetails = createAsyncThunk(
  'cards/funding/getFinancialAccountId',
  async (integration: PaidolHighnoteIntegration, { rejectWithValue }) => {
    try {
      const { businessAccountHolderId, externalFinancialBankAccountId, financialAccountId } = integration;

      const getFinancialAccount = await API.graphql(
        graphqlOperation(hnGetFinancialAccount, { id: businessAccountHolderId })
      );

      const accountHolder = (getFinancialAccount as GraphQLResult<HnGetBusinessAccountHolderQuery>).data
        ?.hnGetBusinessAccountHolder as HNUSBusinessAccountHolder;

      const externalFinancialBankAccount = accountHolder.externalFinancialAccounts?.edges?.find(
        (edge) => edge.node?.accountStatus === 'ACTIVE' && edge.node?.id === externalFinancialBankAccountId
      )?.node as HNExternalFinancialBankAccount;

      const financialAccount = accountHolder.financialAccounts?.edges?.find(
        (edge) => edge.node?.accountStatus === 'ACTIVE' && edge.node?.id === financialAccountId
      )?.node;

      const ledger = financialAccount?.ledgers?.find((l) => l.name === 'AVAILABLE_CASH');

      const creditLedger = [
        financialAccount?.ledgers?.find((l) => l.name === 'AVAILABLE_CREDIT'),
        financialAccount?.ledgers?.find((l) => l.name === 'OUTSTANDING_BALANCE_PAYABLE'),
        financialAccount?.ledgers?.find((l) => l.name === 'ACCOUNT_HOLDER_CREDIT_LIMIT'),
      ];

      const externalBankAccountDetails =
        externalFinancialBankAccount?.externalBankAccountDetails as HNExternalBankAccountDetail;

      const bankAccountDetails = {
        ...externalBankAccountDetails,
        name: externalFinancialBankAccount?.name,
      };

      return {
        externalFinancialBankAccount: externalFinancialBankAccount,
        externalBankAccountDetails: bankAccountDetails,
        directDepositDetailId: financialAccount?.directDepositDetails?.id,
        billingCycleConfiguration: financialAccount?.activeBillingCycleConfiguration,
        balance:
          ledger?.normalBalance === HNNormalBalance.CREDIT ? ledger?.creditBalance : ledger?.debitBalance,
        creditBalance: creditLedger,
      };
    } catch (error: unknown) {
      captureException(error);
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const getAllFinancialAccountTransfers = createAsyncThunk(
  'cards/funding/getAllFinancialAccountTransfers',
  async ({
    id,
    scheduledTransfersAfterCursor = undefined,
    integratorTransfersAfterCursor = undefined,
    externalTransfersAfterCursor = undefined,
  }: HnFindFinancialAccountTransfersQueryVariables) => {
    return (
      API.graphql(
        graphqlOperation(hnFindFinancialAccountTransfers, {
          id,
          firstScheduledTransfers: 20,
          scheduledTransfersAfterCursor,
          firstIntegratorTransfers: 20,
          integratorTransfersAfterCursor,
          firstExternalTransfers: 20,
          externalTransfersAfterCursor,
        })
      ) as Promise<GraphQLResult<HnFindFinancialAccountTransfersQuery>>
    ).then((result) => {
      return result?.data?.hnFindFinancialAccountTransfers as unknown as Transactions;
    });
  }
);

export const viewDirectDepositRestrictedDetails = createAsyncThunk(
  'cards/funding/viewDirectDepositRestrictedDetails',
  async (directDepositDetailId: string) => {
    return (
      API.graphql(
        graphqlOperation(hnGenerateDirectDepositDetailClientToken, {
          input: {
            directDepositDetailId: directDepositDetailId,
            permissions: 'READ_RESTRICTED_DETAILS',
          },
        })
      ) as Promise<GraphQLResult<HnGenerateDirectDepositDetailClientTokenMutation>>
    ).then((result) => {
      const clientToken = result?.data?.hnGenerateDirectDepositDetailClientToken as HNClientToken;

      return (
        API.graphql(
          graphqlOperation(hnViewDirectDepositDetails, {
            id: directDepositDetailId,
          }),
          {
            customAuthorization: `Bearer ${clientToken.value}`,
          }
        ) as Promise<GraphQLResult<HnViewDirectDepositDetailsQuery>>
      ).then((response) => {
        if (
          response.data?.hnViewDirectDepositDetails?.restrictedDetails?.__typename ===
          'HNDirectDepositDetailRestrictedDetails'
        ) {
          return response.data?.hnViewDirectDepositDetails?.restrictedDetails;
        } else {
          return null;
        }
      });
    });
  }
);

export const saveThresholdAmount = createAsyncThunk(
  'cards/funding/saveThresholdAmount',
  async (input: SetThresholdAmount) => {
    return API.graphql(graphqlOperation(setThresholdAmount, { input }));
  }
);

export interface AddFundingBankAccountArgs {
  paidolId: string;
  input: HNAddExternalBankAccountVerifiedThroughFinicityInput;
}

export const addFundingBankAccount = createAsyncThunk(
  'cards/funding/addFundingBankAccount',
  async ({ paidolId, input }: AddFundingBankAccountArgs, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(hnAddExternalBankAccountVerifiedThroughFinicity, {
          input,
        })
      ) as Promise<GraphQLResult<HnAddExternalBankAccountVerifiedThroughFinicityMutation>>
    ).then((results) => {
      const response = results.data?.hnAddExternalBankAccountVerifiedThroughFinicity;
      if ((response as HNExternalFinancialBankAccount).id !== undefined) {
        dispatch(getIntegration(paidolId));
      }
      return response;
    });
  }
);

export const disconnectExternalFinancialAccount = createAsyncThunk(
  'cards/funding/disconnectExternalFinancialAccount',
  async (financialAccountId: string) => {
    return (
      API.graphql(
        graphqlOperation(hnCloseExternalFinancialBankAccount, {
          input: {
            externalFinancialBankAccountId: financialAccountId,
          },
        })
      ) as Promise<GraphQLResult<HnCloseExternalFinancialBankAccountMutation>>
    ).then((response) => {
      return response.data?.hnCloseExternalFinancialBankAccount;
    });
  }
);

type ObjArray =
  | Array<HNIntegratorInitiatedACHTransferEdge>
  | Array<HNExternallyInitiatedACHTransferEdge>
  | Array<HNScheduledTransferEdge>;

function findUniques(arr1: ObjArray, arr2: ObjArray): ObjArray {
  const array = [...arr1, ...arr2];
  const result = Object.values(array.reduce((acc, obj) => ({ ...acc, [obj.node?.id ?? '']: obj }), {}));
  return result as ObjArray;
}

const fundingSlice = createSlice({
  name: 'cards/funding',
  initialState,
  reducers: {
    resetFundingSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialAccountDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getFinancialAccountDetails.fulfilled, (state, action) => {
      if (action.payload) {
        state.fundingBankAccount = action.payload.externalFinancialBankAccount;
        state.externalBankAccountDetails =
          action.payload.externalBankAccountDetails ?? initialState.externalBankAccountDetails;
        state.billingCycleConfiguration =
          action.payload?.billingCycleConfiguration ?? initialState.billingCycleConfiguration;
        state.directDepositDetailId =
          action.payload?.directDepositDetailId ?? initialState.directDepositDetailId;
        state.balance = action.payload.balance ?? initialState.balance;
        state.creditBalance = action.payload.creditBalance ?? initialState.creditBalance;
      }
      state.isLoading = false;
    });

    builder.addCase(getFinancialAccountDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(viewDirectDepositRestrictedDetails.fulfilled, (state, action) => {
      if (action.payload) {
        state.financialAccount = action.payload ?? initialState.financialAccount;
      }
    });
    builder.addCase(getAllFinancialAccountTransfers.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload?.integratorACHTransfers) {
          state.transactions.integratorACHTransfers.pageInfo =
            action.payload?.integratorACHTransfers.pageInfo;

          if (
            state.transactions.integratorACHTransfers.edges &&
            action.payload?.integratorACHTransfers?.edges
          ) {
            state.transactions.integratorACHTransfers.edges = findUniques(
              state.transactions.integratorACHTransfers.edges,
              action.payload.integratorACHTransfers.edges
            ) as Array<HNIntegratorInitiatedACHTransferEdge>;
          }
        }

        if (action.payload?.externalACHTransfers) {
          state.transactions.externalACHTransfers.pageInfo = action.payload?.externalACHTransfers.pageInfo;

          if (state.transactions.externalACHTransfers.edges && action.payload?.externalACHTransfers?.edges) {
            state.transactions.externalACHTransfers.edges = findUniques(
              state.transactions.externalACHTransfers.edges,
              action.payload.externalACHTransfers.edges
            ) as Array<HNExternallyInitiatedACHTransferEdge>;
          }
        }

        if (action.payload?.incomingScheduledTransfers) {
          state.transactions.incomingScheduledTransfers.pageInfo =
            action.payload?.incomingScheduledTransfers.pageInfo;

          if (
            state.transactions.incomingScheduledTransfers.edges &&
            action.payload?.incomingScheduledTransfers?.edges
          ) {
            state.transactions.incomingScheduledTransfers.edges = findUniques(
              state.transactions.incomingScheduledTransfers.edges,
              action.payload.incomingScheduledTransfers.edges
            ) as Array<HNScheduledTransferEdge>;
          }
        }
      }
    });

    builder.addCase(disconnectExternalFinancialAccount.fulfilled, (state, action) => {
      if ((action.payload as HNExternalFinancialBankAccount)?.id) {
        state.fundingBankAccount = undefined;
      }
    });

    builder.addCase(addFundingBankAccount.pending, (state, action) => {
      state.isAddingFundingBankAccount = true;
    });

    builder.addMatcher(
      isAnyOf(addFundingBankAccount.fulfilled, addFundingBankAccount.rejected),
      (state, action) => {
        state.isAddingFundingBankAccount = false;
        if (action.payload) {
          state.fundingBankAccount = action.payload as
            | HNExternalFinancialBankAccount
            | HNUserError
            | HNAccessDeniedError
            | null;
        }
      }
    );
  },
});

export const { resetFundingSlice } = fundingSlice.actions;

export const selectFundingSlice = (state: RootState): FundingState => state?.cards?.funding ?? initialState;

export default fundingSlice.reducer;
