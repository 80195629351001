import { BoxTypeMap, Box, SystemProps } from '@mui/system';
import { PaidolUserToHighnotePaymentCard } from 'API';

export type PaymentCardUserProps = BoxTypeMap['props'] & {
  user?: PaidolUserToHighnotePaymentCard | null;
};

export function PaymentCardUser({ user, ...restProps }: PaymentCardUserProps): JSX.Element {
  const paidolUser = user?.paidolUser;
  const userProfile = paidolUser?.user;

  if (user && userProfile) {
    return <Box {...restProps}>{`${userProfile.first_name} ${userProfile.last_name}`}</Box>;
  } else if (user && paidolUser) {
    return <Box {...restProps}>{paidolUser.email}</Box>;
  }

  return <></>;
}

export interface PaymentCardUsersProps {
  users?: Array<PaidolUserToHighnotePaymentCard | null> | null;
  sx?: SystemProps;
}

function PaymentCardUsers({ users, sx }: PaymentCardUsersProps): JSX.Element {
  if (users) {
    return <>{users.map((user) => user && <PaymentCardUser key={user.id} user={user} />)}</>;
  }

  return <></>;
}

export default PaymentCardUsers;
