import { GetProcoreProjectsPayload } from 'API';
import { getCompanies, getProjects, getWbsCodes, selectProcoreSlice } from 'app/pages/store/procoreSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useEffect, useMemo } from 'react';
import { useFetchProcoreAuthToken } from './useFetchProcoreAuthToken';

export const useFetchProcoreCommonData = (codeParam: string | null) => {
  const dispatch = useAppDispatch();
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { selectedCompany, projects, companies } = useAppSelector(selectProcoreSlice);
  const { tokens, handleRequestError } = useFetchProcoreAuthToken(codeParam);

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const isAgaveClient = useMemo(
    () => selectedPaidol?.isAgaveClient ?? false,
    [selectedPaidol?.isAgaveClient]
  );

  const loadCompanies = useCallback(
    (accessToken: string) => {
      return dispatch(getCompanies({ auth: accessToken }))
        .unwrap()
        .catch(handleRequestError);
    },
    [dispatch, handleRequestError]
  );

  const loadProjects = useCallback(
    (accessToken: string, companyId: number) => {
      return dispatch(getProjects({ auth: accessToken, companyId }))
        .unwrap()
        .catch(handleRequestError);
    },
    [dispatch, handleRequestError]
  );

  const loadWBSCodes = useCallback(
    async (accessToken: string, projects: GetProcoreProjectsPayload[], companyId: number) => {
      const promises = projects.map((project) =>
        dispatch(getWbsCodes({ auth: accessToken, projectId: project.id, companyId }))
      );

      await Promise.all(promises);
    },
    [dispatch]
  );

  useEffect(() => {
    if (isConstructionType && !isAgaveClient && tokens.accessToken) {
      loadCompanies(`Bearer ${tokens.accessToken}`);
    }
  }, [tokens.accessToken, dispatch, loadCompanies, isConstructionType, isAgaveClient]);

  useEffect(() => {
    if (isConstructionType && !isAgaveClient && tokens.accessToken && selectedCompany?.id) {
      loadProjects(`Bearer ${tokens.accessToken}`, selectedCompany.id);
    }
  }, [isAgaveClient, isConstructionType, loadProjects, selectedCompany?.id, tokens.accessToken]);

  useEffect(() => {
    if (
      isConstructionType &&
      !isAgaveClient &&
      tokens.accessToken &&
      companies.length === 1 &&
      projects.length > 0 &&
      selectedCompany?.id
    ) {
      loadWBSCodes(`Bearer ${tokens.accessToken}`, projects, selectedCompany.id);
    }
  }, [
    companies.length,
    isAgaveClient,
    isConstructionType,
    loadWBSCodes,
    projects,
    selectedCompany?.id,
    tokens.accessToken,
  ]);
};
