const validateInputs = (thunk, key, time) => {
  if (typeof thunk !== 'function') {
    throw new Error('thunk must be a function');
  }

  if (typeof key !== 'string' || key.length < 1) {
    throw new Error('key must be a non-empty string');
  }

  if (typeof time !== 'number' || time <= 0) {
    throw new Error('time must be a non-zero number');
  }
};

// Thunks made with this helper will be debounced by redux-debouced middleware
// https://github.com/ryanseddon/redux-debounced#readme
export const createDebouncedThunk = (thunk, key, time = 300) => {
  validateInputs(thunk, key, time);

  thunk.meta = {
    debounce: {
      time,
      key,
      leading: false,
      trailing: true,
    },
  };
  return thunk;
};

// Thunks made with this helper will be throttled by redux-debouced middleware
// https://github.com/ryanseddon/redux-debounced#readme
export const createThrottledThunk = (thunk, key, time = 300) => {
  validateInputs(thunk, key, time);

  thunk.meta = {
    debounce: {
      time,
      key,
      leading: true,
      trailing: false,
    },
  };
  return thunk;
};
