import { useEffect } from 'react';
import { getUnreadMessages, unreadMessagesSelect } from 'app/pages/overview/store/unreadMessagesSlice';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import overviewReducer from 'app/pages/overview/store';
import withReducer from 'app/store/withReducer';
import { useAppDispatch, useAppSelector } from 'app/store';
import { Badge, IconButton } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

function SpeedchatButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { unreadCount } = useAppSelector(unreadMessagesSelect);

  useEffect(() => {
    if (user) {
      dispatch(getUnreadMessages(user.sub));
      const timer = setInterval(() => dispatch(getUnreadMessages(user.sub)), 60 * 1000);
      return () => clearInterval(timer);
    }
  }, [dispatch, user]);

  return (
    <IconButton href="/profile/speedchat" color="inherit">
      <Badge
        sx={{
          '& .MuiBadge-badge': {
            minWidth: 16,
            height: 16,
            fontSize: '.9rem',
          },
        }}
        badgeContent={unreadCount}
        color="error"
      >
        <ChatBubbleIcon />
      </Badge>
    </IconButton>
  );
}

export default withReducer('overview', overviewReducer)(SpeedchatButton);
