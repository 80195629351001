import { ReactNode, useState } from 'react';
import { Box, Grid, Paper, Typography, List, ListItem, Button, IconButton, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { useAppSelector, useAppDispatch } from 'app/store';
import {
  getCardProductApplication,
  selectReviewOnboardSlice,
  sendDocumentForManualReview,
} from 'app/pages/store/reviewOnboardSlice';
import { HNDocumentType } from 'API';
import { useTranslation } from 'react-i18next';

enum DocumentType {
  primaryAuthorizedPerson = 'primaryAuthorizedPerson',
  businessProfile = 'businessProfile',
}

interface Props {
  array: HNDocumentType[];
  type: DocumentType;
}

interface FileArgs {
  id: string;
  file: File | undefined;
  documentType?: HNDocumentType;
}

function AdditionalDocuments() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { application, integration } = useAppSelector(selectReviewOnboardSlice);
  const [loading, setLoading] = useState(false);
  const [individualFiles, setIndividualFiles] = useState<FileArgs[]>([]);
  const [businessFiles, setBusinessFiles] = useState<FileArgs[]>([]);
  const { t } = useTranslation();

  const businessRequired =
    application?.accountHolderSnapshot?.businessProfile?.currentVerification?.reason ===
    'DOCUMENT_UPLOAD_REQUIRED';

  const individualRequired =
    application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification?.reason ===
    'DOCUMENT_UPLOAD_REQUIRED';

  const filterBusinessReasonsRequiredDocuments: HNDocumentType[] = (
    application?.accountHolderSnapshot?.businessProfile?.currentVerification?.requiredDocuments || []
  ).reduce((accumulator, document) => {
    if (document && document.documentUploadSession && document.documentUploadSession.primaryDocumentTypes) {
      accumulator.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...(document.documentUploadSession.primaryDocumentTypes as HNDocumentType[])
      );
    }
    return accumulator;
  }, []);

  const filterIndividualReasonsRequiredDocuments: HNDocumentType[] = (
    application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification?.requiredDocuments || []
  ).reduce((accumulator, document) => {
    if (document && document.documentUploadSession && document.documentUploadSession.primaryDocumentTypes) {
      accumulator.push(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...(document.documentUploadSession.primaryDocumentTypes as HNDocumentType[])
      );
    }
    return accumulator;
  }, []);

  const findFile = (type: string, document: HNDocumentType) => {
    if (type === 'primaryAuthorizedPerson') {
      return individualFiles.find((file) => file?.id === document);
    } else {
      return businessFiles.find((file) => file?.id === document);
    }
  };

  const normalizeDocumentName = (document: HNDocumentType) => document.replaceAll('_', ' ');

  const handleChange = (type: string, file: FileArgs) => {
    if (type === 'primaryAuthorizedPerson') {
      setIndividualFiles((individualFiles) => [...individualFiles, file]);
    } else {
      setBusinessFiles((businessFiles) => [...businessFiles, file]);
    }
  };

  const handleRemove = (type: string, id: string) => {
    if (type === 'primaryAuthorizedPerson') {
      setIndividualFiles((oldValues) => {
        return oldValues.filter((file) => file.id !== id);
      });
    } else {
      setBusinessFiles((oldValues) => {
        return oldValues.filter((file) => file.id !== id);
      });
    }
  };

  const sendFiles = () => {
    setLoading(true);
    const individualDocumentId =
      application?.accountHolderSnapshot?.primaryAuthorizedPerson?.currentVerification?.requiredDocuments?.find(
        (document) => document?.status === 'CREATED'
      )?.documentUploadSession?.id;

    const businessDocumentId =
      application?.accountHolderSnapshot?.businessProfile?.currentVerification?.requiredDocuments?.find(
        (document) => document?.status === 'CREATED'
      )?.documentUploadSession?.id;

    const promises = [];

    if (individualDocumentId && individualFiles.length) {
      promises.push(
        dispatch(
          sendDocumentForManualReview({
            documentUploadSessionId: individualDocumentId,
            files: individualFiles,
          })
        )
      );
    }

    if (businessDocumentId && businessFiles.length) {
      promises.push(
        dispatch(
          sendDocumentForManualReview({
            documentUploadSessionId: businessDocumentId,
            files: businessFiles,
          })
        )
      );
    }

    if (integration) {
      Promise.all(promises)
        .then(() => new Promise((resolve) => setTimeout(resolve, 10000)))
        .then(() => dispatch(getCardProductApplication(integration)))
        .then(() => setLoading(false));
    }
  };

  const WrapList = (props: { list: ReactNode[] | undefined }) => (
    <List sx={{ ml: 2.5, pt: 0 }}>
      {props.list?.map((text, i) => (
        <ListItem
          key={`listItem-${i}`}
          sx={{
            mt: 0,
            padding: 0,
            fontSize: '12px',
            listStyleType: 'disc',
            display: 'list-item',
            fontWeight: 400,
          }}
        >
          {text}
        </ListItem>
      ))}
    </List>
  );
  const WrapperDocuments = ({ array, type }: Props) => {
    return (
      <Grid container gap={3} sx={{ my: 3 }}>
        {array.map((document, i) => {
          const find = findFile(type, document);
          return (
            <Grid key={`${type}-${i}`}>
              <Paper
                elevation={4}
                style={{
                  padding: 14,
                  borderRadius: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '250px',
                  height: '300px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    mb: 0.5,
                  }}
                >
                  {t('uploadDocument')}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                >
                  {t('attachImageOfDocument')}
                </Typography>
                <Box display="flex">
                  <WrapList list={[<strong>{normalizeDocumentName(document)}</strong>]} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  {find ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        backgroundColor: theme.palette.primary.lighter,
                        borderRadius: 5,
                        px: 2.5,
                        py: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          overflow: 'hidden',
                          mr: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            overflow: 'hidden',
                            WebkitLineClamp: 1,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {find?.file?.name}
                        </Typography>
                        <span>{find?.file?.size} KB</span>
                      </Box>
                      <IconButton
                        aria-label="upload picture"
                        component="label"
                        onClick={() => handleRemove(type, document)}
                      >
                        <ClearIcon sx={{ color: 'black' }} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Button variant="contained" component="label" sx={{ mb: 1 }}>
                      {t('chooseFile')}
                      <input
                        hidden
                        accept="image/*,.pdf"
                        multiple
                        type="file"
                        onChange={(e) =>
                          handleChange(type, {
                            id: document,
                            file: e.target.files?.[0],
                            documentType: document,
                          })
                        }
                      />
                    </Button>
                  )}
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box>
      <Box>
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            color: 'primary.main',
            fontWeight: 400,
          }}
        >
          {t('additionalInformationNeeded')}
        </Typography>
        <Typography
          variant="h3"
          sx={{
            mb: 0.5,
            color: '#4F4F4F',
            fontWeight: 600,
          }}
        >
          {t('additionalDocumentsNeeded')}
        </Typography>
        <Typography
          sx={{
            color: '#4F4F4F',
            fontWeight: 400,
            fontSize: '1.6rem',
          }}
        >
          {t('acceptedFileTypes')}
        </Typography>
      </Box>
      {individualRequired ? (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 5,
              color: '#4F4F4F',
              fontWeight: 400,
            }}
          >
            {t('identityDocumentation')}
          </Typography>
          <WrapperDocuments
            array={filterIndividualReasonsRequiredDocuments}
            type={DocumentType.primaryAuthorizedPerson}
          />
        </Box>
      ) : null}

      {businessRequired ? (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 5,
              color: '#4F4F4F',
              fontWeight: 400,
            }}
          >
            {t('businessEntityDocumentation')}
          </Typography>
          <WrapperDocuments
            array={filterBusinessReasonsRequiredDocuments}
            type={DocumentType.businessProfile}
          />
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', mt: 10 }}>
        <LoadingButton
          loading={loading}
          variant="contained"
          startIcon={<SendIcon />}
          onClick={() => sendFiles()}
        >
          {t('submitFiles')}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default AdditionalDocuments;
