import { Box, Typography, styled } from '@mui/material';
import { StyledContainer } from './style';
import defaultColors from 'app/theme/defaultColors';

type ContainerProps = {
  sx?: Record<string, unknown>;
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

const StyledText = styled(Typography)({
  color: `${defaultColors.primaryLighter}`,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
});

const ContainerComponent = ({ children, sx, title, description, ...props }: Readonly<ContainerProps>) => {
  return (
    <StyledContainer
      sx={{
        minHeight: '194px',
        ...sx,
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} maxWidth="38rem">
        {title && <StyledText variant="h4">{title}</StyledText>}
        {description && <StyledText variant="body1">{description}</StyledText>}
      </Box>
      {children}
    </StyledContainer>
  );
};

export default ContainerComponent;
