import { useAgaveLink } from '@agave-api/react-agave-link';
import {
  getAgaveAccountToken,
  getAgaveLinkToken,
  getProjects,
  persistAgaveAccountToken,
  selectAgaveSlice,
} from 'app/pages/store/agaveSlice';

import { useAuth } from 'app/shared-components/auth/AuthProvider';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useEffect, useMemo } from 'react';

export type AgaveIntegrationProps = { sourceSystem?: string | string[] };

export function useAgaveIntegration({ sourceSystem }: AgaveIntegrationProps = {}) {
  const dispatch = useAppDispatch();
  const authState = useAuth();
  const { user } = authState;
  const { linkToken } = useAppSelector(selectAgaveSlice);
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { accountToken: agaveAccountToken } = useAppSelector(selectAgaveSlice);

  useEffect(() => {
    dispatch(getAgaveLinkToken());
  }, [dispatch]);

  const onSuccess = useCallback(
    (publicToken: string) => {
      dispatch(getAgaveAccountToken({ input: { public_token: publicToken } }))
        .unwrap()
        .then((agaveAccountToken) => {
          if (selectedPaidol?.id) {
            dispatch(persistAgaveAccountToken({ id: selectedPaidol.id, agaveAccountToken }));
            dispatch(getProjects({ input: { account_token: agaveAccountToken } }));
          }
        });
    },
    [dispatch, selectedPaidol?.id]
  );

  const { openLink, isReady } = useAgaveLink({
    referenceId: user!.sub,
    linkToken,
    showSandboxSourceSystems: process.env.REACT_APP_ENV === 'production' ? false : true, // Only for local development
    showProductionSourceSystems: true,
    sourceSystem: sourceSystem || ['sage-300-cre', 'vista-hosted', 'cmic', 'sage-100-contractor'],
    sourceSystemEnvironment: process.env.REACT_APP_ENV === 'production' ? 'prod' : 'sandbox',
    onSuccess,
  });

  const loading = useMemo(() => !isReady || !linkToken, [isReady, linkToken]);
  const connected = useMemo(() => Boolean(agaveAccountToken), [agaveAccountToken]);

  return { loading, connected, openPopup: openLink };
}
