import { PROCORE_TOKENS_KEY, getOrRefreshAccessToken } from 'app/pages/store/procoreSlice';
import { useAppDispatch } from 'app/store';
import { GraphQLError } from 'graphql';
import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export type LocalStorageTokens = {
  accessToken: string;
  refreshToken: string;
};

const isTokenExpiredError = (errors: GraphQLError[] | undefined) => {
  return errors?.some((error) => error.message.includes('expired'));
};

export const useFetchProcoreAuthToken = (codeParam: string | null) => {
  const dispatch = useAppDispatch();

  const [tokens, setTokens] = useLocalStorage<LocalStorageTokens>(PROCORE_TOKENS_KEY, {
    accessToken: '',
    refreshToken: '',
  });

  const handleAuthToken = useCallback(
    (code: string) => {
      dispatch(getOrRefreshAccessToken({ code, redirect_uri: window.location.href }))
        .unwrap()
        .then((response) => {
          if (response) {
            setTokens({
              accessToken: response.access_token,
              refreshToken: response.refresh_token,
            });
          }
        });
    },
    [dispatch, setTokens]
  );

  useEffect(() => {
    if (codeParam) {
      handleAuthToken(codeParam);
    }
  }, [codeParam, handleAuthToken]);

  const handleRequestError = useCallback(
    async (errors: GraphQLError[] | undefined) => {
      if (isTokenExpiredError(errors)) {
        dispatch(getOrRefreshAccessToken({ refresh_token: tokens.refreshToken }))
          .unwrap()
          .then((response) => {
            if (response) {
              setTokens({
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
              });
              // dispatchAndRetry(`Bearer ${response.access_token}`);
            }
          })
          .catch((errors: GraphQLError[] | undefined) => {
            // If refresh token request returns expired error, then user should be forced to sign in again
            if (isTokenExpiredError(errors)) {
              setTokens({
                accessToken: '',
                refreshToken: '',
              });
            }
          });
      }
    },
    [dispatch, setTokens, tokens.refreshToken]
  );

  return {
    tokens,
    handleRequestError,
  };
};
