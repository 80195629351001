import { Box, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import { useUpdateFirstRunState } from 'app/hooks/useUpdateFirstRunState';
import ConnectAccounting from 'app/pages/first-run/stepper/ConnectToAccountingSystemForm';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import Page from 'app/shared-components/layout/Page';
import { useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddTeamMembersForm from './AddTeamMembersForm';
import ConnectBankForm from './ConnectBankForm';
import CreateSpendRule from './CreateSpendRuleForm';
import IssueCardForm from './IssueCardForm';
import { OnboardStepsIds } from './stepper-utils/constants';
import { OnboardSteps } from './stepper-utils/models';

const OnboardReviewNew = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const isStepperComplete = selectedPaidol?.firstRunChecklist?.isStepperComplete;

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const { triggerUpdate } = useUpdateFirstRunState({
    operationName: 'completeFirstRunChecklist',
    firstRunValue: 'isStepperComplete',
  });

  const handleLastStep = () => {
    triggerUpdate();
  };

  // Used to pass user out of the stepper if they have already completed it
  // Based on if the user has already added a financial account, we can assume they've been onboarded
  useEffect(() => {
    if (integration?.externalFinancialBankAccountId) {
      triggerUpdate();
    }

    if (isStepperComplete) {
      navigate('/overview');
    }
  }, [integration?.externalFinancialBankAccountId, isStepperComplete, navigate, triggerUpdate]);

  // used to dynamically render the stepper based on the company type
  const OnboardStepsType = [
    ...(isConstructionType
      ? [{ id: OnboardStepsIds[OnboardSteps.Accounting], phrase: 'accountingStepperPhrase' }]
      : []),
    { id: OnboardStepsIds[OnboardSteps.TeamMembers], phrase: 'teamMemberStepperPhrase' },
    { id: OnboardStepsIds[OnboardSteps.SpendRules], phrase: 'spendRuleStepperPhrase' },
    { id: OnboardStepsIds[OnboardSteps.IssueCards], phrase: 'issueCardStepperPhrase' },
    { id: OnboardStepsIds[OnboardSteps.ConnectBank], phrase: 'connectBankStepperPhrase' },
  ];

  const StyledStepperBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
  }));

  return (
    <Page id={'onboard-review'}>
      <StyledStepperBox>
        <Typography sx={{ textAlign: 'center', mb: 4 }} variant="h2">
          {t(OnboardStepsType[activeStep].phrase)}
        </Typography>
        <Box className="max-w-lg mb-0 MuiBox-root css-1d5n5z6 md:w-512" sx={{ pb: 3 }}>
          <Stepper activeStep={activeStep}>
            {OnboardStepsType.map((step) => (
              <Step key={step.id}>
                <StepLabel
                  sx={{
                    p: 0,
                    '& .MuiStepLabel-iconContainer': { p: 0 },
                    '& .MuiStepIcon-root': {
                      fontSize: '3rem',
                    },
                  }}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 && isConstructionType && <ConnectAccounting handleNext={() => handleNext()} />}
        {activeStep === (isConstructionType ? 1 : 0) && (
          <AddTeamMembersForm handleNext={() => handleNext()} />
        )}
        {activeStep === (isConstructionType ? 2 : 1) && <CreateSpendRule handleNext={() => handleNext()} />}
        {activeStep === (isConstructionType ? 3 : 2) && <IssueCardForm handleNext={() => handleNext()} />}
        {activeStep === (isConstructionType ? 4 : 3) && (
          <ConnectBankForm handleNext={() => handleLastStep()} />
        )}
      </StyledStepperBox>
    </Page>
  );
};

export default OnboardReviewNew;
