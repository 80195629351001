import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UnreadMessagesQuery } from 'API';
import { unreadMessages } from 'graphql/queries';
import { RootState } from 'app/store/rootReducer';

export interface UnreadMessagesState {
  unreadCount: number;
}

export const initialState: UnreadMessagesState = {
  unreadCount: 0,
};

export const getUnreadMessages = createAsyncThunk(
  'unreadMessages/getUnreadMessages',
  async (userId: string) => {
    return (
      API.graphql(graphqlOperation(unreadMessages, { userId: userId })) as Promise<
        GraphQLResult<UnreadMessagesQuery>
      >
    ).then((res) => res.data?.unreadMessages?.unread_count || 0);
  }
);

const unreadMessagesSlice = createSlice({
  name: 'unreadMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUnreadMessages.fulfilled, (state, action) => {
      state.unreadCount = action.payload;
    });
  },
});

export const unreadMessagesSelect = (state: RootState) => state.overview?.unreadMessages || initialState;

export default unreadMessagesSlice.reducer;
