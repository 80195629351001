import { DeleteOutline, Lock, LockOpen } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { PaidolUserToHighnotePaymentCard, Role } from 'API';
import { resetGroupsSlice } from 'app/pages/store/groupsSlice';
import {
  activatePaymentCard,
  closePaymentCard,
  dispatchEmail,
  dispatchSpeedchatMessage,
  suspendPaymentCard,
} from 'app/pages/store/specificCardSlice';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import ResponsiveDialogActions from 'app/shared-components/layout/dialog/ResponsiveDialogActions';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import moment from 'moment';
import { useState } from 'react';

export enum ModalType {
  lock = 'lock',
  unlock = 'unlock',
  cancel = 'cancel',
}

export interface ModalActionsCardProps {
  open: boolean;
  type: ModalType;
  cardName: string;
  paymentCardId: string;
  paymentCardUsers?: Array<PaidolUserToHighnotePaymentCard>;
  onClose: () => void;
  onSuccess?: () => void;
}

function ModalActionsCard({
  open,
  type,
  paymentCardUsers,
  cardName,
  paymentCardId,
  onClose,
  onSuccess,
}: ModalActionsCardProps) {
  const dispatch = useAppDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const { user } = useAuth();
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);

  const unlockCard = () => {
    setBtnLoading(true);
    dispatch(
      activatePaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was unlocked on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;

        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const lockCard = () => {
    setBtnLoading(true);
    dispatch(
      suspendPaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was locked on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;

        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const cancelCard = () => {
    setBtnLoading(true);
    dispatch(
      closePaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was canceled on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;
        dispatch(resetGroupsSlice());
        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const modalTypes = {
    lock: {
      title: 'Lock this card?',
      list: <li>This will immediately block all future transactions</li>,
      function: lockCard,
      icon: <Lock />,
      btnText: 'Lock card',
    },
    unlock: {
      title: 'Unlock this card?',
      list: <li>This will immediately allow spending to resume on the card</li>,
      function: unlockCard,
      icon: <LockOpen />,
      btnText: 'Unlock card',
    },
    cancel: {
      title: 'Cancel this card?',
      list: (
        <>
          <li>This will remove all access to this card</li>
          <li>This cannot be undone</li>
        </>
      ),
      function: cancelCard,
      icon: <DeleteOutline />,
      btnText: 'Cancel card',
    },
  };
  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        color="error.main"
        sx={{
          fontSize: '2.4rem',
          mt: '2rem',
          p: '2rem 5rem 1rem',
        }}
      >
        {modalTypes[type].title}
      </DialogTitle>
      <DialogContent sx={{ p: '0 5rem' }}>
        <ul style={{ fontWeight: 'normal', marginTop: '0px' }}>{modalTypes[type].list}</ul>
        Are you sure you wish to {ModalType[type]} {cardName}?
      </DialogContent>
      <ResponsiveDialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          onClick={modalTypes[type].function}
          startIcon={modalTypes[type].icon}
          loadingPosition="start"
          color="error"
        >
          {modalTypes[type].btnText}
        </LoadingButton>
      </ResponsiveDialogActions>
    </ResponsiveDialog>
  );
}

export default ModalActionsCard;
