import { Menu, MenuOpen } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { captureException } from '@sentry/react';
import CompanySwitcher from 'app/shared-components/layout/CompanySwitcher';
import BrowserView from 'app/shared-components/util/BrowserView';
import MobileView from 'app/shared-components/util/MobileView';
import { useAppDispatch, useAppSelector } from 'app/store';
import { toggleDrawer, useNavigationState } from 'app/store/navigationSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import theme from 'app/theme';
import { ReactComponent as SpeedchainLogo } from 'assets/White-SpeedChain-Logo.svg';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import SpeedchatButton from './app-header/SpeedchatButton';
import UserMenu from './app-header/UserMenu';

function AppHeader(): JSX.Element {
  const dispatch = useAppDispatch();
  const { selectedPaidol: paidol } = useAppSelector(selectUserCompanies);
  const { drawerOpen } = useNavigationState();

  const getLogo = async (logo: string): Promise<string | undefined> => {
    try {
      return await Storage.get(logo);
    } catch (error) {
      captureException(error);
      return undefined;
    }
  };

  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (paidol?.logoUrl) {
      getLogo(paidol.logoUrl).then((url) => setLogoUrl(url));
    }
  }, [paidol?.logoUrl]);

  return (
    <AppBar
      position="static"
      sx={{
        background: `linear-gradient(to right, ${theme.palette.primary.darker} 0%, ${theme.palette.primary.main} 100%)`,
      }}
    >
      <Toolbar disableGutters sx={{ px: { xs: 1, md: 2 } }}>
        <IconButton color="inherit" aria-label="menu" sx={{ mr: 4 }} onClick={() => dispatch(toggleDrawer())}>
          {drawerOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Box sx={{ mr: 1, flexGrow: 1, display: 'flex' }}>
          {logoUrl ? <CustomLogo logoUrl={logoUrl} /> : <SpeedchainLogo />}
        </Box>
        <BrowserView>
          <CompanySwitcher sx={{ mr: 2, minWidth: 256 }} />
          <UserMenu />
        </BrowserView>
        <MobileView>
          <SpeedchatButton />
        </MobileView>
      </Toolbar>
    </AppBar>
  );
}

const CustomLogo = ({ logoUrl }: { logoUrl: string }) => {
  return (
    <Box
      component="img"
      src={logoUrl}
      alt="Custom Company Logo"
      sx={{
        maxWidth: 200,
        maxHeight: 40,
      }}
    />
  );
};

export default AppHeader;
