import { Fade, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { forwardRef, useEffect, useState } from 'react';
import { useAppDispatch } from 'app/store';
import { setCurrentPage } from 'app/store/navigationSlice';
import useScrollBottom from 'app/hooks/useScrollBottom';
import { useLocation } from 'react-router-dom';

interface PageProps {
  id: string;
  Header?: React.ReactNode;
  NavigationTabs?: React.ReactNode;
  onScrollToBottom?: (isBottom: boolean) => void;
  children?: React.ReactNode;
  margins?: boolean;
}

const Page = forwardRef(function (
  { id, Header, NavigationTabs, onScrollToBottom, children, margins = false }: PageProps,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const dispatch = useAppDispatch();
  const [fadeIn, setFadeIn] = useState(false);
  const [isBottom, scrollRef] = useScrollBottom({ offset: 100, ref });

  const location = useLocation();
  const isReviewPage = location.pathname.match(/^\/review/);

  useEffect(() => {
    setFadeIn(true);
    dispatch(setCurrentPage(id));

    return () => {
      setFadeIn(false);
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (onScrollToBottom) {
      onScrollToBottom(isBottom);
    }
  }, [isBottom, onScrollToBottom]);

  return (
    <Fade in={fadeIn}>
      <Box
        ref={scrollRef}
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'auto',
          pl: isReviewPage ? { md: 2 } : { md: 1 },
        }}
      >
        {Header}
        <Paper
          elevation={0}
          sx={{
            flexGrow: 1,
            borderRadius: { md: 4 },
            mt: { md: Header ? undefined : 2 },
            mr: { md: 2 },
            mb: { md: 2 },
            boxShadow: { md: '5px 5px 20px 0px #00000040' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {NavigationTabs}
          <Box
            sx={{
              p: margins ? 2 : 0,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
});

export default Page;
