import { Typography } from '@mui/material';
import { Currency as CurrencyType } from 'API';
import Currency from 'app/shared-components/util/Currency';
import { addMonths, format } from 'date-fns';

export type Spent = Omit<CurrencyType, '__typename'>;
export type Budget = Omit<CurrencyType, '__typename'>;
export type BudgetProgressSize = 'small' | 'regular';

export const getSpentPercentage = (spent: number, total: number) => {
  if (total === 0) return 0;
  const result = (spent * 100) / total;
  return result > 100 ? 100 : result;
};

export const isOver = (spent: Spent, isUnlimited: boolean, budget?: Budget) => {
  if (isUnlimited) return false;

  return Boolean(budget?.value !== undefined && budget.value < spent.value);
};

export const getAmountHelperText = (
  size: BudgetProgressSize,
  spent: Spent,
  isUnlimited: boolean,
  budget?: Budget,
  dark = false
) => {
  let props = {};
  let text = '';

  if (isUnlimited) {
    props = dark && { color: 'white' };
    text = 'spent';
  } else if (isOver(spent, isUnlimited, budget)) {
    text = 'over';
    props = { color: 'error' };
  } else if (budget?.value) {
    props = dark && { color: 'white' };
    text = 'available';
  }

  return (
    text && (
      <Typography variant="h4" fontWeight={400} ml={1} {...props}>
        {text}
      </Typography>
    )
  );
};

export const getAvailableOrSpentAmountValue = (
  size: BudgetProgressSize,
  spent: Spent,
  isUnlimited: boolean,
  budget?: Budget,
  dark = false
) => {
  const props: any = {
    ...(size === 'small' && { fontSize: 16 }),
    ...(dark && { color: 'white ' }),
  };
  let value: number | undefined = undefined;

  if (isUnlimited) {
    value = spent.value;
  } else if (isOver(spent, isUnlimited, budget)) {
    value = spent.value - budget!.value;
    props.color = 'error';
  } else {
    value = budget!.value - spent.value;
  }

  const thousandFormatValue = formatValueOnThousandOrHundred(
    roundValueOnThousandOrHundred(value !== undefined ? value / 100 : undefined)
  );

  const _value = size === 'small' ? thousandFormatValue : <Currency value={value || 0} currency="USD" />;

  return (
    <Typography
      variant="display"
      fontWeight="bold"
      fontSize={{ xs: '24px', sm: '20px', md: '28px' }}
      {...props}
    >
      {_value}
    </Typography>
  );
};

export const getDisplayPercentage = (spent: Spent, isUnlimited: boolean, budget?: Budget) => {
  const percentage =
    isUnlimited || budget?.value === undefined
      ? 100
      : Math.floor(getSpentPercentage(spent.value, budget?.value));

  if (isOver(spent, isUnlimited, budget) || isUnlimited) {
    return percentage;
  } else {
    return 100 - percentage;
  }
};

const roundValueOnThousandOrHundred = (value?: number) => {
  if (value === undefined) return value;

  return value < 1000 ? value : Math.floor(value / 100) * 100;
};

const formatValueOnThousandOrHundred = (value?: number) => {
  if (value === undefined) return '';

  return value < 1000 ? `$${value}` : `$${value / 1000}k`;
};

export const getBudgetHelperText = (
  size: BudgetProgressSize,
  spent: Spent,
  yearAndMonth: string,
  isUnlimited: boolean,
  budget?: Budget,
  dark = false
) => {
  let text = '';
  const props = dark && { color: 'white' };

  if (isUnlimited || budget?.value === undefined || budget.value === 0) {
    text = 'unlimited';
  } else {
    const _budget = budget!.value / 100;
    const roundedBudget = roundValueOnThousandOrHundred(_budget);
    const formattedBudget = formatValueOnThousandOrHundred(roundedBudget);
    const percent = Math.floor(getSpentPercentage(spent.value, budget!.value));
    const month = format(addMonths(new Date(yearAndMonth), 1), 'LLL');

    if (isOver(spent, isUnlimited, budget)) {
      text = `${percent}% of ${formattedBudget} ${month} budget spent`;
      if (size !== 'small') text += ` ${month} budget`;
    } else {
      if (size === 'small') text = `${percent}% of ${formattedBudget} spent`;
      else text = `${percent}% of ${formattedBudget} ${month} budget spent`;
    }
  }

  return (
    <Typography variant="h4" fontSize={12} fontWeight={400} textAlign="right" {...props}>
      {text}
    </Typography>
  );
};

export const getProgressColor = (spent: Spent, isUnlimited: boolean, budget?: Budget) => {
  if (isUnlimited || budget?.value === undefined) return undefined;
  if (isOver(spent, isUnlimited, budget)) return 'RED';
  if (getDisplayPercentage(spent, isUnlimited, budget) <= 100 - 75) return 'YELLOW';
};
