import { EditSpendRuleFormValues } from 'app/pages/cards/spend-rules/SpendRulesForm';
import {
  getMerchantSpendRules,
  newHighnoteSpendRule,
  newMerchantSpendRule,
} from 'app/pages/store/spendRulesSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback } from 'react';

export function useCreateSpendRule() {
  const dispatch = useAppDispatch();
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);

  const createSpendRuleHandler = useCallback(
    async ({
      name,
      spendingByCategory,
      spendingByCountry,
      spendingByCountryType,
      spendingByCategoryType,
    }: EditSpendRuleFormValues) => {
      if (!spendingByCategoryType && !spendingByCountryType) {
        return;
      }

      const countriesPayload = {
        [spendingByCountryType as string]: spendingByCountry,
      };

      const categoriesPayload = {
        [spendingByCategoryType as string]: spendingByCategory,
      };

      const action: any = await dispatch(
        newHighnoteSpendRule({
          merchantCategoryInput: { name, ...categoriesPayload },
          merchantCountryInput: { name, ...countriesPayload },
        })
      );

      await dispatch(
        newMerchantSpendRule({
          createMerchantSpendRuleInput: {
            paidolId: selectedPaidolId,
            authPaidolId: selectedPaidolId,
            name,
            merchantCategorySpendRuleId: action.payload?.hnCreateMerchantCategorySpendRule?.id,
            merchantCountrySpendRuleId: action.payload?.hnCreateMerchantCountrySpendRule?.id,
          },
        })
      );

      await dispatch(getMerchantSpendRules({ paidolId: selectedPaidolId }));
    },
    [dispatch, selectedPaidolId]
  );

  return createSpendRuleHandler;
}
