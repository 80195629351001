import { OnboardSteps } from './models';

export const OnboardStepsIds = {
  [OnboardSteps.Accounting]: 'organizationProfile',
  [OnboardSteps.TeamMembers]: 'primaryAuthorizedPerson',
  [OnboardSteps.SpendRules]: 'administrator',
  [OnboardSteps.IssueCards]: 'beneficialOwners',
  [OnboardSteps.ConnectBank]: 'informationCertification',
};

/* The phrases are phrase translations keys */
export const OnboardStepsWithPhrases = [
  {
    id: OnboardStepsIds[OnboardSteps.Accounting],
    phrase: 'accountingStepperPhrase',
  },
  {
    id: OnboardStepsIds[OnboardSteps.TeamMembers],
    phrase: 'teamMemberStepperPhrase',
  },
  {
    id: OnboardStepsIds[OnboardSteps.SpendRules],
    phrase: 'spendRuleStepperPhrase',
  },
  {
    id: OnboardStepsIds[OnboardSteps.IssueCards],
    phrase: 'issueCardStepperPhrase',
  },
  {
    id: OnboardStepsIds[OnboardSteps.ConnectBank],
    phrase: 'connectBankStepperPhrase',
  },
];
