import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';

export const COPIED_TO_CLIPBOARD = 'Copied to clipboard';

export enum DateFilterKeys {
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_QUARTER = 'thisQuarter',
  THIS_YEAR = 'thisYear',
  LAST_WEEK = 'lastWeek',
  LAST_MONTH = 'lastMonth',
  LAST_QUARTER = 'lastQuarter',
  LAST_YEAR = 'lastYear',
}

export interface DateOption {
  id: DateFilterKeys;
  label: string;
  getDates: () => Array<Date>;
}

export const DATE_FILTER_OPTIONS: Array<DateOption> = [
  {
    id: DateFilterKeys.THIS_WEEK,
    label: 'This week',
    getDates() {
      const today = new Date();
      return [startOfWeek(today), endOfWeek(today)];
    },
  },
  {
    id: DateFilterKeys.THIS_MONTH,
    label: 'This month',
    getDates() {
      const today = new Date();
      return [startOfMonth(today), endOfMonth(today)];
    },
  },
  {
    id: DateFilterKeys.THIS_QUARTER,
    label: 'This quarter',
    getDates() {
      const today = new Date();
      return [startOfQuarter(today), endOfQuarter(today)];
    },
  },
  {
    id: DateFilterKeys.THIS_YEAR,
    label: 'This year',
    getDates() {
      const today = new Date();
      return [startOfYear(today), endOfYear(today)];
    },
  },
  {
    id: DateFilterKeys.LAST_WEEK,
    label: 'Last week',
    getDates() {
      const today = new Date();
      const lastWeek = subWeeks(today, 1);
      return [startOfWeek(lastWeek), endOfWeek(lastWeek)];
    },
  },
  {
    id: DateFilterKeys.LAST_MONTH,
    label: 'Last month',
    getDates() {
      const today = new Date();
      const lastMonth = subMonths(today, 1);
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)];
    },
  },
  {
    id: DateFilterKeys.LAST_QUARTER,
    label: 'Last quarter',
    getDates() {
      const today = new Date();
      const lastQuarter = subQuarters(today, 1);
      return [startOfQuarter(lastQuarter), endOfQuarter(lastQuarter)];
    },
  },
  {
    id: DateFilterKeys.LAST_YEAR,
    label: 'Last year',
    getDates() {
      const today = new Date();
      const lastYear = subYears(today, 1);
      return [startOfYear(lastYear), endOfYear(lastYear)];
    },
  },
];
