import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  HNFinancialAccountActivityConnection,
  HnFindFinancialAccountActivitiesQuery,
  HnFindFinancialAccountActivitiesQueryVariables,
  HnFindFinancialAccountLedgerActivityQuery,
  HnFindFinancialAccountLedgerActivityQueryVariables,
  HNLedger,
  HNPageInfo,
} from 'API';
import {
  hnFindFinancialAccountActivities,
  hnFindFinancialAccountLedgerActivity,
} from 'app/pages/store/queries';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';

export interface WalletState {
  financialAccountLedgersActivity: HNLedger[] | null | undefined;
  automaticFundingPendingActivity:
    | {
        amount: {
          value: number;
          currencyCode: string;
        };
        date: string;
      }
    | null
    | undefined;
  pageInfo?: HNPageInfo;
  isActivitiesLoading: boolean;
  loadMore: boolean;
}

const initialState: WalletState = {
  financialAccountLedgersActivity: null,
  automaticFundingPendingActivity: null,
  pageInfo: undefined,
  isActivitiesLoading: false,
  loadMore: false,
};

export const getFinancialAccountLedgerActivity = createAsyncThunk(
  'wallet/getFinancialAccountLedgerActivity',
  async ({ id, activitiesAfterCursor = undefined }: HnFindFinancialAccountLedgerActivityQueryVariables) => {
    return (
      API.graphql(
        graphqlOperation(hnFindFinancialAccountLedgerActivity, {
          id,
          firstActivities: 20,
          activitiesAfterCursor,
        })
      ) as Promise<GraphQLResult<HnFindFinancialAccountLedgerActivityQuery>>
    ).then((result) => result.data?.hnFindFinancialAccountLedgerActivity);
  }
);

export const getFinancialAccountActivities = createAsyncThunk(
  'wallet/getFinancialAccountActivities',
  async ({ id, activitiesAfterCursor = undefined }: HnFindFinancialAccountActivitiesQueryVariables) => {
    return (
      API.graphql(
        graphqlOperation(hnFindFinancialAccountActivities, {
          id,
          firstActivities: 20,
          activitiesAfterCursor,
          filterBy: {
            isComplete: {
              equals: false,
            },
          },
        })
      ) as Promise<GraphQLResult<HnFindFinancialAccountActivitiesQuery>>
    ).then((result) => result.data?.hnFindFinancialAccountActivities);
  }
);

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetWalletSlice: () => initialState,

    setLoadMore: (state) => {
      state.loadMore = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFinancialAccountLedgerActivity.pending, (state) => {
        state.isActivitiesLoading = true;
      })
      .addCase(getFinancialAccountLedgerActivity.fulfilled, (state, action) => {
        const ledgerActivity = action.payload;
        if (ledgerActivity) {
          if (state.loadMore === true) {
            state.financialAccountLedgersActivity = [
              ...(state.financialAccountLedgersActivity || []),
              ...(ledgerActivity.ledgers || []),
            ];
            state.loadMore = false;
          } else {
            state.financialAccountLedgersActivity = ledgerActivity.ledgers ?? [];
          }
          state.pageInfo = (ledgerActivity.ledgers as HNLedger[])?.[0].ledgerEntries?.pageInfo ?? undefined;
        }
        state.isActivitiesLoading = false;
      });
    builder.addCase(getFinancialAccountActivities.fulfilled, (state, action) => {
      const activities = action.payload?.financialAccountActivities as HNFinancialAccountActivityConnection;

      activities.edges?.find(({ node }) => {
        if (
          node?.source?.__typename === 'HNIntegratorInitiatedFundsDepositACHTransfer' &&
          node?.source?.descriptor?.companyEntryDescription === 'Automatic'
        ) {
          state.automaticFundingPendingActivity = {
            amount: {
              value: node.pendingAmount?.value || 0,
              currencyCode: node.pendingAmount?.currencyCode || 'USD',
            },
            date: node.source.settlementDate || '',
          };
          return true;
        }
        return false;
      });
    });
  },
});

export const { resetWalletSlice, setLoadMore } = walletSlice.actions;

export const selectWalletSlice = (state: RootState): WalletState => state?.wallet ?? initialState;

export default walletSlice.reducer;
