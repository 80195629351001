import { CardViewerRenderedFields, renderFields } from '@highnoteplatform/card-viewer';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { getCardTypeDescription } from 'app/shared-components/util/utils';
import { useAppSelector } from 'app/store';
import theme from 'app/theme';
import { ReactComponent as SpeedchainLogo } from 'assets/Blue-SpeedChain-Logo.svg';
import { ReactComponent as SpeedchainLogoGray } from 'assets/Gray-SpeedChain-Logo.svg';
import { ReactComponent as MasterCardMetal } from 'assets/master-card-logo-metal.svg';
import { useEffect, useMemo, useState } from 'react';
import { COPIED_TO_CLIPBOARD } from 'util/constants';
import { CardWrapper } from './Style';
import { ReactComponent as VirtualChip } from './assets/Chip-Virtual.svg';
import { ReactComponent as Chip } from './assets/Chip.svg';

interface Props {
  clientToken: string;
  paymentCardId: string;
  name?: string | null | undefined;
  formFactor: string;
  sx?: object;
  zipcode?: string;
}

const SpecificCard = ({ sx, clientToken, paymentCardId, name, formFactor, zipcode }: Props) => {
  const { integration } = useAppSelector(selectReviewOnboardSlice);

  const CARD_TYPE_DESCRIPTION = useMemo(
    () => getCardTypeDescription(integration?.highnoteProduct?.type),
    [integration?.highnoteProduct?.type]
  );

  const [show, setShow] = useState(false);
  const [openTooltip, setOpen] = useState({
    cardNumber: false,
    expirationDate: false,
    cvv: false,
    zipcode: false,
  });
  const [loading, setLoading] = useState(false);
  const [sdk, setSdk] = useState<CardViewerRenderedFields>();

  const cardInfoStyle = (fontSize: number | string) => ({
    fontSize: fontSize + 'px',
    color: formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white',
    fontWeight: '600',
    cursor: 'pointer',
  });

  const clearData = async () => {
    return new Promise((resolve, reject) => {
      document.getElementById('cardNumber')!.innerHTML = '';
      document.getElementById('expirationDate')!.innerHTML = '';
      document.getElementById('cvv')!.innerHTML = '';
      resolve(true);
    });
  };

  const ViewPaymentCardForSdk = async () => {
    return await renderFields({
      clientToken: clientToken,
      paymentCardId: paymentCardId,
      enableClipboard: true,
      environment: process.env.REACT_APP_ENV === 'production' ? 'live' : 'test',

      onCopyToClipboardSuccess: ({ field }) => {
        setOpen({ ...openTooltip, [field]: true });
      },
      onError: (error: unknown) => {
        // eslint-disable-next-line no-console
        console.warn(error);
        // Handle errors
      },
      elements: {
        cardNumber: {
          selector: '#cardNumber',
          styles: cardInfoStyle(28),
        },

        expirationDate: {
          selector: '#expirationDate',
          styles: cardInfoStyle(16),
        },

        cvv: {
          selector: '#cvv',
          styles: cardInfoStyle(16),
        },
      },
    });
  };

  const hideAndShow = () => {
    sdk?.toggleCardNumberMask();
    setShow((show) => !show);
  };

  useEffect(() => {
    setLoading(true);
    if (clientToken) {
      clearData().then(() => {
        ViewPaymentCardForSdk()
          .then((response) => {
            setSdk(response);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
    return () => {
      sdk?.unmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken]);

  const copyZipcode = () => {
    setOpen({ ...openTooltip, zipcode: true });
    const zipcode = document.getElementById('zipcode')?.innerText;
    zipcode && navigator.clipboard.writeText(zipcode);
  };

  return (
    <CardWrapper sx={sx} formFactor={formFactor}>
      <Box
        sx={{
          zIndex: 10,
          height: { sm: '275px' },
          width: { sm: '435px' },
          px: { xs: 1, sm: 3 },
          pt: { xs: 2, sm: 3 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',

            pb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {formFactor === 'VIRTUAL' ? (
              <VirtualChip />
            ) : (
              <>
                <Chip />
                <Typography sx={{ pt: 0.5 }} variant="xsmall" color={theme.palette.secondary.main}>
                  {formFactor}
                </Typography>
              </>
            )}
          </Box>
          {formFactor === 'VIRTUAL' ? (
            <SpeedchainLogoGray style={{ paddingTop: 2 }} />
          ) : (
            <SpeedchainLogo style={{ paddingTop: 2 }} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h4" color={formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white'}>
            {name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 2,
            }}
          >
            <Tooltip
              title={COPIED_TO_CLIPBOARD}
              placement="top"
              leaveDelay={500}
              open={openTooltip.cardNumber}
              onClose={() => setOpen({ ...openTooltip, cardNumber: false })}
              arrow
            >
              <div id="cardNumber"></div>
            </Tooltip>
            <IconButton
              sx={{
                color: formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white',
                backgroundColor: 'transparent !important',
                p: 0.5,
              }}
              onClick={() => hideAndShow()}
            >
              {show ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 4,
              pt: 2,
            }}
          >
            <Tooltip
              title={COPIED_TO_CLIPBOARD}
              placement="top"
              leaveDelay={500}
              open={openTooltip.expirationDate}
              onClose={() => setOpen({ ...openTooltip, expirationDate: false })}
              arrow
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 30,
                }}
                variant="medium"
                color={formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white'}
              >
                Exp <div id="expirationDate"></div>
              </Typography>
            </Tooltip>
            <Tooltip
              title={COPIED_TO_CLIPBOARD}
              placement="top"
              leaveDelay={500}
              open={openTooltip.cvv}
              onClose={() => setOpen({ ...openTooltip, cvv: false })}
              arrow
            >
              <Typography
                sx={{ display: 'flex', alignItems: 'center', height: 30 }}
                variant="medium"
                color={formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white'}
              >
                Code <div id="cvv"></div>
              </Typography>
            </Tooltip>
            <Tooltip
              title={COPIED_TO_CLIPBOARD}
              placement="top"
              leaveDelay={500}
              open={openTooltip.zipcode}
              onClose={() => setOpen({ ...openTooltip, zipcode: false })}
              arrow
            >
              <Typography
                sx={{ display: 'flex', alignItems: 'center', height: 30 }}
                variant="medium"
                color={formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white'}
              >
                ZIP
                <Box id="zipcode" onClick={copyZipcode} style={{ padding: '0 4px', ...cardInfoStyle(16) }}>
                  {zipcode}
                </Box>
              </Typography>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              sx={{ opacity: 0.8 }}
              variant="small"
              color={formFactor === 'VIRTUAL' ? theme.palette.text.secondary : 'white'}
            >
              {CARD_TYPE_DESCRIPTION}
            </Typography>
            <MasterCardMetal />
          </Box>
        </Box>
        {loading && (
          <Box className="animate">
            <Box className="over" />
            <Box className="pulse" />
          </Box>
        )}
      </Box>
    </CardWrapper>
  );
};

export default SpecificCard;
