import React from 'react';
import {
  // Importing Link here so we can bridge it with MUI
  // eslint-disable-next-line no-restricted-imports
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

// NOTE: this component is just glue code; don't use
// it directly.
// See: https://mui.com/guides/routing/#global-theme-link
const LinkBehavior = React.forwardRef<never, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, children, ...other } = props;
    const hrefString = String(href);

    if (hrefString.match(/^https?:\/\//)) {
      // For external links, render a plain <a> element
      return (
        // eslint-disable-next-line react/forbid-elements
        <a ref={ref} href={hrefString} {...other}>
          {children}
        </a>
      );
    }

    // Map href (MUI) -> to (react-router)
    return (
      <RouterLink ref={ref} to={href} {...other}>
        {children}
      </RouterLink>
    );
  }
);

export default LinkBehavior;
