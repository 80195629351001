export const updateOnboard = /* GraphQL */ `
  mutation UpdateOnboard($input: UpdateOnboardInput!, $condition: ModelOnboardConditionInput) {
    updateOnboard(input: $input, condition: $condition) {
      id
      status
      invitationCode
      formData {
        organizationProfile {
          productId
          legalBusinessName
          doingBusinessAs
          phoneNumber {
            countryCode
            number
            extension
          }
          phoneNumberValue
          phoneExtension
          hasExtension
          businessType
          annualRevenue
          billingAddress {
            country
            state
            city
            address
            extendedAddress
            zip
          }
          identification
          businessIndustryClassification {
            industryId
            industryName
            businessId
            businessName
          }
          isConstructionType
        }
        primaryAuthorizedPerson {
          firstName
          lastName
          email
          phoneNumber {
            countryCode
            number
            extension
          }
          phoneNumberValue
          phoneExtension
          hasExtension
          birthdate
          homeAddress {
            country
            state
            city
            address
            extendedAddress
            zip
          }
          socialSecurityNumber
          jobTitle
          primaryAuthorizedPersonConsent {
            financialAccountPermission
            cardholderAgreementPermission
            issuingPermission
            signature
          }
          percentageOwnership
        }
        administrator {
          firstName
          lastName
          email
        }
        beneficialOwners {
          firstName
          lastName
          email
          phoneNumber {
            countryCode
            number
            extension
          }
          phoneNumberValue
          phoneExtension
          hasExtension
          homeAddress {
            country
            state
            city
            address
            extendedAddress
            zip
          }
          socialSecurityNumber
          birthdate
          percentageOwnership
        }
        informationCertification
      }
      rebatePercentage
      createdAt
      updatedAt
    }
  }
`;

export const customUpdateHighnoteTransaction = /* GraphQL */ `
  mutation UpdateHighnoteTransaction(
    $input: UpdateHighnoteTransactionInput!
    $condition: ModelHighnoteTransactionConditionInput
  ) {
    updateHighnoteTransaction(input: $input, condition: $condition) {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const customCreateReimbursementTransaction = /* GraphQL */ `
  mutation CreateReimbursementTransaction(
    $input: CreateReimbursementTransactionInput!
    $condition: ModelReimbursementTransactionConditionInput
  ) {
    createReimbursementTransaction(input: $input, condition: $condition) {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const customUpdateReimbursementTransaction = /* GraphQL */ `
  mutation UpdateReimbursementTransaction(
    $input: UpdateReimbursementTransactionInput!
    $condition: ModelReimbursementTransactionConditionInput
  ) {
    updateReimbursementTransaction(input: $input, condition: $condition) {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      financialAccountId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const customUpdatePaidolHighnoteIntegration = /* GraphQL */ `
  mutation UpdatePaidolHighnoteIntegration(
    $input: UpdatePaidolHighnoteIntegrationInput!
    $condition: ModelPaidolHighnoteIntegrationConditionInput
  ) {
    updatePaidolHighnoteIntegration(input: $input, condition: $condition) {
      id
      paidolId
      userId
      legalBusinessName
      businessAccountHolderId
      primaryAuthorizedPersonId
      financialAccountId
      externalFinancialBankAccountId
      hasInitialFunding
      accountHolderCardProductApplicationId
      isApplicationAccepted
      isDisconnected
      highnoteProductId
      finicityCustomerId
      dwollaCustomerId
      cardProfileId
      createdAt
      updatedAt
      __typename
    }
  }
`;
