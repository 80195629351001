import { useAuth } from 'app/shared-components/auth/AuthProvider';
import Loading from 'app/shared-components/util/Loading';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  FeatureFlagsState,
  getRemoteFlags,
  initialState,
  selectFeatureFlags,
  setFlagsLoaded,
} from 'app/store/featureFlagsSlice';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { captureException } from '@sentry/react';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';

const FeatureFlagContext = createContext<FeatureFlagsState>(initialState);

interface FeatureFlagProviderProps {
  children: ReactNode;
  onFeatureFlagsLoaded?: () => void;
}

function FeatureFlagProvider({ children, onFeatureFlagsLoaded }: FeatureFlagProviderProps): JSX.Element {
  const dispatch = useAppDispatch();
  const featureFlagsState = useAppSelector(selectFeatureFlags);
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { user } = useAuth();
  const { areFeatureFlagsLoaded } = featureFlagsState;

  useEffect(() => {
    if (user) {
      dispatch(
        getRemoteFlags({
          email: user.email,
          paidolId: selectedPaidol?.id ?? '',
        })
      ).finally(() => {
        dispatch(setFlagsLoaded(true));
        if (onFeatureFlagsLoaded) {
          onFeatureFlagsLoaded();
        }
      });
    }
  }, [dispatch, onFeatureFlagsLoaded, selectedPaidol?.id, user]);

  useEffect(() => {
    const refreshFlagsInterval = setInterval(() => {
      if (areFeatureFlagsLoaded) {
        if (user) {
          dispatch(
            getRemoteFlags({
              email: user.email,
              paidolId: selectedPaidol?.id ?? '',
            })
          );
        }
      }
    }, 15 * 60 * 1000);

    return () => clearInterval(refreshFlagsInterval);
  }, [areFeatureFlagsLoaded, dispatch, selectedPaidol?.id, user]);

  return (
    <FeatureFlagContext.Provider value={featureFlagsState}>
      {!user || featureFlagsState?.areFeatureFlagsLoaded ? children : <Loading />}
    </FeatureFlagContext.Provider>
  );
}

export function useFeatureFlags(): FeatureFlagsState {
  return useContext(FeatureFlagContext);
}

export function isEnabled(
  featureName: string,
  flagName: string,
  flagsState: FeatureFlagsState
): boolean | undefined {
  const { areFeatureFlagsLoaded, flags } = flagsState;

  if (!areFeatureFlagsLoaded) {
    return undefined;
  }

  let flagValue = flags[featureName];

  if (typeof flagValue === 'string') {
    try {
      flagValue = JSON.parse(flagValue);
    } catch (e) {
      captureException(e);
      console.error(`Error parsing feature flag ${featureName}: ${e}`);
      return false;
    }
  }

  if (flagValue && typeof flagValue === 'object') {
    return flagValue[flagName] ?? false;
  }

  return flagValue;
}

export const useIsEnabled = (
  featureFlags: { featureName: string; flagName: string }[]
): Record<string, boolean | undefined> => {
  const flagsState = useFeatureFlags();

  const result = featureFlags.reduce((acc, { featureName, flagName }) => {
    acc[`${featureName}.${flagName}`] = isEnabled(featureName, flagName, flagsState);
    return acc;
  }, {} as Record<string, boolean | undefined>);

  return result;
};

export default FeatureFlagProvider;
