import shortUUID from 'short-uuid';
// eslint-disable-next-line you-dont-need-lodash-underscore/is-null

type ReturnType = { path: string; fileId: string };

export const getCompanyLogoPath = (paidolId: string, fileName: string): ReturnType => {
  const uuid = shortUUID.uuid();
  return {
    path: `paidol/${paidolId}/logo/${fileName}`,
    fileId: uuid,
  };
};

export const getBase64FromFile = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = () => {
      res(`data:${file.type};base64,${btoa(reader.result as string)}`);
    };
    reader.onerror = rej;
    reader.readAsBinaryString(file);
  });
};

export const getPaymentFilesPath = (paidolId: string, fileName: string): ReturnType => {
  const uuid = shortUUID.uuid();
  return {
    path: `paidol/${paidolId}/paymentfiles/${uuid}/${fileName}`,
    fileId: uuid,
  };
};

export const getSupplierFilesPath = (paidolId: string, fileName: string): string => {
  return `paidol/${paidolId}/supplierfiles/${shortUUID.uuid()}/${fileName}`;
};

export const getCompanySelection = (): string => {
  return (localStorage.getItem('selectedPaidol') ?? '') as string;
};

export const setCompanySelection = (paidolId: string): void => {
  localStorage.setItem('selectedPaidol', paidolId);
};

export const clearCompanySelection = (): void => {
  localStorage.removeItem('selectedPaidol');
};

export const getSuppliersFilesPath = (paidolId: string, fileName: string): ReturnType => {
  const uuid = shortUUID.uuid();
  return {
    path: `paidol/${paidolId}/suppliersfiles/${uuid}/${fileName}`,
    fileId: uuid,
  };
};

export const getPageName = (pathName: string): string => {
  return pathName.slice(1).replaceAll('/', '-');
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function removeTypename(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  } else if (obj !== null && typeof obj === 'object') {
    const { __typename, ...rest } = obj;
    return Object.keys(rest).reduce((acc, key) => {
      acc[key] = removeTypename(rest[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

export function hasAnyFields(obj: any) {
  return obj && Object.keys(obj).length > 0;
}
