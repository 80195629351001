import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  AttachDirectCostReceiptInputData,
  AttachDirectCostReceiptMutation,
  AttachDirectCostReceiptMutationVariables,
  CreateOrUpdateProcoreDirectCostInputData,
  CreateOrUpdateProcoreDirectCostMutation,
  CreateOrUpdateProcoreDirectCostMutationVariables,
  CreateReimbursementDirectCostInputData,
  CreateReimbursementDirectCostMutation,
  CreateReimbursementDirectCostMutationVariables,
  GetOrRefreshProcoreAccessTokenInputData,
  GetOrRefreshProcoreAccessTokenMutation,
  GetOrRefreshProcoreAccessTokenMutationVariables,
  GetOrRefreshProcoreAccessTokenPayload,
  GetProcoreCompaniesInputData,
  GetProcoreCompaniesPayload,
  GetProcoreCompaniesQuery,
  GetProcoreCompaniesQueryVariables,
  GetProcoreProjectUsersInputData,
  GetProcoreProjectUsersPayload,
  GetProcoreProjectUsersQuery,
  GetProcoreProjectUsersQueryVariables,
  GetProcoreProjectsInputData,
  GetProcoreProjectsPayload,
  GetProcoreProjectsQuery,
  GetProcoreProjectsQueryVariables,
  GetProcoreWBSCodesInputData,
  GetProcoreWBSCodesPayload,
  GetProcoreWBSCodesQuery,
  GetProcoreWBSCodesQueryVariables,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import {
  attachDirectCostReceipt,
  createOrUpdateProcoreDirectCost,
  createReimbursementDirectCost,
  getOrRefreshProcoreAccessToken,
} from 'graphql/mutations';
import {
  getProcoreCompanies,
  getProcoreProjectUsers,
  getProcoreProjects,
  getProcoreWBSCodes,
} from 'graphql/queries';

export const PROCORE_TOKENS_KEY = 'PROCORE_TOKENS';

export interface ProcoreState {
  loading: boolean;
  companies: GetProcoreCompaniesPayload[];
  selectedCompany?: GetProcoreCompaniesPayload;
  projects: GetProcoreProjectsPayload[];
  projectUsers: GetProcoreProjectUsersPayload[];
  projectWbsCodes: Record<number, GetProcoreWBSCodesPayload[]>;
}

const initialState: ProcoreState = {
  loading: false,
  companies: [],
  selectedCompany: undefined,
  projects: [],
  projectUsers: [],
  projectWbsCodes: {},
};

export const getOrRefreshAccessToken = createAsyncThunk(
  'procore/getOrRefreshAccessToken',
  async (data: GetOrRefreshProcoreAccessTokenInputData, { rejectWithValue }) => {
    const variables: GetOrRefreshProcoreAccessTokenMutationVariables = {
      input: { operationName: 'getOrRefreshAccessToken', data },
    };

    return (
      API.graphql(graphqlOperation(getOrRefreshProcoreAccessToken, variables)) as Promise<
        GraphQLResult<GetOrRefreshProcoreAccessTokenMutation>
      >
    )
      .then((result) => {
        return result.data?.getOrRefreshProcoreAccessToken as
          | GetOrRefreshProcoreAccessTokenPayload
          | undefined;
      })
      .catch((result: GraphQLResult<GetOrRefreshProcoreAccessTokenMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getCompanies = createAsyncThunk(
  'procore/getCompanies',
  async (data: GetProcoreCompaniesInputData, { rejectWithValue }) => {
    const variables: GetProcoreCompaniesQueryVariables = {
      input: { operationName: 'getCompanies', data },
    };

    return (
      API.graphql(graphqlOperation(getProcoreCompanies, variables)) as Promise<
        GraphQLResult<GetProcoreCompaniesQuery>
      >
    )
      .then((result) => {
        return result.data?.getProcoreCompanies as GetProcoreCompaniesPayload[] | undefined;
      })
      .catch((result: GraphQLResult<GetProcoreCompaniesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getProjects = createAsyncThunk(
  'procore/getProjects',
  async (data: GetProcoreProjectsInputData, { rejectWithValue }) => {
    const variables: GetProcoreProjectsQueryVariables = {
      input: { operationName: 'getProjects', data },
    };

    return (
      API.graphql(graphqlOperation(getProcoreProjects, variables)) as Promise<
        GraphQLResult<GetProcoreProjectsQuery>
      >
    )
      .then((result) => {
        return result.data?.getProcoreProjects as GetProcoreProjectsPayload[] | undefined;
      })
      .catch((result: GraphQLResult<GetProcoreProjectsQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getProjectUsers = createAsyncThunk(
  'procore/getProjectUsers',
  async (data: GetProcoreProjectUsersInputData, { rejectWithValue }) => {
    const variables: GetProcoreProjectUsersQueryVariables = {
      input: { operationName: 'getProjectUsers', data },
    };

    return (
      API.graphql(graphqlOperation(getProcoreProjectUsers, variables)) as Promise<
        GraphQLResult<GetProcoreProjectUsersQuery>
      >
    )
      .then((result) => {
        return result.data?.getProcoreProjectUsers as GetProcoreProjectUsersPayload[] | undefined;
      })
      .catch((result: GraphQLResult<GetProcoreProjectUsersQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getWbsCodes = createAsyncThunk(
  'procore/getWBSCodes',
  async (data: GetProcoreWBSCodesInputData, { rejectWithValue }) => {
    const variables: GetProcoreWBSCodesQueryVariables = {
      input: { operationName: 'getWBSCodes', data },
    };

    return (
      API.graphql(graphqlOperation(getProcoreWBSCodes, variables)) as Promise<
        GraphQLResult<GetProcoreWBSCodesQuery>
      >
    )
      .then((result) => {
        return result.data?.getProcoreWBSCodes as GetProcoreWBSCodesPayload[] | undefined;
      })
      .catch((result: GraphQLResult<GetProcoreWBSCodesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createOrUpdateDirectCost = createAsyncThunk(
  'procore/createOrUpdateDirectCost',
  async (data: CreateOrUpdateProcoreDirectCostInputData, { rejectWithValue }) => {
    const variables: CreateOrUpdateProcoreDirectCostMutationVariables = {
      input: { operationName: 'createOrUpdateDirectCost', data },
    };

    return (
      API.graphql(graphqlOperation(createOrUpdateProcoreDirectCost, variables)) as Promise<
        GraphQLResult<CreateOrUpdateProcoreDirectCostMutation>
      >
    )
      .then((result) => {
        return result.data?.createOrUpdateProcoreDirectCost;
      })
      .catch((result: GraphQLResult<CreateOrUpdateProcoreDirectCostMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createReimbursementProcoreDirectCost = createAsyncThunk(
  'procore/createReimbursementDirectCost',
  async (data: CreateReimbursementDirectCostInputData, { rejectWithValue }) => {
    const variables: CreateReimbursementDirectCostMutationVariables = {
      input: { operationName: 'createReimbursementDirectCost', data },
    };

    return (
      API.graphql(graphqlOperation(createReimbursementDirectCost, variables)) as Promise<
        GraphQLResult<CreateReimbursementDirectCostMutation>
      >
    )
      .then((result) => {
        return result.data?.createReimbursementDirectCost;
      })
      .catch((result: GraphQLResult<CreateReimbursementDirectCostMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const attachProcoreDirectCostReceipt = createAsyncThunk(
  'procore/attachDirectCostReceipt',
  async (data: AttachDirectCostReceiptInputData, { rejectWithValue }) => {
    const variables: AttachDirectCostReceiptMutationVariables = {
      input: { operationName: 'attachDirectCostReceipt', data },
    };

    return (
      API.graphql(graphqlOperation(attachDirectCostReceipt, variables)) as Promise<
        GraphQLResult<AttachDirectCostReceiptMutation>
      >
    )
      .then((result) => {
        return result.data?.attachDirectCostReceipt;
      })
      .catch((result: GraphQLResult<AttachDirectCostReceiptMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

const procoreSlice = createSlice({
  name: 'procore',
  initialState,
  reducers: {
    resetProcoreSlice: () => initialState,
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload || [];

      if (state.companies.length === 0) {
        state.selectedCompany = undefined;
      } else if (state.companies.length > 0 && !state.selectedCompany) {
        state.selectedCompany = state.companies[0];
      }
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload || [];
    });

    builder.addCase(getProjectUsers.fulfilled, (state, action) => {
      state.projectUsers = action.payload || [];
    });

    builder.addCase(getWbsCodes.fulfilled, (state, action) => {
      state.projectWbsCodes[action.meta.arg.projectId] = action.payload || [];
    });

    builder.addMatcher(
      isAnyOf(
        getCompanies.pending,
        getProjects.pending,
        getWbsCodes.pending,
        createOrUpdateDirectCost.pending
      ),
      (state) => {
        state.loading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getCompanies.fulfilled,
        getCompanies.rejected,
        getProjects.fulfilled,
        getProjects.rejected,
        getProjectUsers.fulfilled,
        getProjectUsers.rejected,
        getWbsCodes.fulfilled,
        getWbsCodes.rejected,
        createOrUpdateDirectCost.fulfilled,
        createOrUpdateDirectCost.rejected
      ),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { resetProcoreSlice, setSelectedCompany } = procoreSlice.actions;

export const selectProcoreSlice = (state: RootState): ProcoreState => state?.cards?.procore ?? initialState;

export default procoreSlice.reducer;
