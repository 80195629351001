import {
  FinicityConnectUrlType,
  FinicityConsentReceiptPayload,
  HNBankAccountType,
  HNFinicityProduct,
  HNFinicityProductType,
  Paidol,
  PaidolHighnoteIntegration,
} from 'API';
import { useAppDispatch, useAppSelector } from 'app/store';
import { isNotNullOrUndefined } from 'util/typeGuards';
import FinicityIntegration from './FinicityIntegration';
import { addFundingBankAccount, selectFundingSlice } from 'app/pages/store/fundingSlice';

interface FinicityIntegrationPaidolContainerProps {
  integration: PaidolHighnoteIntegration;
  paidol: Paidol;
  hasConnectedFinancialAccount: boolean;
  stylingType?: 'standard' | 'onboarding';
}

const FinicityIntegrationPaidolContainer = ({
  integration,
  paidol,
  hasConnectedFinancialAccount,
  stylingType,
}: FinicityIntegrationPaidolContainerProps) => {
  const dispatch = useAppDispatch();
  const { isAddingFundingBankAccount } = useAppSelector(selectFundingSlice);

  const afterCreateReceipt = async (
    consentReceipt: FinicityConsentReceiptPayload,
    bankAccountName: string,
    bankAccountType: string
  ) => {
    if (
      integration.businessAccountHolderId &&
      consentReceipt.customerId &&
      consentReceipt.receiptId &&
      consentReceipt.products &&
      !isAddingFundingBankAccount
    ) {
      const resolveProductTypeMap = (str: string) => {
        if (str === 'moneyTransferDetails') return HNFinicityProductType.ACH_DETAILS;
        else if (str === 'availableBalanceLive') return HNFinicityProductType.CURRENT_BALANCE;
        else if (str === 'accountOwner') return HNFinicityProductType.ACH_OWNER_DETAILS;
      };

      const products = consentReceipt.products
        .map((product) => {
          if (
            !product?.accountId ||
            !product?.accessPeriod?.startTime ||
            !product?.accessPeriod?.endTime ||
            !product?.product
          ) {
            return null;
          }

          return {
            accountId: product.accountId,
            callLimit: 100,
            productType: resolveProductTypeMap(product.product),
            expirationDetail: {
              start: product.accessPeriod.startTime,
              expiry: product.accessPeriod.endTime,
            },
          } as HNFinicityProduct;
        })
        .filter(isNotNullOrUndefined);

      await dispatch(
        addFundingBankAccount({
          paidolId: paidol.id,
          input: {
            paidolHighnoteIntegrationId: integration.id,
            accountHolderId: integration.businessAccountHolderId,
            bankAccountType:
              bankAccountType === 'checking' ? HNBankAccountType.CHECKING : HNBankAccountType.SAVINGS,
            name: bankAccountName,
            externalToken: {
              customerId: consentReceipt.customerId,
              receiptId:
                process.env.REACT_APP_ENV === 'production'
                  ? consentReceipt.receiptId
                  : 'processor-token-success', // TODO: this logic needs to be validated when in prod
              products,
            },
          },
        })
      ).unwrap();
    }
  };

  return (
    <>
      <FinicityIntegration
        {...(integration.finicityCustomerId ? { finicityCustomerId: integration.finicityCustomerId } : {})}
        {...(integration.dwollaCustomerId ? { dwollaCustomerId: integration.dwollaCustomerId } : {})}
        afterCreateReceipt={afterCreateReceipt}
        hasConnectedFinancialAccount={hasConnectedFinancialAccount}
        type={FinicityConnectUrlType.PAIDOL}
        stylingType={stylingType}
      />
    </>
  );
};

export default FinicityIntegrationPaidolContainer;
