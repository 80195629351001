import { faker } from '@faker-js/faker';
import { BeneficialOwnerInput, HighnoteProduct, HighnoteProductType } from 'API';
import { matchIsValidTel } from 'mui-tel-input';
import dwollaBusinessIndustryClassifications from 'util/dwolla_business_industry_classifications.json';
import * as yup from 'yup';

export const determineEnvironment = (): string => {
  if (
    process.env.REACT_APP_FEATURE_ENVIRONMENT && // Set in Amplify build settings
    process.env.NODE_ENV !== 'test' // CI running on deploys
  ) {
    return process.env.REACT_APP_FEATURE_ENVIRONMENT;
  }

  // Used in local development
  if (['production', 'development', 'test'].includes(process.env.NODE_ENV)) {
    return process.env.NODE_ENV;
  }

  // Always default to prod
  return 'production';
};

export const getIndustryClassificationsOptions = () => {
  return dwollaBusinessIndustryClassifications._embedded['business-classifications']
    .flatMap((businessClassification) =>
      businessClassification._embedded['industry-classifications'].map((industryClassification) => ({
        industryId: industryClassification.id,
        industryName: industryClassification.name,
        businessId: businessClassification.id,
        businessName: businessClassification.name,
      }))
    )
    .sort((a, b) => {
      const businessComparison = a.businessName.localeCompare(b.businessName);
      if (businessComparison !== 0) {
        return businessComparison;
      }
      return a.industryName.localeCompare(b.industryName);
    });
};

export const getInitialOrganizationProfile = () => {
  return {
    businessType: '',
    billingAddress: {
      state: '',
      extendedAddress: '',
    },
    annualRevenue: '',
  };
};

export const getInitialPrimaryAuthorizedPerson = () => {
  return {
    homeAddress: {
      state: '',
      extendedAddress: '',
    },
  };
};

export const getInitialAdministrator = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
  };
};

export const generateAddressSchema = () => {
  return yup.object().shape({
    country: yup.string().required('Country is required'),
    address: yup
      .string()
      .required('Address is required')
      .matches(/^(?:((\S([^pPOo])+)|(?:[0-9]+)))\s(?:[0-9A-Za-z\.]|[^\S\r\n])+$/, 'Invalid address'),
    extendedAddress: yup.string().test('valid-extented-address', 'Invalid extended address', (value) => {
      if (!value) return true;
      return /^[a-zA-Z\d',. \-#]+(([',. \-#][a-zA-Z ])?[a-zA-Z.]*)*$/.test(value);
    }),
    zip: yup
      .string()
      .required('ZIP code is required')
      .matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code'),
    city: yup
      .string()
      .required('City is required')
      .matches(/^[a-zA-Z',.\s-]{1,25}$/, 'Invalid city'),
    state: yup.string().required('State is required'),
  });
};

export const generatePhoneNumberSchema = () => {
  return yup.object().shape({
    countryCode: yup.string().required('Country code is required'),
    number: yup.string().required('Phone number is required'),
    extension: yup.string().nullable(),
  });
};

export const generateOrganizationProfileSchema = () => {
  return yup.object().shape({
    productId: yup.string().required('Select one product'),
    legalBusinessName: yup.string().required('Legal business name is required'),
    doingBusinessAs: yup.string().required('Doing business as name is required'),
    phoneNumber: generatePhoneNumberSchema(),
    phoneNumberValue: generatePhoneNumberValueSchema(),
    hasExtension: yup.boolean().nullable(),
    phoneExtension: yup
      .string()
      .when('hasExtension', {
        is: true,
        then: yup.string().required('You must enter an extension'),
      })
      .nullable(),
    businessType: yup.string().required('Business type is required'),
    annualRevenue: yup.string().when(['productId', '$products'], {
      is: (productId: string, products: HighnoteProduct[]) => {
        const selectedProduct = products.find((product) => product.id === productId);
        return selectedProduct?.type === HighnoteProductType.CREDIT;
      },
      then: yup.string().required('Annual revenue is required'),
    }),
    businessIndustryClassification: yup
      .object()
      .required('Business Industry Classification is required')
      .nullable(),
    isConstructionType: yup.boolean().nullable(),
    billingAddress: generateAddressSchema(),
    identification: yup
      .string()
      .required('Identification is required')
      .test('is-nine-digits', 'Identification must be 9 digits', (value) => {
        return Boolean(value && /^\d{9}$/.test(value.replace('-', '')));
      }),
  });
};

export const generatePrimaryAuthorizedPersonSchema = () => {
  return yup.object().shape({
    firstName: generateFirstNameSchema(),
    lastName: generateLastNameSchema(),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: generatePhoneNumberSchema(),
    phoneNumberValue: generatePhoneNumberValueSchema(),
    hasExtension: yup.boolean().nullable(),
    phoneExtension: yup
      .string()
      .when('hasExtension', {
        is: true,
        then: yup.string().required('You must enter an extension'),
      })
      .nullable(),
    birthdate: yup
      .date()
      .required('Birthdate is required')
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(v as unknown as number) ? v : null)),
    homeAddress: generateAddressSchema(),
    socialSecurityNumber: generateSSNSchema(),
    jobTitle: yup.string().required('Job title is required'),
    percentageOwnership: yup
      .number()
      .notRequired()
      .max(100, 'Percentage ownership cannot exceed 100')
      .typeError("Please enter a valid number, if you don't have a percentage fill in 0."),
    primaryAuthorizedPersonConsent: yup.object().shape({
      financialAccountPermission: yup
        .boolean()
        .required('Financial Account permission is required')
        .oneOf([true], 'Financial Account permission is required'),
      cardholderAgreementPermission: yup
        .boolean()
        .required('Cardholder Agreement permission is required')
        .oneOf([true], 'Cardholder Agreement permission is required'),
      issuingPermission: yup
        .boolean()
        .required('Issuing permission is required')
        .oneOf([true], 'Issuing permission is required'),
      signature: yup.string().required('Primary Authorized Person signature is required'),
    }),
  });
};

export const generateAdministratorSchema = () => {
  return yup.object().shape({
    firstName: generateFirstNameSchema(),
    lastName: generateLastNameSchema(),
    email: yup.string().email('Invalid email format').required('Email is required'),
  });
};

interface TestContext extends yup.TestContext {
  from: {
    value: {
      beneficialOwners: BeneficialOwnerInput[];
    };
  }[];
}

export const generateBeneficialOwnerSchema = () => {
  return yup.object().shape({
    firstName: generateFirstNameSchema(),
    lastName: generateLastNameSchema(),
    email: yup.string().email('Invalid email format').required('Email is required'),
    phoneNumber: yup.object().shape({
      countryCode: yup.string().required('Country code is required'),
      number: yup.string().required('Phone number is required'),
      extension: yup.string().nullable(),
    }),
    phoneNumberValue: generatePhoneNumberValueSchema(),
    hasExtension: yup.boolean().nullable(),
    phoneExtension: yup
      .string()
      .when('hasExtension', {
        is: true,
        then: yup.string().required('You must enter an extension'),
      })
      .nullable(),
    homeAddress: generateAddressSchema(),
    socialSecurityNumber: generateSSNSchema(),
    birthdate: yup
      .date()
      .required('Birthdate is required')
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(v as any) ? v : null)),
    percentageOwnership: yup
      .number()
      .required('Percentage ownership is required')
      .min(25, 'Percentage ownership must be at least 25')
      .max(100, 'Percentage ownership cannot exceed 100')
      .test('sumOfPercentages', 'The sum of percentages cannot exceed 100%', (value, context) => {
        const realContext = context as TestContext;
        const [_, parentSchema] = realContext.from;
        const sumOfPercentages = parentSchema.value.beneficialOwners.reduce(
          (acc, owner) => acc + Number(owner.percentageOwnership ?? 0),
          0
        );
        return sumOfPercentages <= 100;
      })
      .typeError('Please enter a valid number'),
  });
};

function getFakeAddress() {
  return {
    country: 'USA',
    state: faker.location.state({ abbreviated: true }),
    city: faker.location.city(),
    address: faker.location.streetAddress(),
    extendedAddress: '',
    zip: faker.location.zipCode(),
  };
}

function generatePhoneNumberValueSchema() {
  return yup
    .string()
    .required('Phone number is required')
    .test('custom', 'Invalid phone number', (value) => {
      return matchIsValidTel(value || '');
    });
}

function generateSSNSchema() {
  return yup
    .string()
    .required('Social security number is required')
    .test('is-nine-digits', 'Social Security Number must be 9 digits', (value) => {
      return Boolean(value && /^\d{9}$/.test(value.replaceAll('-', '')));
    })
    .matches(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/, 'Invalid Social Security Number');
}

function generateLastNameSchema() {
  return yup
    .string()
    .required('Last name is required')
    .matches(/^[\w',.\s-]{2,255}$/, 'Invalid last name');
}

function generateFirstNameSchema() {
  return yup
    .string()
    .required('First name is required')
    .matches(/^[\w',.\s-]{2,255}$/, 'Invalid first name');
}
