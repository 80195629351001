import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreatePaidolHighnoteIntegrationInput,
  CreatePaidolHighnoteIntegrationMutation,
  CreatePaidolInput,
  CreatePaidolMutation,
  CronStatus,
  HNAccountHolderApplicationStatusCode,
  HnGetBusinessAccountHolderQuery,
  HNUSBusinessAccountHolder,
  ListHighnoteProductsQuery,
  ModelHighnoteProductConnection,
  Paidol,
  PaidolType,
} from 'API';
import { makeAndGetActiveAccount, NewCompanyFormData } from 'app/shared-components/util/PaidolHelper';
import { AppDispatch } from 'app/store';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { createPaidol, createPaidolHighnoteIntegration } from 'graphql/mutations';
import { hnGetBusinessAccountHolder } from './queries';
import shortUUID from 'short-uuid';
import slug from 'slug';
import { createDebouncedThunk } from 'util/thunkHelpers';
import { isPaidol } from 'util/typeGuards';
import { listHighnoteProducts } from 'graphql/queries';

export interface OnboardState {
  paidol?: Paidol;
  hnProfile?: HNUSBusinessAccountHolder;
  isHnProfileValid?: boolean;
  highnoteProducts?: ModelHighnoteProductConnection;
}

export const initialState: OnboardState = {
  paidol: undefined,
  hnProfile: undefined,
  isHnProfileValid: undefined,
  highnoteProducts: undefined,
};

export interface SaveCompanyArgs {
  companyData: NewCompanyFormData;
  userId: string;
  hnProfile?: HNUSBusinessAccountHolder;
}

export const savecompany = createAsyncThunk(
  'onboard/saveCompany',
  async ({ companyData, userId, hnProfile }: SaveCompanyArgs, { dispatch, rejectWithValue }) => {
    const newCompany: CreatePaidolInput = {
      id: shortUUID.uuid(),
      slug: slug(companyData.name),
      legal_name: companyData.legal_name,
      name: companyData.name,
      dba: companyData.dba,
      ein: companyData.ein,
      mcc: companyData.mcc,
      corp_type: companyData.corp_type,
      industry: companyData.industry,
      company_email: companyData.company_email,
      customer_facing_email: companyData.customer_facing_email,
      company_phone: companyData.company_phone,
      website: companyData.website,
      company_address: companyData.company_address,
      legal_address: companyData.legal_address,
      customer_facing_address: companyData.customer_facing_address,
      type: PaidolType.PAIDOL,
      priority_buyer_id: companyData.priority_buyer_id,
      company_id: companyData.company_id,
      bankAccounts: makeAndGetActiveAccount(companyData.bankAccounts),
      achEnabled: companyData.achEnabled,
      cron_status: companyData.useExistingBuyer ? CronStatus.PROCESSED : CronStatus.TO_BE_PROCESSED,
      owner_sub: userId,
      enablePayables: companyData.enablePayables,
      enablePCards: companyData.enablePCards,
    };

    return (
      API.graphql(graphqlOperation(createPaidol, { input: newCompany })) as Promise<
        GraphQLResult<CreatePaidolMutation>
      >
    ).then((res) => {
      if (res.errors) {
        rejectWithValue(res.errors[0]);
      }

      if (hnProfile && res.data?.createPaidol?.id) {
        dispatch(
          saveHighnoteIntegration({
            paidolId: res.data.createPaidol.id,
            userId,
            hnProfile,
            highnoteProductId: companyData.productId || '',
          })
        );
      }

      return res?.data?.createPaidol;
    });
  }
);

export const getHNBusinessAccountHolderDebounced = (id: string) =>
  createDebouncedThunk(
    (dispatch: AppDispatch) => {
      return dispatch(getHNBusinessAccountHolder(id));
    },
    'onboard/getHNBusinessAccountHolderDebounced',
    300
  );

export const getHNBusinessAccountHolder = createAsyncThunk(
  'onboard/getHNBusinessAccountHolder',
  async (id: string) => {
    return (
      API.graphql(graphqlOperation(hnGetBusinessAccountHolder, { id })) as Promise<
        GraphQLResult<HnGetBusinessAccountHolderQuery>
      >
    ).then((results) => {
      return results?.data?.hnGetBusinessAccountHolder as HNUSBusinessAccountHolder;
    });
  }
);

export const getHighnoteProducts = createAsyncThunk('onboard/getHighnoteProducts', async () => {
  return (
    API.graphql(graphqlOperation(listHighnoteProducts)) as Promise<GraphQLResult<ListHighnoteProductsQuery>>
  ).then((results) => {
    return results?.data?.listHighnoteProducts as ModelHighnoteProductConnection;
  });
});

export interface SaveHighnoteIntegrationArgs {
  paidolId: string;
  userId: string;
  hnProfile: HNUSBusinessAccountHolder;
  highnoteProductId: string;
}

export const saveHighnoteIntegration = createAsyncThunk(
  'onboard/saveHighnoteIntegration',
  async ({ paidolId, userId, hnProfile, highnoteProductId }: SaveHighnoteIntegrationArgs) => {
    const financialAccount = hnProfile?.financialAccounts?.edges?.find((e) => e.node?.id);
    const ledger = financialAccount?.node?.ledgers?.find((l) => l.name === 'CASH');
    const externalAccount = hnProfile?.externalFinancialAccounts?.edges?.find(
      (e) => e.node?.accountStatus === 'ACTIVE'
    );
    const application = hnProfile?.cardProductApplications?.edges?.find((e) => e.node?.id);

    const hnIntegration: CreatePaidolHighnoteIntegrationInput = {
      id: paidolId,
      paidolId,
      userId,
      legalBusinessName: hnProfile?.businessProfile?.name?.legalBusinessName,
      businessAccountHolderId: hnProfile?.businessProfile?.id,
      primaryAuthorizedPersonId: hnProfile?.primaryAuthorizedPerson?.id,
      financialAccountId: financialAccount?.node?.id,
      hasInitialFunding: (ledger?.debitBalance?.value ?? 0) > 0,
      externalFinancialBankAccountId: externalAccount?.node?.id,
      accountHolderCardProductApplicationId: application?.node?.id,
      isApplicationAccepted:
        application?.node?.applicationState?.status === HNAccountHolderApplicationStatusCode.APPROVED,
      highnoteProductId,
    };

    return (
      API.graphql(
        graphqlOperation(createPaidolHighnoteIntegration, {
          input: hnIntegration,
        })
      ) as Promise<GraphQLResult<CreatePaidolHighnoteIntegrationMutation>>
    ).then((response) => response.data?.createPaidolHighnoteIntegration);
  }
);

const onboardSlice = createSlice({
  name: 'onboard-old',
  initialState,
  reducers: {
    resetAll: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(savecompany.fulfilled, (state, action) => {
      if (isPaidol(action.payload)) {
        state.paidol = action.payload;
      }
    });

    builder.addCase(getHNBusinessAccountHolder.pending, (state) => {
      state.hnProfile = undefined;
      state.isHnProfileValid = undefined;
    });

    builder.addCase(getHNBusinessAccountHolder.fulfilled, (state, action) => {
      if (action.payload) {
        state.hnProfile = action.payload;
        state.isHnProfileValid = true;
      } else {
        state.isHnProfileValid = false;
      }
    });

    builder.addCase(getHNBusinessAccountHolder.rejected, (state) => {
      state.hnProfile = undefined;
      state.isHnProfileValid = false;
    });

    builder.addCase(getHighnoteProducts.fulfilled, (state, action) => {
      if (action.payload) {
        state.highnoteProducts = action.payload;
      }
    });
  },
});

export const { resetAll } = onboardSlice.actions;

export const selectOnboardSlice = (state: RootState) => state.onboard ?? initialState;

export default onboardSlice.reducer;
