import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { HNPageInfo } from 'API';

export interface LoadMoreButtonProps {
  pageInfo?: HNPageInfo;
  nextToken?: string;
  loadMore: () => void;
  loading: boolean;
}

function LoadMoreButton({ pageInfo, nextToken, loadMore, loading }: LoadMoreButtonProps) {
  const hasNextPage = pageInfo?.hasNextPage || nextToken;
  return (
    <>
      {hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <LoadingButton disabled={loading} loading={loading} onClick={loadMore} size="small">
            Load more
          </LoadingButton>
        </Box>
      )}
    </>
  );
}

export default LoadMoreButton;
