import { yupResolver } from '@hookform/resolvers/yup';
import { DialogContent, DialogContentText, DialogTitle, Icon, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { darken, styled } from '@mui/material/styles';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import { CognitoError, selectAuth, signIn } from 'app/store/authSlice';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const Root = styled('div')(({ theme }) => ({
  '& .Login3-leftSection': {},

  '& .Login3-rightSection': {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { username: rememberedUsername } = useSelector(selectAuth);
  const [isPending, setIsPending] = useState(false);
  const [authError, setAuthError] = useState();
  const [supportAlertOpen, setSupportAlertOpen] = useState(false);
  const { t } = useTranslation();

  /**
   * Form Validation Schema
   */

  const schema = yup.object().shape({
    username: yup.string().required(t('enterUsername')),
    password: yup.string().required(t('enterPassword')).min(8, t('passwordTooShort')),
  });

  const defaultValues = {
    username: '',
    password: '',
    remember: true,
  };

  const { control, setValue, formState, handleSubmit, setError, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const watchedUsername = watch('username');

  useEffect(() => {
    if (rememberedUsername) {
      setValue('username', rememberedUsername, {
        shouldDirty: true,
        shouldValidate: false,
      });
    }
  }, [setValue, rememberedUsername]);

  useEffect(() => {
    if (authError) {
      if (authError.name === CognitoError.PasswordReset) {
        enqueueSnackbar(t('passwordMustBeChanged'));
        navigate(`/forgot-password/${watchedUsername}`);
      }

      let fieldName = authError.name === CognitoError.NotAuthorized ? 'password' : 'username';

      setError(fieldName, {
        type: 'manual',
        message: authError.message,
      });
    }
  }, [authError, enqueueSnackbar, navigate, setError, watchedUsername, t]);

  function onSubmit(model) {
    setIsPending(true);
    dispatch(signIn(model)).then((action) => {
      setIsPending(false);
      if (action.meta.requestStatus === 'rejected') {
        setAuthError(action.payload);
      }
    });
  }

  return (
    <Root className="flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Login3-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0"
          square
        >
          <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
              <div className="flex items-center mb-48">
                <img className="logo-icon w-216" src="assets/images/logos/new_logo.svg" alt="logo" />
              </div>
            </motion.div>

            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ height: '18rem' }}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-16"
                      label={t('username')}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      type="username"
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pl: 1.5 }}>
                            <Icon className="text-20" color="action">
                              person
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-16"
                      label={t('password')}
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      variant="outlined"
                      required
                      fullWidth
                      InputProps={{
                        className: 'pr-2',
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                              <Icon className="text-20" color="action">
                                {showPassword ? 'visibility' : 'visibility_off'}
                              </Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>

              <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                <Controller
                  name="remember"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label={t('rememberMe')}
                        control={<Checkbox {...field} checked={field.value} />}
                      />
                    </FormControl>
                  )}
                />

                <Link className="font-normal" to="/forgot-password">
                  {t('forgotPassword')}
                </Link>
              </div>

              <Button
                variant="contained"
                color="primary"
                className="w-full mx-auto my-16"
                aria-label="LOG IN"
                disabled={isEmpty(dirtyFields) || !isValid || isPending}
                type="submit"
              >
                {isPending ? t('loggingIn') : t('login')}
              </Button>
            </form>

            <div className="flex flex-col my-10 flex items-center justify-center">
              <Link className="font-normal" to="/onboard/invitation">
                {t('haveInviteCode')}
              </Link>
            </div>

            <div className="flex flex-col my-10 flex items-center justify-center">
              <Link className="font-normal" to="/verify-user">
                {t('verifyMyEmail')}
              </Link>
            </div>

            <div className="flex flex-col my-10 flex items-center justify-center">
              <Link
                className="font-normal"
                to="#"
                onClick={(e) => {
                  setSupportAlertOpen(true);
                  e.preventDefault();
                }}
              >
                {t('needHelp')}
              </Link>
            </div>

            <ResponsiveDialog open={supportAlertOpen} onClose={() => setSupportAlertOpen(false)}>
              <DialogTitle>{t('needHelp')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('contactUsAt')}{' '}
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = 'mailto:support@speedchain.com';
                      e.preventDefault();
                    }}
                  >
                    support@speedchain.com
                  </Link>
                </DialogContentText>
              </DialogContent>
              <Button
                className="mr-16 mb-16"
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => setSupportAlertOpen(false)}
              >
                OK
              </Button>
            </ResponsiveDialog>
          </CardContent>
        </Card>

        <div className="Login3-rightSection flex hidden md:flex flex-1 items-center justify-center p-64">
          <div className="max-w-320">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <Typography color="inherit" className="text-32 sm:text-44 font-semibold leading-tight">
                <Trans i18nKey={'welcomeToSpeedChain'} />
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            ></motion.div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
}

export default Login;
