import defaultColors from './defaultColors';

export const fontFamily = 'Poppins, sans-serif';
export const fontWeightRegular = 300;
export const fontWeightSemiBold = 500;
export const fontWeightBold = 600;

const customTypography = {
  fontFamily,
  fontWeightRegular,
  fontWeightBold,
  h1: {
    fontSize: '3.2rem',
    lineHeight: '4.0rem',
    color: defaultColors.textPrimary,
  },
  h2: {
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
    color: defaultColors.textPrimary,
  },
  h3: {
    fontSize: '2.0rem',
    lineHeight: '2.8rem',
    fontWeight: fontWeightBold,
    color: defaultColors.textPrimary,
  },
  h4: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    fontWeight: fontWeightSemiBold,
    color: defaultColors.textPrimary,
  },
  display: {
    fontSize: '2.8rem',
    lineHeight: '3.6rem',
    color: defaultColors.textSecondary,
  },
  large: {
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    letterSpacing: 0.15,
    color: defaultColors.textSecondary,
  },
  medium: {
    fontSize: '1.4rem',
    lineHeight: '2.0rem',
    letterSpacing: 0.25,
    color: defaultColors.textSecondary,
  },
  small: {
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    letterSpacing: 0.4,
    color: defaultColors.textSecondary,
  },
  xsmall: {
    fontSize: '.9rem',
    lineHeight: '1.05rem',
    fontWeight: fontWeightSemiBold,
  },
};

export default customTypography;
