/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdatePaymentFileQueueByPaidol = /* GraphQL */ `subscription OnUpdatePaymentFileQueueByPaidol($paidol_id: ID) {
  onUpdatePaymentFileQueueByPaidol(paidol_id: $paidol_id) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentFileQueueByPaidolSubscriptionVariables,
  APITypes.OnUpdatePaymentFileQueueByPaidolSubscription
>;
export const onUpdateSupplierFileQueueByPaidol = /* GraphQL */ `subscription OnUpdateSupplierFileQueueByPaidol($paidol_id: ID) {
  onUpdateSupplierFileQueueByPaidol(paidol_id: $paidol_id) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSupplierFileQueueByPaidolSubscriptionVariables,
  APITypes.OnUpdateSupplierFileQueueByPaidolSubscription
>;
export const onUpdatePaidolVendorByPaidol = /* GraphQL */ `subscription OnUpdatePaidolVendorByPaidol($paidol_id: ID) {
  onUpdatePaidolVendorByPaidol(paidol_id: $paidol_id) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolVendorByPaidolSubscriptionVariables,
  APITypes.OnUpdatePaidolVendorByPaidolSubscription
>;
export const onAnyPaidolUserByUserId = /* GraphQL */ `subscription OnAnyPaidolUserByUserId($user_id: ID) {
  onAnyPaidolUserByUserId(user_id: $user_id) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAnyPaidolUserByUserIdSubscriptionVariables,
  APITypes.OnAnyPaidolUserByUserIdSubscription
>;
export const onAnyPaidolByOwnerSub = /* GraphQL */ `subscription OnAnyPaidolByOwnerSub($owner_sub: ID!) {
  onAnyPaidolByOwnerSub(owner_sub: $owner_sub) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnAnyPaidolByOwnerSubSubscriptionVariables,
  APITypes.OnAnyPaidolByOwnerSubSubscription
>;
export const onUpdatePaymentStatusDownloadById = /* GraphQL */ `subscription OnUpdatePaymentStatusDownloadById($id: ID!) {
  onUpdatePaymentStatusDownloadById(id: $id) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentStatusDownloadByIdSubscriptionVariables,
  APITypes.OnUpdatePaymentStatusDownloadByIdSubscription
>;
export const onUpdateJobCodeImportByPaidolId = /* GraphQL */ `subscription OnUpdateJobCodeImportByPaidolId($paidolId: ID!) {
  onUpdateJobCodeImportByPaidolId(paidolId: $paidolId) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJobCodeImportByPaidolIdSubscriptionVariables,
  APITypes.OnUpdateJobCodeImportByPaidolIdSubscription
>;
export const onUpdateFlexCodeImportByPaidolId = /* GraphQL */ `subscription OnUpdateFlexCodeImportByPaidolId($paidolId: ID!) {
  onUpdateFlexCodeImportByPaidolId(paidolId: $paidolId) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlexCodeImportByPaidolIdSubscriptionVariables,
  APITypes.OnUpdateFlexCodeImportByPaidolIdSubscription
>;
export const onCreateOnboard = /* GraphQL */ `subscription OnCreateOnboard($filter: ModelSubscriptionOnboardFilterInput) {
  onCreateOnboard(filter: $filter) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOnboardSubscriptionVariables,
  APITypes.OnCreateOnboardSubscription
>;
export const onUpdateOnboard = /* GraphQL */ `subscription OnUpdateOnboard($filter: ModelSubscriptionOnboardFilterInput) {
  onUpdateOnboard(filter: $filter) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOnboardSubscriptionVariables,
  APITypes.OnUpdateOnboardSubscription
>;
export const onDeleteOnboard = /* GraphQL */ `subscription OnDeleteOnboard($filter: ModelSubscriptionOnboardFilterInput) {
  onDeleteOnboard(filter: $filter) {
    id
    status
    invitationCode
    formData {
      informationCertification
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    rebatePercentage
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOnboardSubscriptionVariables,
  APITypes.OnDeleteOnboardSubscription
>;
export const onCreatePaidolTheme = /* GraphQL */ `subscription OnCreatePaidolTheme(
  $filter: ModelSubscriptionPaidolThemeFilterInput
) {
  onCreatePaidolTheme(filter: $filter) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolThemeSubscriptionVariables,
  APITypes.OnCreatePaidolThemeSubscription
>;
export const onUpdatePaidolTheme = /* GraphQL */ `subscription OnUpdatePaidolTheme(
  $filter: ModelSubscriptionPaidolThemeFilterInput
) {
  onUpdatePaidolTheme(filter: $filter) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolThemeSubscriptionVariables,
  APITypes.OnUpdatePaidolThemeSubscription
>;
export const onDeletePaidolTheme = /* GraphQL */ `subscription OnDeletePaidolTheme(
  $filter: ModelSubscriptionPaidolThemeFilterInput
) {
  onDeletePaidolTheme(filter: $filter) {
    id
    paidolId
    palette {
      primary
      primaryDark
      primaryDarker
      primaryLight
      primaryLighter
      secondary
      secondaryDark
      textPrimary
      textSecondary
      whiteColor
      successColor
      warningColor
      errorColor
      errorLightColor
      lightestGrayColor
      lightGrayColor
      lightBlueColor
      info
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolThemeSubscriptionVariables,
  APITypes.OnDeletePaidolThemeSubscription
>;
export const onCreatePaidolUser = /* GraphQL */ `subscription OnCreatePaidolUser(
  $filter: ModelSubscriptionPaidolUserFilterInput
) {
  onCreatePaidolUser(filter: $filter) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolUserSubscriptionVariables,
  APITypes.OnCreatePaidolUserSubscription
>;
export const onUpdatePaidolUser = /* GraphQL */ `subscription OnUpdatePaidolUser(
  $filter: ModelSubscriptionPaidolUserFilterInput
) {
  onUpdatePaidolUser(filter: $filter) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolUserSubscriptionVariables,
  APITypes.OnUpdatePaidolUserSubscription
>;
export const onDeletePaidolUser = /* GraphQL */ `subscription OnDeletePaidolUser(
  $filter: ModelSubscriptionPaidolUserFilterInput
) {
  onDeletePaidolUser(filter: $filter) {
    id
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    position
    phone
    address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    roles
    is_test_fixture
    paymentCards {
      nextToken
      __typename
    }
    authorizedUserId
    authorizedUserApplicationId
    allowedJobCodes
    employee_id
    managedGroups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolUserSubscriptionVariables,
  APITypes.OnDeletePaidolUserSubscription
>;
export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    username
    email
    first_name
    last_name
    paidol_users {
      nextToken
      __typename
    }
    picture
    is_speedster
    birthdate
    address
    phone_number
    ssn
    phone_extension
    password_updated_at
    finicityCustomerId
    dwollaCustomerId
    firstRunBanner {
      insights
      transactions
      reimbursements
      cardsManagement
      spendRules
      funding
      teamMembers
      groups
      wallet
      settings
      connections
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreatePaidol = /* GraphQL */ `subscription OnCreatePaidol(
  $filter: ModelSubscriptionPaidolFilterInput
  $owner: String
) {
  onCreatePaidol(filter: $filter, owner: $owner) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolSubscriptionVariables,
  APITypes.OnCreatePaidolSubscription
>;
export const onUpdatePaidol = /* GraphQL */ `subscription OnUpdatePaidol(
  $filter: ModelSubscriptionPaidolFilterInput
  $owner: String
) {
  onUpdatePaidol(filter: $filter, owner: $owner) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolSubscriptionVariables,
  APITypes.OnUpdatePaidolSubscription
>;
export const onDeletePaidol = /* GraphQL */ `subscription OnDeletePaidol(
  $filter: ModelSubscriptionPaidolFilterInput
  $owner: String
) {
  onDeletePaidol(filter: $filter, owner: $owner) {
    id
    id_base58
    id_base58_short
    owner_paidol_id
    slug
    legal_name
    account_balance
    name
    dba
    ein
    mcc
    corp_type
    industry
    company_email
    customer_facing_email
    company_phone
    website
    company_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    customer_facing_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    legal_address {
      address1
      address2
      city
      state
      country
      postal_code
      __typename
    }
    business_owner_ssn
    dwolla_business_industry_classification
    admin_user_id
    accounts_payable_user_id
    accounts_receivable_user_id
    payment_methods {
      ach
      vcard
      check
      __typename
    }
    low_balance_alert_threshold
    low_balance_auto_funding_amount
    low_balance_auto_funding_threshold
    speedscore
    priority_status
    priority_buyer_id
    deleted_at
    company_id
    logo
    priority_response
    type
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardRequested
    virtualCardEnabled
    achRequested
    cron_status
    owner_sub
    is_test_fixture
    is_migration
    enablePayables
    enablePCards
    reimbursementSettings {
      isEnabled
      isCheckrunEnabled
      rhythm
      day
      weekday
      ordinal
      __typename
    }
    jobCodeSettings {
      isEnabled
      __typename
    }
    flexCodingSettings {
      isEnabled
      __typename
    }
    isConstructionType
    agaveAccountToken
    isAgaveClient
    selectedERP
    primaryAuthorizedPerson {
      firstName
      lastName
      email
      hasExtension
      birthdate
      socialSecurityNumber
      jobTitle
      percentageOwnership
      __typename
    }
    firstRunChecklist {
      connectFinancialAccount
      addAdmin
      addTeamMember
      createSpendRule
      issueCard
      createGroup
      setReimbursement
      integrateERP
      dismissWelcomePage
      isStepperComplete
      __typename
    }
    logoUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolSubscriptionVariables,
  APITypes.OnDeletePaidolSubscription
>;
export const onCreatePaymentFileQueue = /* GraphQL */ `subscription OnCreatePaymentFileQueue(
  $filter: ModelSubscriptionPaymentFileQueueFilterInput
) {
  onCreatePaymentFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentFileQueueSubscriptionVariables,
  APITypes.OnCreatePaymentFileQueueSubscription
>;
export const onUpdatePaymentFileQueue = /* GraphQL */ `subscription OnUpdatePaymentFileQueue(
  $filter: ModelSubscriptionPaymentFileQueueFilterInput
) {
  onUpdatePaymentFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentFileQueueSubscriptionVariables,
  APITypes.OnUpdatePaymentFileQueueSubscription
>;
export const onDeletePaymentFileQueue = /* GraphQL */ `subscription OnDeletePaymentFileQueue(
  $filter: ModelSubscriptionPaymentFileQueueFilterInput
) {
  onDeletePaymentFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_payments
    num_success
    num_failed
    total {
      value
      currency
      __typename
    }
    createdAt
    filename
    supplier_vendor_paidol_ids
    referenced_vendors
    invoice_ids
    invoice_spx_ids
    priority_status
    priority_file_id
    priority_process_on
    priority_response
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentFileQueueSubscriptionVariables,
  APITypes.OnDeletePaymentFileQueueSubscription
>;
export const onCreatePaidolVendor = /* GraphQL */ `subscription OnCreatePaidolVendor(
  $filter: ModelSubscriptionPaidolVendorFilterInput
) {
  onCreatePaidolVendor(filter: $filter) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolVendorSubscriptionVariables,
  APITypes.OnCreatePaidolVendorSubscription
>;
export const onUpdatePaidolVendor = /* GraphQL */ `subscription OnUpdatePaidolVendor(
  $filter: ModelSubscriptionPaidolVendorFilterInput
) {
  onUpdatePaidolVendor(filter: $filter) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolVendorSubscriptionVariables,
  APITypes.OnUpdatePaidolVendorSubscription
>;
export const onDeletePaidolVendor = /* GraphQL */ `subscription OnDeletePaidolVendor(
  $filter: ModelSubscriptionPaidolVendorFilterInput
) {
  onDeletePaidolVendor(filter: $filter) {
    id
    user_vendor_id
    is_migration
    paidol_id
    vendor {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    vendor_paidol_id
    priority_active
    payment_terms
    priority_account_balance
    contact_name
    priority_billing_code
    priority_repeate_code
    priority_group_code
    email_addresses
    priority_document_delivery
    priority_regular_inv
    priority_summary_inv
    foreign_name
    remarks
    box_1099
    form_code_1099
    remark_1
    priority_response
    cron_status
    priority_status
    priority_supplier_id
    virtual_card_email
    bankAccounts {
      bankId
      accountType
      accountNumber
      routingNumber
      defaults
      isActive
      __typename
    }
    achEnabled
    virtualCardEnabled
    checkIssuingEnabled
    checkIssuingPayableTo
    checkIssuingStreet
    checkIssuingCity
    checkIssuingState
    checkIssuingCountry
    checkIssuingPostalCode
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolVendorSubscriptionVariables,
  APITypes.OnDeletePaidolVendorSubscription
>;
export const onCreateSupplierFileQueue = /* GraphQL */ `subscription OnCreateSupplierFileQueue(
  $filter: ModelSubscriptionSupplierFileQueueFilterInput
) {
  onCreateSupplierFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSupplierFileQueueSubscriptionVariables,
  APITypes.OnCreateSupplierFileQueueSubscription
>;
export const onUpdateSupplierFileQueue = /* GraphQL */ `subscription OnUpdateSupplierFileQueue(
  $filter: ModelSubscriptionSupplierFileQueueFilterInput
) {
  onUpdateSupplierFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSupplierFileQueueSubscriptionVariables,
  APITypes.OnUpdateSupplierFileQueueSubscription
>;
export const onDeleteSupplierFileQueue = /* GraphQL */ `subscription OnDeleteSupplierFileQueue(
  $filter: ModelSubscriptionSupplierFileQueueFilterInput
) {
  onDeleteSupplierFileQueue(filter: $filter) {
    id
    user_id
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    paidol_id
    paidol {
      id
      id_base58
      id_base58_short
      owner_paidol_id
      slug
      legal_name
      account_balance
      name
      dba
      ein
      mcc
      corp_type
      industry
      company_email
      customer_facing_email
      company_phone
      website
      business_owner_ssn
      dwolla_business_industry_classification
      admin_user_id
      accounts_payable_user_id
      accounts_receivable_user_id
      low_balance_alert_threshold
      low_balance_auto_funding_amount
      low_balance_auto_funding_threshold
      speedscore
      priority_status
      priority_buyer_id
      deleted_at
      company_id
      logo
      priority_response
      type
      achEnabled
      virtualCardRequested
      virtualCardEnabled
      achRequested
      cron_status
      owner_sub
      is_test_fixture
      is_migration
      enablePayables
      enablePCards
      isConstructionType
      agaveAccountToken
      isAgaveClient
      selectedERP
      logoUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    key
    status
    processed_at
    error_messages
    num_vendors
    num_success
    num_failed
    createdAt
    is_migration
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSupplierFileQueueSubscriptionVariables,
  APITypes.OnDeleteSupplierFileQueueSubscription
>;
export const onCreatePaymentStatusDownload = /* GraphQL */ `subscription OnCreatePaymentStatusDownload(
  $filter: ModelSubscriptionPaymentStatusDownloadFilterInput
  $user_id: String
) {
  onCreatePaymentStatusDownload(filter: $filter, user_id: $user_id) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentStatusDownloadSubscriptionVariables,
  APITypes.OnCreatePaymentStatusDownloadSubscription
>;
export const onUpdatePaymentStatusDownload = /* GraphQL */ `subscription OnUpdatePaymentStatusDownload(
  $filter: ModelSubscriptionPaymentStatusDownloadFilterInput
  $user_id: String
) {
  onUpdatePaymentStatusDownload(filter: $filter, user_id: $user_id) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentStatusDownloadSubscriptionVariables,
  APITypes.OnUpdatePaymentStatusDownloadSubscription
>;
export const onDeletePaymentStatusDownload = /* GraphQL */ `subscription OnDeletePaymentStatusDownload(
  $filter: ModelSubscriptionPaymentStatusDownloadFilterInput
  $user_id: String
) {
  onDeletePaymentStatusDownload(filter: $filter, user_id: $user_id) {
    id
    user_id
    paidol_id
    start_date
    end_date
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentStatusDownloadSubscriptionVariables,
  APITypes.OnDeletePaymentStatusDownloadSubscription
>;
export const onCreatePaidolHighnoteIntegration = /* GraphQL */ `subscription OnCreatePaidolHighnoteIntegration(
  $filter: ModelSubscriptionPaidolHighnoteIntegrationFilterInput
) {
  onCreatePaidolHighnoteIntegration(filter: $filter) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolHighnoteIntegrationSubscriptionVariables,
  APITypes.OnCreatePaidolHighnoteIntegrationSubscription
>;
export const onUpdatePaidolHighnoteIntegration = /* GraphQL */ `subscription OnUpdatePaidolHighnoteIntegration(
  $filter: ModelSubscriptionPaidolHighnoteIntegrationFilterInput
) {
  onUpdatePaidolHighnoteIntegration(filter: $filter) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolHighnoteIntegrationSubscriptionVariables,
  APITypes.OnUpdatePaidolHighnoteIntegrationSubscription
>;
export const onDeletePaidolHighnoteIntegration = /* GraphQL */ `subscription OnDeletePaidolHighnoteIntegration(
  $filter: ModelSubscriptionPaidolHighnoteIntegrationFilterInput
) {
  onDeletePaidolHighnoteIntegration(filter: $filter) {
    id
    paidolId
    userId
    legalBusinessName
    businessAccountHolderId
    primaryAuthorizedPersonId
    financialAccountId
    externalFinancialBankAccountId
    hasInitialFunding
    accountHolderCardProductApplicationId
    isApplicationAccepted
    isDisconnected
    highnoteProductId
    highnoteProduct {
      id
      type
      isActive
      createdAt
      updatedAt
      __typename
    }
    finicityCustomerId
    dwollaCustomerId
    cardProfileId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolHighnoteIntegrationSubscriptionVariables,
  APITypes.OnDeletePaidolHighnoteIntegrationSubscription
>;
export const onCreateHighnoteProduct = /* GraphQL */ `subscription OnCreateHighnoteProduct(
  $filter: ModelSubscriptionHighnoteProductFilterInput
) {
  onCreateHighnoteProduct(filter: $filter) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHighnoteProductSubscriptionVariables,
  APITypes.OnCreateHighnoteProductSubscription
>;
export const onUpdateHighnoteProduct = /* GraphQL */ `subscription OnUpdateHighnoteProduct(
  $filter: ModelSubscriptionHighnoteProductFilterInput
) {
  onUpdateHighnoteProduct(filter: $filter) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHighnoteProductSubscriptionVariables,
  APITypes.OnUpdateHighnoteProductSubscription
>;
export const onDeleteHighnoteProduct = /* GraphQL */ `subscription OnDeleteHighnoteProduct(
  $filter: ModelSubscriptionHighnoteProductFilterInput
) {
  onDeleteHighnoteProduct(filter: $filter) {
    id
    type
    isActive
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHighnoteProductSubscriptionVariables,
  APITypes.OnDeleteHighnoteProductSubscription
>;
export const onCreateHighnotePaymentCard = /* GraphQL */ `subscription OnCreateHighnotePaymentCard(
  $filter: ModelSubscriptionHighnotePaymentCardFilterInput
) {
  onCreateHighnotePaymentCard(filter: $filter) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHighnotePaymentCardSubscriptionVariables,
  APITypes.OnCreateHighnotePaymentCardSubscription
>;
export const onUpdateHighnotePaymentCard = /* GraphQL */ `subscription OnUpdateHighnotePaymentCard(
  $filter: ModelSubscriptionHighnotePaymentCardFilterInput
) {
  onUpdateHighnotePaymentCard(filter: $filter) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHighnotePaymentCardSubscriptionVariables,
  APITypes.OnUpdateHighnotePaymentCardSubscription
>;
export const onDeleteHighnotePaymentCard = /* GraphQL */ `subscription OnDeleteHighnotePaymentCard(
  $filter: ModelSubscriptionHighnotePaymentCardFilterInput
) {
  onDeleteHighnotePaymentCard(filter: $filter) {
    paymentCardId
    paidolId
    authPaidolId
    paidolUsers {
      nextToken
      __typename
    }
    name
    cardGroupId
    group {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    bin
    last4
    network
    formFactor
    expirationDate
    budget {
      __typename
    }
    hasPin
    procoreEmployee {
      id
      name
      email_address
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHighnotePaymentCardSubscriptionVariables,
  APITypes.OnDeleteHighnotePaymentCardSubscription
>;
export const onCreateHighnotePaymentCardSpendRules = /* GraphQL */ `subscription OnCreateHighnotePaymentCardSpendRules(
  $filter: ModelSubscriptionHighnotePaymentCardSpendRulesFilterInput
) {
  onCreateHighnotePaymentCardSpendRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHighnotePaymentCardSpendRulesSubscriptionVariables,
  APITypes.OnCreateHighnotePaymentCardSpendRulesSubscription
>;
export const onUpdateHighnotePaymentCardSpendRules = /* GraphQL */ `subscription OnUpdateHighnotePaymentCardSpendRules(
  $filter: ModelSubscriptionHighnotePaymentCardSpendRulesFilterInput
) {
  onUpdateHighnotePaymentCardSpendRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHighnotePaymentCardSpendRulesSubscriptionVariables,
  APITypes.OnUpdateHighnotePaymentCardSpendRulesSubscription
>;
export const onDeleteHighnotePaymentCardSpendRules = /* GraphQL */ `subscription OnDeleteHighnotePaymentCardSpendRules(
  $filter: ModelSubscriptionHighnotePaymentCardSpendRulesFilterInput
) {
  onDeleteHighnotePaymentCardSpendRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    merchantCategoryAllowed
    merchantCategoryBlocked
    merchantCountryAllowed
    merchantCountryBlocked
    streetAddressAllowed
    streetAddressBlocked
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHighnotePaymentCardSpendRulesSubscriptionVariables,
  APITypes.OnDeleteHighnotePaymentCardSpendRulesSubscription
>;
export const onCreateHighnotePaymentCardVelocityRules = /* GraphQL */ `subscription OnCreateHighnotePaymentCardVelocityRules(
  $filter: ModelSubscriptionHighnotePaymentCardVelocityRulesFilterInput
) {
  onCreateHighnotePaymentCardVelocityRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHighnotePaymentCardVelocityRulesSubscriptionVariables,
  APITypes.OnCreateHighnotePaymentCardVelocityRulesSubscription
>;
export const onUpdateHighnotePaymentCardVelocityRules = /* GraphQL */ `subscription OnUpdateHighnotePaymentCardVelocityRules(
  $filter: ModelSubscriptionHighnotePaymentCardVelocityRulesFilterInput
) {
  onUpdateHighnotePaymentCardVelocityRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHighnotePaymentCardVelocityRulesSubscriptionVariables,
  APITypes.OnUpdateHighnotePaymentCardVelocityRulesSubscription
>;
export const onDeleteHighnotePaymentCardVelocityRules = /* GraphQL */ `subscription OnDeleteHighnotePaymentCardVelocityRules(
  $filter: ModelSubscriptionHighnotePaymentCardVelocityRulesFilterInput
) {
  onDeleteHighnotePaymentCardVelocityRules(filter: $filter) {
    id
    paymentCardId
    name
    maximumAmount {
      value
      currencyCode
      __typename
    }
    cumulativeRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHighnotePaymentCardVelocityRulesSubscriptionVariables,
  APITypes.OnDeleteHighnotePaymentCardVelocityRulesSubscription
>;
export const onCreateBudget = /* GraphQL */ `subscription OnCreateBudget($filter: ModelSubscriptionBudgetFilterInput) {
  onCreateBudget(filter: $filter) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBudgetSubscriptionVariables,
  APITypes.OnCreateBudgetSubscription
>;
export const onUpdateBudget = /* GraphQL */ `subscription OnUpdateBudget($filter: ModelSubscriptionBudgetFilterInput) {
  onUpdateBudget(filter: $filter) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBudgetSubscriptionVariables,
  APITypes.OnUpdateBudgetSubscription
>;
export const onDeleteBudget = /* GraphQL */ `subscription OnDeleteBudget($filter: ModelSubscriptionBudgetFilterInput) {
  onDeleteBudget(filter: $filter) {
    paidolId
    authPaidolId
    budget {
      value
      currency
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBudgetSubscriptionVariables,
  APITypes.OnDeleteBudgetSubscription
>;
export const onCreateMcc = /* GraphQL */ `subscription OnCreateMcc($filter: ModelSubscriptionMccFilterInput) {
  onCreateMcc(filter: $filter) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMccSubscriptionVariables,
  APITypes.OnCreateMccSubscription
>;
export const onUpdateMcc = /* GraphQL */ `subscription OnUpdateMcc($filter: ModelSubscriptionMccFilterInput) {
  onUpdateMcc(filter: $filter) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMccSubscriptionVariables,
  APITypes.OnUpdateMccSubscription
>;
export const onDeleteMcc = /* GraphQL */ `subscription OnDeleteMcc($filter: ModelSubscriptionMccFilterInput) {
  onDeleteMcc(filter: $filter) {
    id
    code
    highnoteDescription
    highnoteCategoryDescription
    speedchainDescription
    expenseTypes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMccSubscriptionVariables,
  APITypes.OnDeleteMccSubscription
>;
export const onCreateExpenseType = /* GraphQL */ `subscription OnCreateExpenseType(
  $filter: ModelSubscriptionExpenseTypeFilterInput
) {
  onCreateExpenseType(filter: $filter) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateExpenseTypeSubscriptionVariables,
  APITypes.OnCreateExpenseTypeSubscription
>;
export const onUpdateExpenseType = /* GraphQL */ `subscription OnUpdateExpenseType(
  $filter: ModelSubscriptionExpenseTypeFilterInput
) {
  onUpdateExpenseType(filter: $filter) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExpenseTypeSubscriptionVariables,
  APITypes.OnUpdateExpenseTypeSubscription
>;
export const onDeleteExpenseType = /* GraphQL */ `subscription OnDeleteExpenseType(
  $filter: ModelSubscriptionExpenseTypeFilterInput
) {
  onDeleteExpenseType(filter: $filter) {
    id
    name
    codes {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteExpenseTypeSubscriptionVariables,
  APITypes.OnDeleteExpenseTypeSubscription
>;
export const onCreateReceiptSummaryField = /* GraphQL */ `subscription OnCreateReceiptSummaryField(
  $filter: ModelSubscriptionReceiptSummaryFieldFilterInput
) {
  onCreateReceiptSummaryField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReceiptSummaryFieldSubscriptionVariables,
  APITypes.OnCreateReceiptSummaryFieldSubscription
>;
export const onUpdateReceiptSummaryField = /* GraphQL */ `subscription OnUpdateReceiptSummaryField(
  $filter: ModelSubscriptionReceiptSummaryFieldFilterInput
) {
  onUpdateReceiptSummaryField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReceiptSummaryFieldSubscriptionVariables,
  APITypes.OnUpdateReceiptSummaryFieldSubscription
>;
export const onDeleteReceiptSummaryField = /* GraphQL */ `subscription OnDeleteReceiptSummaryField(
  $filter: ModelSubscriptionReceiptSummaryFieldFilterInput
) {
  onDeleteReceiptSummaryField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReceiptSummaryFieldSubscriptionVariables,
  APITypes.OnDeleteReceiptSummaryFieldSubscription
>;
export const onCreateReceiptLineItem = /* GraphQL */ `subscription OnCreateReceiptLineItem(
  $filter: ModelSubscriptionReceiptLineItemFilterInput
) {
  onCreateReceiptLineItem(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReceiptLineItemSubscriptionVariables,
  APITypes.OnCreateReceiptLineItemSubscription
>;
export const onUpdateReceiptLineItem = /* GraphQL */ `subscription OnUpdateReceiptLineItem(
  $filter: ModelSubscriptionReceiptLineItemFilterInput
) {
  onUpdateReceiptLineItem(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReceiptLineItemSubscriptionVariables,
  APITypes.OnUpdateReceiptLineItemSubscription
>;
export const onDeleteReceiptLineItem = /* GraphQL */ `subscription OnDeleteReceiptLineItem(
  $filter: ModelSubscriptionReceiptLineItemFilterInput
) {
  onDeleteReceiptLineItem(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptUploadId
    lineItemFields {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReceiptLineItemSubscriptionVariables,
  APITypes.OnDeleteReceiptLineItemSubscription
>;
export const onCreateReceiptLineItemField = /* GraphQL */ `subscription OnCreateReceiptLineItemField(
  $filter: ModelSubscriptionReceiptLineItemFieldFilterInput
) {
  onCreateReceiptLineItemField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReceiptLineItemFieldSubscriptionVariables,
  APITypes.OnCreateReceiptLineItemFieldSubscription
>;
export const onUpdateReceiptLineItemField = /* GraphQL */ `subscription OnUpdateReceiptLineItemField(
  $filter: ModelSubscriptionReceiptLineItemFieldFilterInput
) {
  onUpdateReceiptLineItemField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReceiptLineItemFieldSubscriptionVariables,
  APITypes.OnUpdateReceiptLineItemFieldSubscription
>;
export const onDeleteReceiptLineItemField = /* GraphQL */ `subscription OnDeleteReceiptLineItemField(
  $filter: ModelSubscriptionReceiptLineItemFieldFilterInput
) {
  onDeleteReceiptLineItemField(filter: $filter) {
    id
    paidolId
    authPaidolId
    receiptLineItemId
    type
    typeConfidenceScore
    label
    labelConfidenceScore
    value
    valueConfidenceScore
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReceiptLineItemFieldSubscriptionVariables,
  APITypes.OnDeleteReceiptLineItemFieldSubscription
>;
export const onCreateReceiptUpload = /* GraphQL */ `subscription OnCreateReceiptUpload(
  $filter: ModelSubscriptionReceiptUploadFilterInput
) {
  onCreateReceiptUpload(filter: $filter) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReceiptUploadSubscriptionVariables,
  APITypes.OnCreateReceiptUploadSubscription
>;
export const onUpdateReceiptUpload = /* GraphQL */ `subscription OnUpdateReceiptUpload(
  $filter: ModelSubscriptionReceiptUploadFilterInput
) {
  onUpdateReceiptUpload(filter: $filter) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReceiptUploadSubscriptionVariables,
  APITypes.OnUpdateReceiptUploadSubscription
>;
export const onDeleteReceiptUpload = /* GraphQL */ `subscription OnDeleteReceiptUpload(
  $filter: ModelSubscriptionReceiptUploadFilterInput
) {
  onDeleteReceiptUpload(filter: $filter) {
    id
    paidolId
    authPaidolId
    document
    status
    summaryFields {
      nextToken
      __typename
    }
    lineItems {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReceiptUploadSubscriptionVariables,
  APITypes.OnDeleteReceiptUploadSubscription
>;
export const onCreateTransaction = /* GraphQL */ `subscription OnCreateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onCreateTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionSubscriptionVariables,
  APITypes.OnCreateTransactionSubscription
>;
export const onUpdateTransaction = /* GraphQL */ `subscription OnUpdateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onUpdateTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionSubscriptionVariables,
  APITypes.OnUpdateTransactionSubscription
>;
export const onDeleteTransaction = /* GraphQL */ `subscription OnDeleteTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onDeleteTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    transactionDate
    reimbursementTransactionId
    reimbursementTransaction {
      id
      paidolId
      authPaidolId
      dwollaTransferId
      transactionDate
      status
      checkrunStatus
      note
      contestNote
      deniedNote
      merchantName
      receiptLineItems
      jobCodeId
      reimbursementDate
      paidolUserId
      transactionType
      expenseTypeId
      receiptId
      procoreProjectId
      procoreWBSCodeId
      createdAt
      updatedAt
      __typename
    }
    highnoteTransactionId
    highnoteTransaction {
      transactionId
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      status
      transactionDate
      merchantName
      merchantCategoryCode
      merchantCategory
      transactionEvents
      note
      jobCodeId
      procoreCompanyId
      procoreProjectId
      procoreWBSCodeId
      procoreDirectCostId
      procoreDirectCostLineItemId
      agaveProjectId
      agaveCostCodeId
      agaveCostTypeId
      agaveExpenseId
      agaveVendorId
      receiptPath
      receiptId
      erpApprovalStatus
      last4
      userId
      email
      approvedAmountValue
      approvedAmountCurrencyCode
      flexCodeId
      flexCostCodeId
      flexCostTypeId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionSubscriptionVariables,
  APITypes.OnDeleteTransactionSubscription
>;
export const onCreateReimbursementTransaction = /* GraphQL */ `subscription OnCreateReimbursementTransaction(
  $filter: ModelSubscriptionReimbursementTransactionFilterInput
) {
  onCreateReimbursementTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReimbursementTransactionSubscriptionVariables,
  APITypes.OnCreateReimbursementTransactionSubscription
>;
export const onUpdateReimbursementTransaction = /* GraphQL */ `subscription OnUpdateReimbursementTransaction(
  $filter: ModelSubscriptionReimbursementTransactionFilterInput
) {
  onUpdateReimbursementTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReimbursementTransactionSubscriptionVariables,
  APITypes.OnUpdateReimbursementTransactionSubscription
>;
export const onDeleteReimbursementTransaction = /* GraphQL */ `subscription OnDeleteReimbursementTransaction(
  $filter: ModelSubscriptionReimbursementTransactionFilterInput
) {
  onDeleteReimbursementTransaction(filter: $filter) {
    id
    paidolId
    authPaidolId
    dwollaTransferId
    transactionDate
    amount {
      value
      currencyCode
      __typename
    }
    status
    checkrunStatus
    note
    contestNote
    deniedNote
    merchantName
    receiptLineItems
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    reimbursementDate
    paidolUserId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    transactionType
    expenseTypeId
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReimbursementTransactionSubscriptionVariables,
  APITypes.OnDeleteReimbursementTransactionSubscription
>;
export const onCreateHighnoteTransaction = /* GraphQL */ `subscription OnCreateHighnoteTransaction(
  $filter: ModelSubscriptionHighnoteTransactionFilterInput
) {
  onCreateHighnoteTransaction(filter: $filter) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateHighnoteTransactionSubscriptionVariables,
  APITypes.OnCreateHighnoteTransactionSubscription
>;
export const onUpdateHighnoteTransaction = /* GraphQL */ `subscription OnUpdateHighnoteTransaction(
  $filter: ModelSubscriptionHighnoteTransactionFilterInput
) {
  onUpdateHighnoteTransaction(filter: $filter) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateHighnoteTransactionSubscriptionVariables,
  APITypes.OnUpdateHighnoteTransactionSubscription
>;
export const onDeleteHighnoteTransaction = /* GraphQL */ `subscription OnDeleteHighnoteTransaction(
  $filter: ModelSubscriptionHighnoteTransactionFilterInput
) {
  onDeleteHighnoteTransaction(filter: $filter) {
    transactionId
    paymentCardId
    paymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    paidolId
    authPaidolId
    cardGroupId
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    status
    transactionDate
    approvedAmount {
      value
      currencyCode
      __typename
    }
    merchantName
    merchantCategoryCode
    merchantCategory
    transactionEvents
    note
    jobCodeId
    jobCode {
      id
      paidolId
      authPaidolId
      description
      code
      isActive
      budget
      jobCodeImportId
      createdAt
      updatedAt
      __typename
    }
    procoreCompanyId
    procoreCompany {
      id
      name
      is_active
      logo_url
      __typename
    }
    procoreProjectId
    procoreProject {
      id
      active
      display_name
      name
      created_at
      start_date
      completion_date
      project_number
      total_value
      __typename
    }
    procoreWBSCodeId
    procoreWBSCode {
      id
      flat_code
      flat_name
      description
      status
      __typename
    }
    procoreDirectCostId
    procoreDirectCostLineItemId
    agaveProjectId
    agaveProject {
      id
      source_id
      amount
      completion_date
      name
      number
      start_date
      status
      type
      source_create_time
      source_update_time
      __typename
    }
    agaveCostCodeId
    agaveCostCode {
      id
      source_id
      code
      full_code
      name
      parent_id
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveCostTypeId
    agaveCostType {
      id
      source_id
      code
      name
      project_id
      source_create_time
      source_update_time
      __typename
    }
    agaveExpenseId
    agaveExpense {
      id
      source_id
      amount
      amount_due
      ap_payment_ids
      batch
      batch_id
      creator_id
      currency_code
      description
      discount_amount
      discount_date
      due_date
      issue_date
      misc_amount
      net_amount
      number
      project_id
      retention_amount
      retention_percent
      status
      subtotal_amount
      tax_amount
      total_amount
      type
      vendor_id
      source_create_time
      source_update_time
      __typename
    }
    agaveVendor {
      id
      code
      email
      fax
      name
      phone
      status
      tax_number
      terms
      type
      website
      __typename
    }
    agaveVendorId
    receiptPath
    receiptId
    receipt {
      id
      paidolId
      authPaidolId
      document
      status
      createdAt
      updatedAt
      __typename
    }
    erpApprovalStatus
    last4
    userId
    user {
      id
      username
      email
      first_name
      last_name
      picture
      is_speedster
      birthdate
      address
      phone_number
      ssn
      phone_extension
      password_updated_at
      finicityCustomerId
      dwollaCustomerId
      createdAt
      updatedAt
      __typename
    }
    email
    approvedAmountValue
    approvedAmountCurrencyCode
    flexCodeId
    flexCode {
      id
      paidolId
      authPaidolId
      projectName
      projectCode
      isActive
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostCodeId
    flexCostCode {
      id
      paidolId
      authPaidolId
      code
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    flexCostTypeId
    flexCostType {
      id
      paidolId
      authPaidolId
      name
      flexCodeImportId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteHighnoteTransactionSubscriptionVariables,
  APITypes.OnDeleteHighnoteTransactionSubscription
>;
export const onCreateCardGroup = /* GraphQL */ `subscription OnCreateCardGroup($filter: ModelSubscriptionCardGroupFilterInput) {
  onCreateCardGroup(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCardGroupSubscriptionVariables,
  APITypes.OnCreateCardGroupSubscription
>;
export const onUpdateCardGroup = /* GraphQL */ `subscription OnUpdateCardGroup($filter: ModelSubscriptionCardGroupFilterInput) {
  onUpdateCardGroup(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCardGroupSubscriptionVariables,
  APITypes.OnUpdateCardGroupSubscription
>;
export const onDeleteCardGroup = /* GraphQL */ `subscription OnDeleteCardGroup($filter: ModelSubscriptionCardGroupFilterInput) {
  onDeleteCardGroup(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards {
      nextToken
      __typename
    }
    monthlyBudget {
      value
      currency
      __typename
    }
    isMonthlyBudgetUnlimited
    spendGroupId
    spendGroup {
      id
      paidolId
      authPaidolId
      name
      paymentCards
      merchantCountrySpendRuleId
      merchantCategorySpendRuleId
      amountLimitSpendRuleId
      createdAt
      updatedAt
      __typename
    }
    monthlyBudgetSpend {
      nextToken
      __typename
    }
    transactions {
      nextToken
      __typename
    }
    groupAdmins {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCardGroupSubscriptionVariables,
  APITypes.OnDeleteCardGroupSubscription
>;
export const onCreatePaidolUserToCardGroup = /* GraphQL */ `subscription OnCreatePaidolUserToCardGroup(
  $filter: ModelSubscriptionPaidolUserToCardGroupFilterInput
) {
  onCreatePaidolUserToCardGroup(filter: $filter) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolUserToCardGroupSubscriptionVariables,
  APITypes.OnCreatePaidolUserToCardGroupSubscription
>;
export const onUpdatePaidolUserToCardGroup = /* GraphQL */ `subscription OnUpdatePaidolUserToCardGroup(
  $filter: ModelSubscriptionPaidolUserToCardGroupFilterInput
) {
  onUpdatePaidolUserToCardGroup(filter: $filter) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolUserToCardGroupSubscriptionVariables,
  APITypes.OnUpdatePaidolUserToCardGroupSubscription
>;
export const onDeletePaidolUserToCardGroup = /* GraphQL */ `subscription OnDeletePaidolUserToCardGroup(
  $filter: ModelSubscriptionPaidolUserToCardGroupFilterInput
) {
  onDeletePaidolUserToCardGroup(filter: $filter) {
    id
    paidolUserId
    cardGroupId
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    cardGroup {
      id
      paidolId
      authPaidolId
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolUserToCardGroupSubscriptionVariables,
  APITypes.OnDeletePaidolUserToCardGroupSubscription
>;
export const onCreateMerchantSpendRule = /* GraphQL */ `subscription OnCreateMerchantSpendRule(
  $filter: ModelSubscriptionMerchantSpendRuleFilterInput
) {
  onCreateMerchantSpendRule(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMerchantSpendRuleSubscriptionVariables,
  APITypes.OnCreateMerchantSpendRuleSubscription
>;
export const onUpdateMerchantSpendRule = /* GraphQL */ `subscription OnUpdateMerchantSpendRule(
  $filter: ModelSubscriptionMerchantSpendRuleFilterInput
) {
  onUpdateMerchantSpendRule(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMerchantSpendRuleSubscriptionVariables,
  APITypes.OnUpdateMerchantSpendRuleSubscription
>;
export const onDeleteMerchantSpendRule = /* GraphQL */ `subscription OnDeleteMerchantSpendRule(
  $filter: ModelSubscriptionMerchantSpendRuleFilterInput
) {
  onDeleteMerchantSpendRule(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    paymentCards
    merchantCountrySpendRuleId
    merchantCategorySpendRuleId
    amountLimitSpendRuleId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMerchantSpendRuleSubscriptionVariables,
  APITypes.OnDeleteMerchantSpendRuleSubscription
>;
export const onCreateTransactionsDownloadQueue = /* GraphQL */ `subscription OnCreateTransactionsDownloadQueue(
  $filter: ModelSubscriptionTransactionsDownloadQueueFilterInput
) {
  onCreateTransactionsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionsDownloadQueueSubscriptionVariables,
  APITypes.OnCreateTransactionsDownloadQueueSubscription
>;
export const onUpdateTransactionsDownloadQueue = /* GraphQL */ `subscription OnUpdateTransactionsDownloadQueue(
  $filter: ModelSubscriptionTransactionsDownloadQueueFilterInput
) {
  onUpdateTransactionsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionsDownloadQueueSubscriptionVariables,
  APITypes.OnUpdateTransactionsDownloadQueueSubscription
>;
export const onDeleteTransactionsDownloadQueue = /* GraphQL */ `subscription OnDeleteTransactionsDownloadQueue(
  $filter: ModelSubscriptionTransactionsDownloadQueueFilterInput
) {
  onDeleteTransactionsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    range_option
    start_date
    end_date
    columns
    s3_key
    cron_status
    createdAt
    paidolUserId
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionsDownloadQueueSubscriptionVariables,
  APITypes.OnDeleteTransactionsDownloadQueueSubscription
>;
export const onCreateTransactionsReceiptsDownloadQueue = /* GraphQL */ `subscription OnCreateTransactionsReceiptsDownloadQueue(
  $filter: ModelSubscriptionTransactionsReceiptsDownloadQueueFilterInput
) {
  onCreateTransactionsReceiptsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionsReceiptsDownloadQueueSubscriptionVariables,
  APITypes.OnCreateTransactionsReceiptsDownloadQueueSubscription
>;
export const onUpdateTransactionsReceiptsDownloadQueue = /* GraphQL */ `subscription OnUpdateTransactionsReceiptsDownloadQueue(
  $filter: ModelSubscriptionTransactionsReceiptsDownloadQueueFilterInput
) {
  onUpdateTransactionsReceiptsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionsReceiptsDownloadQueueSubscriptionVariables,
  APITypes.OnUpdateTransactionsReceiptsDownloadQueueSubscription
>;
export const onDeleteTransactionsReceiptsDownloadQueue = /* GraphQL */ `subscription OnDeleteTransactionsReceiptsDownloadQueue(
  $filter: ModelSubscriptionTransactionsReceiptsDownloadQueueFilterInput
) {
  onDeleteTransactionsReceiptsDownloadQueue(filter: $filter) {
    id
    user_id
    paidol_id
    paidolUserId
    s3_key
    cron_status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionsReceiptsDownloadQueueSubscriptionVariables,
  APITypes.OnDeleteTransactionsReceiptsDownloadQueueSubscription
>;
export const onCreateSpendRuleConfig = /* GraphQL */ `subscription OnCreateSpendRuleConfig(
  $filter: ModelSubscriptionSpendRuleConfigFilterInput
) {
  onCreateSpendRuleConfig(filter: $filter) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSpendRuleConfigSubscriptionVariables,
  APITypes.OnCreateSpendRuleConfigSubscription
>;
export const onUpdateSpendRuleConfig = /* GraphQL */ `subscription OnUpdateSpendRuleConfig(
  $filter: ModelSubscriptionSpendRuleConfigFilterInput
) {
  onUpdateSpendRuleConfig(filter: $filter) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSpendRuleConfigSubscriptionVariables,
  APITypes.OnUpdateSpendRuleConfigSubscription
>;
export const onDeleteSpendRuleConfig = /* GraphQL */ `subscription OnDeleteSpendRuleConfig(
  $filter: ModelSubscriptionSpendRuleConfigFilterInput
) {
  onDeleteSpendRuleConfig(filter: $filter) {
    id
    paidolId
    authPaidolId
    ruleId
    ruleType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSpendRuleConfigSubscriptionVariables,
  APITypes.OnDeleteSpendRuleConfigSubscription
>;
export const onCreateJobCodeImport = /* GraphQL */ `subscription OnCreateJobCodeImport(
  $filter: ModelSubscriptionJobCodeImportFilterInput
) {
  onCreateJobCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateJobCodeImportSubscriptionVariables,
  APITypes.OnCreateJobCodeImportSubscription
>;
export const onUpdateJobCodeImport = /* GraphQL */ `subscription OnUpdateJobCodeImport(
  $filter: ModelSubscriptionJobCodeImportFilterInput
) {
  onUpdateJobCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJobCodeImportSubscriptionVariables,
  APITypes.OnUpdateJobCodeImportSubscription
>;
export const onDeleteJobCodeImport = /* GraphQL */ `subscription OnDeleteJobCodeImport(
  $filter: ModelSubscriptionJobCodeImportFilterInput
) {
  onDeleteJobCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    jobCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteJobCodeImportSubscriptionVariables,
  APITypes.OnDeleteJobCodeImportSubscription
>;
export const onCreateJobCode = /* GraphQL */ `subscription OnCreateJobCode($filter: ModelSubscriptionJobCodeFilterInput) {
  onCreateJobCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateJobCodeSubscriptionVariables,
  APITypes.OnCreateJobCodeSubscription
>;
export const onUpdateJobCode = /* GraphQL */ `subscription OnUpdateJobCode($filter: ModelSubscriptionJobCodeFilterInput) {
  onUpdateJobCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateJobCodeSubscriptionVariables,
  APITypes.OnUpdateJobCodeSubscription
>;
export const onDeleteJobCode = /* GraphQL */ `subscription OnDeleteJobCode($filter: ModelSubscriptionJobCodeFilterInput) {
  onDeleteJobCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    description
    code
    isActive
    budget
    jobCodeImportId
    jobCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteJobCodeSubscriptionVariables,
  APITypes.OnDeleteJobCodeSubscription
>;
export const onCreateFlexCodeImport = /* GraphQL */ `subscription OnCreateFlexCodeImport(
  $filter: ModelSubscriptionFlexCodeImportFilterInput
) {
  onCreateFlexCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlexCodeImportSubscriptionVariables,
  APITypes.OnCreateFlexCodeImportSubscription
>;
export const onUpdateFlexCodeImport = /* GraphQL */ `subscription OnUpdateFlexCodeImport(
  $filter: ModelSubscriptionFlexCodeImportFilterInput
) {
  onUpdateFlexCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlexCodeImportSubscriptionVariables,
  APITypes.OnUpdateFlexCodeImportSubscription
>;
export const onDeleteFlexCodeImport = /* GraphQL */ `subscription OnDeleteFlexCodeImport(
  $filter: ModelSubscriptionFlexCodeImportFilterInput
) {
  onDeleteFlexCodeImport(filter: $filter) {
    id
    paidolId
    authPaidolId
    createdAt
    storageFileKey
    fileName
    status
    errors
    flexCodes {
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlexCodeImportSubscriptionVariables,
  APITypes.OnDeleteFlexCodeImportSubscription
>;
export const onCreateFlexCode = /* GraphQL */ `subscription OnCreateFlexCode($filter: ModelSubscriptionFlexCodeFilterInput) {
  onCreateFlexCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlexCodeSubscriptionVariables,
  APITypes.OnCreateFlexCodeSubscription
>;
export const onUpdateFlexCode = /* GraphQL */ `subscription OnUpdateFlexCode($filter: ModelSubscriptionFlexCodeFilterInput) {
  onUpdateFlexCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlexCodeSubscriptionVariables,
  APITypes.OnUpdateFlexCodeSubscription
>;
export const onDeleteFlexCode = /* GraphQL */ `subscription OnDeleteFlexCode($filter: ModelSubscriptionFlexCodeFilterInput) {
  onDeleteFlexCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    projectName
    projectCode
    isActive
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlexCodeSubscriptionVariables,
  APITypes.OnDeleteFlexCodeSubscription
>;
export const onCreateFlexCostCode = /* GraphQL */ `subscription OnCreateFlexCostCode(
  $filter: ModelSubscriptionFlexCostCodeFilterInput
) {
  onCreateFlexCostCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlexCostCodeSubscriptionVariables,
  APITypes.OnCreateFlexCostCodeSubscription
>;
export const onUpdateFlexCostCode = /* GraphQL */ `subscription OnUpdateFlexCostCode(
  $filter: ModelSubscriptionFlexCostCodeFilterInput
) {
  onUpdateFlexCostCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlexCostCodeSubscriptionVariables,
  APITypes.OnUpdateFlexCostCodeSubscription
>;
export const onDeleteFlexCostCode = /* GraphQL */ `subscription OnDeleteFlexCostCode(
  $filter: ModelSubscriptionFlexCostCodeFilterInput
) {
  onDeleteFlexCostCode(filter: $filter) {
    id
    paidolId
    authPaidolId
    code
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlexCostCodeSubscriptionVariables,
  APITypes.OnDeleteFlexCostCodeSubscription
>;
export const onCreateFlexCostType = /* GraphQL */ `subscription OnCreateFlexCostType(
  $filter: ModelSubscriptionFlexCostTypeFilterInput
) {
  onCreateFlexCostType(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFlexCostTypeSubscriptionVariables,
  APITypes.OnCreateFlexCostTypeSubscription
>;
export const onUpdateFlexCostType = /* GraphQL */ `subscription OnUpdateFlexCostType(
  $filter: ModelSubscriptionFlexCostTypeFilterInput
) {
  onUpdateFlexCostType(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFlexCostTypeSubscriptionVariables,
  APITypes.OnUpdateFlexCostTypeSubscription
>;
export const onDeleteFlexCostType = /* GraphQL */ `subscription OnDeleteFlexCostType(
  $filter: ModelSubscriptionFlexCostTypeFilterInput
) {
  onDeleteFlexCostType(filter: $filter) {
    id
    paidolId
    authPaidolId
    name
    flexCodeImportId
    flexCodeImport {
      id
      paidolId
      authPaidolId
      createdAt
      storageFileKey
      fileName
      status
      errors
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFlexCostTypeSubscriptionVariables,
  APITypes.OnDeleteFlexCostTypeSubscription
>;
export const onCreatePaidolUserToHighnotePaymentCard = /* GraphQL */ `subscription OnCreatePaidolUserToHighnotePaymentCard(
  $filter: ModelSubscriptionPaidolUserToHighnotePaymentCardFilterInput
) {
  onCreatePaidolUserToHighnotePaymentCard(filter: $filter) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaidolUserToHighnotePaymentCardSubscriptionVariables,
  APITypes.OnCreatePaidolUserToHighnotePaymentCardSubscription
>;
export const onUpdatePaidolUserToHighnotePaymentCard = /* GraphQL */ `subscription OnUpdatePaidolUserToHighnotePaymentCard(
  $filter: ModelSubscriptionPaidolUserToHighnotePaymentCardFilterInput
) {
  onUpdatePaidolUserToHighnotePaymentCard(filter: $filter) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaidolUserToHighnotePaymentCardSubscriptionVariables,
  APITypes.OnUpdatePaidolUserToHighnotePaymentCardSubscription
>;
export const onDeletePaidolUserToHighnotePaymentCard = /* GraphQL */ `subscription OnDeletePaidolUserToHighnotePaymentCard(
  $filter: ModelSubscriptionPaidolUserToHighnotePaymentCardFilterInput
) {
  onDeletePaidolUserToHighnotePaymentCard(filter: $filter) {
    id
    paidolUserID
    highnotePaymentCardID
    paidolUser {
      id
      paidol_id
      user_id
      email
      position
      phone
      roles
      is_test_fixture
      authorizedUserId
      authorizedUserApplicationId
      allowedJobCodes
      employee_id
      createdAt
      updatedAt
      __typename
    }
    highnotePaymentCard {
      paymentCardId
      paidolId
      authPaidolId
      name
      cardGroupId
      status
      bin
      last4
      network
      formFactor
      expirationDate
      hasPin
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaidolUserToHighnotePaymentCardSubscriptionVariables,
  APITypes.OnDeletePaidolUserToHighnotePaymentCardSubscription
>;
export const onCreateExpenseTypeToMCC = /* GraphQL */ `subscription OnCreateExpenseTypeToMCC(
  $filter: ModelSubscriptionExpenseTypeToMCCFilterInput
) {
  onCreateExpenseTypeToMCC(filter: $filter) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateExpenseTypeToMCCSubscriptionVariables,
  APITypes.OnCreateExpenseTypeToMCCSubscription
>;
export const onUpdateExpenseTypeToMCC = /* GraphQL */ `subscription OnUpdateExpenseTypeToMCC(
  $filter: ModelSubscriptionExpenseTypeToMCCFilterInput
) {
  onUpdateExpenseTypeToMCC(filter: $filter) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateExpenseTypeToMCCSubscriptionVariables,
  APITypes.OnUpdateExpenseTypeToMCCSubscription
>;
export const onDeleteExpenseTypeToMCC = /* GraphQL */ `subscription OnDeleteExpenseTypeToMCC(
  $filter: ModelSubscriptionExpenseTypeToMCCFilterInput
) {
  onDeleteExpenseTypeToMCC(filter: $filter) {
    id
    mccID
    expenseTypeID
    mcc {
      id
      code
      highnoteDescription
      highnoteCategoryDescription
      speedchainDescription
      createdAt
      updatedAt
      __typename
    }
    expenseType {
      id
      name
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteExpenseTypeToMCCSubscriptionVariables,
  APITypes.OnDeleteExpenseTypeToMCCSubscription
>;
