/* eslint-disable */

export const listPaidolsLite = /* GraphQL */ `
  query ListPaidols($filter: ModelPaidolFilterInput, $limit: Int, $nextToken: String) {
    listPaidols(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
    }
  }
`;

export const ListPaidolUsersByUserID = /* GraphQL */ `
  query ListPaidolUsersByUserID($user_id: ID!) {
    paidolUserByUserId(user_id: $user_id) {
      items {
        roles
        paidol {
          id
          name
          id_base58
          id_base58_short
        }
      }
    }
  }
`;

export const PaymentFileQueuesByPaidol = /* GraphQL */ `
  query PaymentFileQueuesByPaidol(
    $paidol_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFileQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentFileQueuesByPaidol(
      paidol_id: $paidol_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        status
        priority_status
        processed_at
        paidol_id
        createdAt
        updatedAt
        error_messages
        num_vendors
        num_payments
        num_success
        num_failed
        total {
          value
          currency
        }
        user {
          username
        }
      }
      nextToken
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListPaidolUsers($filter: ModelPaidolUserFilterInput, $limit: Int, $nextToken: String) {
    listPaidolUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paidol_id
        user_id
        user {
          id
          createdAt
          updatedAt
          first_name
          last_name
          picture
        }
        position
        phone
        email
        address {
          address1
          address2
          city
          state
          country
          postal_code
        }
        roles
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getPaidolVendor = /* GraphQL */ `
  query GetPaidolVendor($id: ID!) {
    getPaidolVendor(id: $id) {
      id
      user_vendor_id
      paidol_id
      is_migration
      vendor {
        id
        slug
        legal_name
        account_balance
        name
        dba
        ein
        mcc
        corp_type
        industry
        company_email
        customer_facing_email
        company_phone
        website
        company_address {
          address1
          address2
          city
          state
          country
          postal_code
        }
        customer_facing_address {
          address1
          address2
          city
          state
          country
          postal_code
        }
        legal_address {
          address1
          address2
          city
          state
          country
          postal_code
        }
        priority_status
        company_id
        priority_response
        createdAt
        updatedAt
      }
      vendor_paidol_id
      payment_terms
      contact_name
      priority_response
      cron_status
      priority_status
      priority_supplier_id
      createdAt
      updatedAt
      bankAccounts {
        bankId
        accountType
        accountNumber
        routingNumber
        defaults
      }
      achEnabled
      virtualCardEnabled
    }
  }
`;

export const SupplierFileQueuesByPaidol = /* GraphQL */ `
  query SupplierFileQueuesByPaidol(
    $paidol_id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSupplierFileQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    supplierFileQueuesByPaidol(
      paidol_id: $paidol_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        key
        status
        processed_at
        paidol_id
        createdAt
        updatedAt
        error_messages
        num_vendors
        num_success
        num_failed
        user {
          username
        }
      }
      nextToken
    }
  }
`;

export const vendorByPaidol = /* GraphQL */ `
  query VendorByPaidol(
    $paidol_id: ID!
    $user_vendor_id: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaidolVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByPaidol(
      paidol_id: $paidol_id
      user_vendor_id: $user_vendor_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_vendor_id
        paidol_id
        vendor {
          id
          id_base58
          id_base58_short
          slug
          legal_name
          account_balance
          name
          dba
          ein
          mcc
          corp_type
          industry
          company_email
          customer_facing_email
          company_phone
          website
          company_address {
            address1
            address2
            city
            state
            country
            postal_code
          }
          admin_user_id
          accounts_payable_user_id
          accounts_receivable_user_id
          low_balance_alert_threshold
          speedscore
          priority_status
          priority_buyer_id
          deleted_at
          company_id
          logo
          priority_response
          type
          achEnabled
          virtualCardRequested
          virtualCardEnabled
          achRequested
          cron_status
          createdAt
          updatedAt
        }
        vendor_paidol_id
        priority_active
        payment_terms
        priority_account_balance
        contact_name
        priority_billing_code
        priority_repeate_code
        priority_group_code
        email_addresses
        priority_document_delivery
        priority_regular_inv
        priority_summary_inv
        foreign_name
        remarks
        box_1099
        form_code_1099
        remark_1
        priority_response
        cron_status
        priority_status
        priority_supplier_id
        virtual_card_email
        bankAccounts {
          bankId
          accountType
          accountNumber
          routingNumber
          defaults
        }
        achEnabled
        virtualCardEnabled
        is_migration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const reimbursementTransactionsByPaidolId = /* GraphQL */ `
  query ListReimbursementTransactionsByPaidolId(
    $paidolId: ID!
    $transactionDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReimbursementTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReimbursementTransactionsByPaidolId(
      paidolId: $paidolId
      transactionDate: $transactionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paidolId
        authPaidolId
        transactionDate
        status
        note
        contestNote
        deniedNote
        merchantName
        receiptLineItems
        amount {
          value
          currencyCode
        }
        jobCodeId
        jobCode {
          id
          description
          code
          isActive
        }
        reimbursementDate
        paidolUserId
        paidolUser {
          id
          email
          user {
            id
            username
            email
            first_name
            last_name
          }
        }
        transactionType
        expenseTypeId
        expenseType {
          id
          name
        }
        receiptId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listOnboardByInvitationCode = /* GraphQL */ `
  query OnboardByInvitationCode(
    $invitationCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onboardByInvitationCode(
      invitationCode: $invitationCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        invitationCode
        createdAt
        updatedAt
        formData {
          organizationProfile {
            productId
            legalBusinessName
            doingBusinessAs
            phoneNumber {
              countryCode
              number
              extension
            }
            phoneNumberValue
            phoneExtension
            hasExtension
            businessType
            annualRevenue
            billingAddress {
              country
              state
              city
              address
              extendedAddress
              zip
            }
            identification
            businessIndustryClassification {
              industryId
              industryName
              businessId
              businessName
            }
            isConstructionType
          }
          primaryAuthorizedPerson {
            firstName
            lastName
            email
            phoneNumber {
              countryCode
              number
              extension
            }
            phoneNumberValue
            phoneExtension
            hasExtension
            birthdate
            homeAddress {
              country
              state
              city
              address
              extendedAddress
              zip
            }
            socialSecurityNumber
            jobTitle
            primaryAuthorizedPersonConsent {
              financialAccountPermission
              cardholderAgreementPermission
              issuingPermission
              signature
            }
            percentageOwnership
          }
          administrator {
            firstName
            lastName
            email
          }
          beneficialOwners {
            firstName
            lastName
            email
            phoneNumber {
              countryCode
              number
              extension
            }
            phoneNumberValue
            phoneExtension
            hasExtension
            homeAddress {
              country
              state
              city
              address
              extendedAddress
              zip
            }
            socialSecurityNumber
            birthdate
            percentageOwnership
          }
          informationCertification
        }
        rebatePercentage
      }
      nextToken
    }
  }
`;
