import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Box, styled, useTheme } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';

const NumberBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.lighter,
  padding: theme.spacing(1),
}));

export interface CurrencyInputBoxProps {
  defaultValue?: string | number;
  onChange: (value: string) => void;
}

export const CurrencyInputBox = ({ defaultValue, onChange }: CurrencyInputBoxProps) => {
  const theme = useTheme();
  return (
    <NumberBox className="flex items-center justify-center">
      <CurrencyInput
        style={{
          background: 'none',
          border: 'none',
          outline: 'none',
          width: 85,
          fontSize: theme.typography.body1.fontSize,
          fontFamily: theme.typography.body1.fontFamily,
          color: theme.palette.text.secondary,
        }}
        prefix="$"
        placeholder="$0.00"
        decimalsLimit={2}
        onChange={(event) => onChange(event.target.value)}
        defaultValue={defaultValue}
      />

      <ModeEditOutlinedIcon color="primary" />
    </NumberBox>
  );
};
