import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import ResponsiveTableHeadCell, { ResponsiveTableHeadCellProps } from './ResponsiveTableHeadCell';

export type ResponsiveTableHeadColumn = Omit<
  ResponsiveTableHeadCellProps,
  'orderBy' | 'sortOrder' | 'onRequestSort'
>;

export interface ResponsiveTableHeadProps {
  columns: Array<ResponsiveTableHeadColumn>;
  orderBy?: string;
  sortOrder?: 'asc' | 'desc';
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

function ResponsiveTableHead({
  columns,
  orderBy,
  sortOrder,
  align = 'left',
  onRequestSort,
}: ResponsiveTableHeadProps): JSX.Element {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <ResponsiveTableHeadCell
            key={column.id}
            {...column}
            orderBy={orderBy}
            sortOrder={sortOrder}
            onRequestSort={onRequestSort}
            align={column.align}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default ResponsiveTableHead;
