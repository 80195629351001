import ActivateCardSheet from 'app/pages/cards/cards/ActivateCardSheet';
import AssignCardSidesheet from 'app/pages/cards/cards/AssignCardSheet/AssignCardSidesheet';
import CardSheet from 'app/pages/cards/cards/CardSheet/CardSheet';
import NewCardSheet from 'app/pages/cards/cards/NewCardSheet/NewCardSheet';
import TransactionAdvancedSearchSidesheet from 'app/pages/overview/tabs/transactions/TransactionAdvancedSearchSidesheet/TransactionAdvancedSearchSidesheet';
import SidesheetContainer from 'app/shared-components/sidesheets/SidesheetContainer';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum SidesheetRoutes {
  AssignCard = 'AssignCard',
  Card = 'Card',
  ActivateCard = 'ActivateCard',
  NewCard = 'NewCard',
  TransactionsAdvancedSearch = 'TransactionAdvancedSeach',
}

const SidesheetContext = createContext({} as any);

export function SidesheetProvider({ children }: { children: ReactNode }) {
  const [sidesheets, setSidesheets] = useState<{
    [key in SidesheetRoutes]: {
      isOpen: boolean;
      params: unknown;
      width?: string;
      customHeader?: boolean;
    };
  }>({
    [SidesheetRoutes.AssignCard]: {
      isOpen: false,
      params: {},
      width: '50vw',
    },
    [SidesheetRoutes.Card]: {
      isOpen: false,
      params: {},
      width: '80vw',
      customHeader: true,
    },
    [SidesheetRoutes.ActivateCard]: {
      isOpen: false,
      params: {},
      width: '90vw',
      customHeader: true,
    },
    [SidesheetRoutes.NewCard]: {
      isOpen: false,
      params: {},
      width: '50vw',
      customHeader: true,
    },
    [SidesheetRoutes.TransactionsAdvancedSearch]: {
      isOpen: false,
      params: {},
      width: '33vw',
    },
  });
  const { t } = useTranslation();
  const context = useMemo(() => {
    return {
      sidesheets,
      openSidesheet(sidesheetId: SidesheetRoutes, params: unknown) {
        if (sidesheets[sidesheetId].isOpen) return;

        setSidesheets((prevSidesheets) => ({
          ...prevSidesheets,
          [sidesheetId]: {
            ...prevSidesheets?.[sidesheetId],
            isOpen: true,
            params,
          },
        }));
      },
      closeSidesheet(sidesheetId: SidesheetRoutes) {
        if (!sidesheets[sidesheetId].isOpen) return;

        setSidesheets((prevSidesheets) => ({
          ...prevSidesheets,
          [sidesheetId]: {
            ...prevSidesheets?.[sidesheetId],
            isOpen: false,
            params: {},
          },
        }));
      },
    };
  }, [sidesheets]);

  return (
    <SidesheetContext.Provider value={context}>
      {children}

      <SidesheetContainer title={t('assignCardToCardholder')} sidesheetId={SidesheetRoutes.AssignCard}>
        <AssignCardSidesheet />
      </SidesheetContainer>

      <SidesheetContainer sidesheetId={SidesheetRoutes.Card}>
        <CardSheet />
      </SidesheetContainer>

      <SidesheetContainer sidesheetId={SidesheetRoutes.ActivateCard}>
        <ActivateCardSheet />
      </SidesheetContainer>

      <SidesheetContainer sidesheetId={SidesheetRoutes.NewCard}>
        <NewCardSheet />
      </SidesheetContainer>

      <SidesheetContainer
        title={t('advancedFiltering')}
        sidesheetId={SidesheetRoutes.TransactionsAdvancedSearch}
      >
        <TransactionAdvancedSearchSidesheet />
      </SidesheetContainer>
    </SidesheetContext.Provider>
  );
}

export function useSidesheet() {
  return useContext(SidesheetContext);
}
