import { useCallback, useEffect, useMemo, useState } from 'react';
import { createDwollaExchange, createDwollaFundingSource } from 'graphql/mutations';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';
import { AddOutlined as AddOutlinedIcon, AddCircle } from '@mui/icons-material';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { t } from 'i18next';
import {
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  FinicityConnect,
} from '@finicity/connect-web-sdk';
import {
  CreateDwollaExchangeMutation,
  CreateDwollaFundingSourceMutation,
  FinicityConsentReceiptPayload,
  FinicityConsentReceiptPayloadInput,
  GetFinicityConnectUrlQuery,
  GetFinicityCustomerAccountsQuery,
  FinicityConnectUrlType,
  CreateDwollaFinicityConsentReceiptMutation,
  CreateHighnoteFinicityConsentReceiptMutation,
} from 'API';
import {
  createDwollaFinicityConsentReceipt,
  createHighnoteFinicityConsentReceipt,
} from 'app/pages/store/mutations';
import {
  getFinicityConnectUrl as getFinicityConnectUrlQuery,
  getFinicityCustomerAccounts,
} from 'graphql/queries';
import LoadingAbsolute from 'app/shared-components/util/LoadingAbsolute';
import { captureException } from '@sentry/react';

const connectOptions: ConnectOptions = {
  popup: true,
  popupOptions: {
    width: 700,
    height: 700,
    top: window!.top!.outerHeight / 2 + window!.top!.screenY - 600 / 2,
    left: window!.top!.outerWidth / 2 + window!.top!.screenX - 600 / 2,
  },
};

const getFinicityConnectUrl = async (finicityCustomerId: string, type: FinicityConnectUrlType) => {
  return API.graphql({
    query: getFinicityConnectUrlQuery,
    variables: {
      input: {
        operationName: 'generateConnectUrl',
        data: { customerId: finicityCustomerId, type },
      },
    },
  }) as Promise<GraphQLResult<GetFinicityConnectUrlQuery>>;
};

const getUserAccounts = async (finicityCustomerId: string) => {
  return API.graphql({
    query: getFinicityCustomerAccounts,
    variables: {
      input: {
        operationName: 'getCustomerAccounts',
        data: { customerId: finicityCustomerId },
      },
    },
  }) as Promise<GraphQLResult<GetFinicityCustomerAccountsQuery>>;
};

const createDwollaConsentReceipt = async (finicityCustomerId: string, finicityAccountId: string) => {
  return (
    API.graphql({
      query: createDwollaFinicityConsentReceipt,
      variables: {
        input: {
          operationName: 'createDwollaConsentReceipt',
          data: {
            customerId: finicityCustomerId,
            accountId: finicityAccountId,
          },
        },
      },
    }) as Promise<GraphQLResult<CreateDwollaFinicityConsentReceiptMutation>>
  ).then((result) => {
    if (
      result.data?.createDwollaFinicityConsentReceipt?.length &&
      result.data?.createDwollaFinicityConsentReceipt[0]?.receipt
    ) {
      return result.data?.createDwollaFinicityConsentReceipt[0].receipt as FinicityConsentReceiptPayload;
    }

    return null;
  });
};

const createHighnoteConsentReceipt = async (finicityCustomerId: string, finicityAccountId: string) => {
  return (
    API.graphql({
      query: createHighnoteFinicityConsentReceipt,
      variables: {
        input: {
          operationName: 'createHighnoteConsentReceipt',
          data: {
            customerId: finicityCustomerId,
            accountId: finicityAccountId,
          },
        },
      },
    }) as Promise<GraphQLResult<CreateHighnoteFinicityConsentReceiptMutation>>
  ).then((result) => {
    if (
      result.data?.createHighnoteFinicityConsentReceipt?.length &&
      result.data?.createHighnoteFinicityConsentReceipt[0]?.receipt
    ) {
      return result.data?.createHighnoteFinicityConsentReceipt[0].receipt as FinicityConsentReceiptPayload;
    }

    return null;
  });
};

const createExchange = async (
  dwollaCustomerId: string,
  finicityReceipt: FinicityConsentReceiptPayloadInput
) => {
  return (
    API.graphql({
      query: createDwollaExchange,
      variables: {
        input: {
          operationName: 'createExchange',
          data: {
            customerId: dwollaCustomerId,
            finicityReceipt,
          },
        },
      },
    }) as Promise<GraphQLResult<CreateDwollaExchangeMutation>>
  ).then((result) => result.data?.createDwollaExchange);
};

const createFundingSource = async (
  dwollaCustomerId: string,
  dwollaExchangeUrl: string,
  name: string,
  type: string
) => {
  return (
    API.graphql({
      query: createDwollaFundingSource,
      variables: {
        input: {
          operationName: 'createFundingSource',
          data: {
            customerId: dwollaCustomerId,
            exchangeUrl: dwollaExchangeUrl,
            name,
            type,
          },
        },
      },
    }) as Promise<GraphQLResult<CreateDwollaFundingSourceMutation>>
  ).then((result) => result.data?.createDwollaFundingSource);
};

interface FinicityIntegrationProps {
  finicityCustomerId?: string;
  dwollaCustomerId?: string;
  afterCreateReceipt?: (
    consentReceipt: FinicityConsentReceiptPayload,
    bankAccountName: string,
    bankAccountType: string
  ) => Promise<void>;
  hasConnectedFinancialAccount: boolean;
  type: FinicityConnectUrlType;
  stylingType?: 'standard' | 'onboarding';
}

const FinicityIntegration = ({
  finicityCustomerId,
  dwollaCustomerId,
  afterCreateReceipt,
  hasConnectedFinancialAccount,
  type,
  stylingType = 'standard',
}: FinicityIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [finicityConnectUrl, setFinicityConnectUrl] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen = useCallback(() => {
    if (finicityCustomerId && dwollaCustomerId) return;
    setOpen(true);
  }, [dwollaCustomerId, finicityCustomerId]);

  const loadComponent = useCallback(
    async (finicityCustomerId: string) => {
      try {
        const { data } = await getFinicityConnectUrl(finicityCustomerId, type);
        if (data?.getFinicityConnectUrl?.link) setFinicityConnectUrl(data?.getFinicityConnectUrl.link);
      } catch (err) {
        captureException(err);
        enqueueSnackbar(t('finicityIntegrationSnackbar.error'), {
          variant: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar, type]
  );

  useEffect(() => {
    if (finicityCustomerId) {
      loadComponent(finicityCustomerId);
    } else {
      setIsLoading(false);
    }
  }, [finicityCustomerId, loadComponent]);

  const connectEventHandlers: ConnectEventHandlers = useMemo(
    () => ({
      onDone: async (event: ConnectDoneEvent) => {
        try {
          if (!finicityCustomerId || !dwollaCustomerId) return;
          setIsLoading(true);
          const { data } = await getUserAccounts(finicityCustomerId);
          if (!data?.getFinicityCustomerAccounts?.length) return;
          const userBankAccount = data.getFinicityCustomerAccounts[0];

          if (!userBankAccount.id || !userBankAccount.name || !userBankAccount.type) {
            console.log({ getUserAccountsFinicityError: data });
            throw new Error('Error while fetch Finicity customer data!');
          }

          // Create dwolla funding source

          enqueueSnackbar(t('finicityIntegrationSnackbar.loading'), {
            variant: 'info',
          });

          try {
            const dwollaConsentReceipt = await createDwollaConsentReceipt(
              finicityCustomerId,
              userBankAccount.id
            );
            if (!dwollaConsentReceipt) throw new Error('Error while create Dwolla consent receipt!');

            const exchangeHref = await createExchange(dwollaCustomerId, dwollaConsentReceipt);
            if (!exchangeHref) throw new Error('Error while create Dwolla exchange href!');

            await createFundingSource(
              dwollaCustomerId,
              exchangeHref,
              userBankAccount.name,
              userBankAccount.type
            );
          } catch (err) {
            captureException(err);
            console.error(err);
          }

          if (type === FinicityConnectUrlType.PAIDOL) {
            const highnoteConsentReceipt = await createHighnoteConsentReceipt(
              finicityCustomerId,
              userBankAccount.id
            );
            if (!highnoteConsentReceipt) throw new Error('Error while create Highnote consent receipt!');

            if (!afterCreateReceipt) throw new Error('afterCreateReceipt not defined!');
            await afterCreateReceipt(highnoteConsentReceipt, userBankAccount.name, userBankAccount.type);
          }
        } catch (err) {
          captureException(err);
          enqueueSnackbar('An error occurred while setting up your account. Please try again.', {
            variant: 'error',
          });
          return;
        } finally {
          setIsLoading(false);
        }

        enqueueSnackbar(t('finicityIntegrationSnackbar.success'), {
          variant: 'success',
        });

        console.log('onDone::', event);
      },
      onCancel: (event: ConnectCancelEvent) => {
        console.log('onCancel::', event);
        setIsLoading(false);
      },
      onError: (event: ConnectErrorEvent) => {
        console.log('onError::', event);
        setIsLoading(false);
      },
    }),
    [afterCreateReceipt, dwollaCustomerId, finicityCustomerId, type]
  );

  const launchFinicityConnectPopup = useCallback(() => {
    FinicityConnect.launch(finicityConnectUrl, connectEventHandlers, connectOptions);
  }, [connectEventHandlers, finicityConnectUrl]);

  if (isLoading) {
    <Box display="flex" flexDirection="column" alignItems="end" gap="5px">
      <Skeleton variant="rectangular" width={200} height={30} />
    </Box>;
  }

  return (
    <>
      {stylingType === 'standard' ? (
        <Tooltip
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          title={t('settingUpYourAccount')}
          arrow
        >
          <span>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AddOutlinedIcon />}
              onClick={launchFinicityConnectPopup}
              disabled={isLoading || hasConnectedFinancialAccount || !finicityCustomerId || !dwollaCustomerId}
              sx={{ ml: 'auto' }}
            >
              {t('addAccount')}
            </Button>
          </span>
        </Tooltip>
      ) : (
        /* Temporary solution. Long term solution would be to have this functionality be a hook that can be used for any button */
        <>
          {isLoading && <LoadingAbsolute />}
          <Button
            onClick={launchFinicityConnectPopup}
            disabled={isLoading || hasConnectedFinancialAccount || !finicityCustomerId || !dwollaCustomerId}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '200px',
              width: '200px',
              backgroundColor: '#D4E2FF',
            }}
          >
            <AddCircle sx={{ width: '48px', height: '48px' }} />
            <Typography variant="h6" sx={{ fontSize: '16px', pt: 1 }}>
              {t('linkFinancialAccount')}
            </Typography>
          </Button>
        </>
      )}
    </>
  );
};

export default FinicityIntegration;
