import useIsMobile from 'app/hooks/useIsMobile';
import { ReactNode } from 'react';

export interface BrowserViewProps {
  children: ReactNode;
}

function BrowserView({ children }: BrowserViewProps): JSX.Element {
  const isMobile = useIsMobile();

  return <>{!isMobile && children}</>;
}

export default BrowserView;
