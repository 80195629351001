import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  InputAdornment,
  InputBase,
  MenuItem,
  Popper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, Close as CloseIcon } from '@mui/icons-material';
import {
  ColumnFilter,
  selectSearchAndFilter,
  setColumnFilters,
  applyColumnFilters,
} from 'app/store/searchAndFilterSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import theme from 'app/theme';

export const TextInput = (
  params: AutocompleteRenderInputParams,
  length: number,
  placeholder: string,
  onClickCloseButton: () => void,
  inputRef: React.Ref<HTMLInputElement>,
  onFocus: () => void,
  onClickLinkButton: () => void
) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <TextField
      {...params}
      sx={{
        input: {
          '&::placeholder': {
            color: length ? '#0B7EB5' : 'inherit',
            opacity: length ? 1 : 'inherit',
            fontWeight: 400,
          },
        },
      }}
      placeholder={placeholder}
      variant="standard"
      inputRef={inputRef}
      InputProps={{
        ...params.InputProps,
        startAdornment: length ? (
          <InputAdornment onClick={onClickCloseButton} sx={{ cursor: 'pointer' }} position="start">
            <CloseIcon fontSize="small" />
          </InputAdornment>
        ) : null,
        endAdornment: length ? (
          <InputAdornment onClick={onClickLinkButton} position="end" sx={{ fontWeight: 'bold' }}>
            <Chip color="info" label={length} size="small" />
          </InputAdornment>
        ) : null,
        disableUnderline: true,
        onBlur: handleBlur,
        onFocus: () => {
          handleFocus();
          setIsFocused(true);
        },
        style: {
          width: '100%',
          backgroundColor: '#FFFFFF',
          padding: '8px 12px',
          borderRadius: '8px',
          paddingLeft: length ? '12px' : '40px',
          border: isFocused ? '1px solid #0B7EB5' : 'none',
        },
      }}
    />
  );
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: `0 8px 24px rgba(0, 0, 0, 0.15)`,
  borderRadius: 8,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
  '& ul': {
    padding: 0,
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    // border: '1px solid red',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const SelectOptionComponent = ({
  props,
  option,
  state,
  onFilterRuleChange,
  filterKey,
  onClose,
  filterRule,
}: any) => {
  // returns the select component in the first iteration
  return props['data-option-index'] === 0 ? (
    <>
      <li key={`select-${option.id}`}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          input={<BootstrapInput />}
          sx={{ borderRadius: 0, '& :focus': { border: 'none', boxShadow: 'none !important' } }}
          fullWidth
          onChange={(e) => onFilterRuleChange(e, filterKey)}
          defaultValue="noExcept"
          onClose={onClose}
          value={filterRule}
        >
          <MenuItem value={'noExcept'}>
            <Typography>
              <b>No</b> [header] except
            </Typography>
          </MenuItem>
          <MenuItem value={'allExcept'}>
            <Typography>
              <b>All</b> [header] except
            </Typography>
          </MenuItem>
        </Select>
      </li>
      <li
        key={option.id}
        {...props}
        style={{
          backgroundColor: state.selected ? theme.palette.primary.main : '',
          color: state.selected ? '#FFF' : '',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            paddingY: 0.8,
          }}
        >
          {option.label}
        </Box>
        <Box
          component={CheckCircleIcon}
          sx={{ width: 18, height: 18 }}
          style={{
            visibility: state.selected ? 'visible' : 'hidden',
          }}
        />
      </li>
    </>
  ) : (
    <li
      key={option.id}
      {...props}
      style={{
        backgroundColor: state.selected ? theme.palette.primary.main : '',
        color: state.selected ? '#FFF' : '',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          paddingY: 0.8,
        }}
      >
        {option.label}
      </Box>
      <Box
        component={CheckCircleIcon}
        sx={{ width: 18, height: 18 }}
        style={{
          visibility: state.selected ? 'visible' : 'hidden',
        }}
      />
    </li>
  );
};

export interface ExistingValue {
  id: string;
  label: string;
}

interface DropdownFilterChipProps {
  filter: ColumnFilter;
}

export const DropdownFilterChip = ({ filter }: DropdownFilterChipProps) => {
  const dispatch = useAppDispatch();
  const tableFilters = useAppSelector(selectSearchAndFilter)[filter.page];

  const [open, setOpen] = useState(false);
  const [pendingValues, setPendingValues] = useState<ExistingValue[]>([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFilterRuleChange = (e: SelectChangeEvent, key: string) => {
    const changedFilterTable = { ...filter };
    changedFilterTable.rule = e.target.value;
    dispatch(
      setColumnFilters({
        pageName: filter.page,
        columnFilters: {
          [filter.key]: changedFilterTable,
        },
      })
    );
    focusAutocompleteTextField();
  };

  const handleSelectedValuesChange = (values: ExistingValue[], key: string) => {
    const changedFilterTable = { ...filter };
    changedFilterTable.selectedValues = values;
    dispatch(
      applyColumnFilters({
        pageName: filter.page,
        columnFilters: {
          ...tableFilters.columnFilters,
          [filter.key]: changedFilterTable,
        },
      })
    );
  };

  const handleClose = () => {
    handleSelectedValuesChange(pendingValues, filter.key);
    setOpen(false);
  };

  const handleClearSelectedValues = () => {
    handleSelectedValuesChange([], filter.key);
    setPendingValues([]);
    setOpen(false);
  };

  const focusAutocompleteTextField = () => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 1);
  };

  const handleCloseSelect = () => {
    focusAutocompleteTextField();
  };

  const handleClickLinkButton = () => {
    setOpen(true);
  };

  const handleFocusTextField = () => {
    setOpen(true);
  };

  useEffect(() => {
    const selectedValues = tableFilters.columnFilters[filter.key].selectedValues;
    if (selectedValues && selectedValues.length > 0) {
      setPendingValues(selectedValues);
    } else {
      setPendingValues([]);
    }
  }, [tableFilters, filter]);

  return (
    <>
      <Autocomplete
        multiple
        readOnly
        onOpen={() => setOpen(true)}
        onClose={(e: any) => {
          if (e?.relatedTarget?.id === 'demo-simple-select') return;
          else handleClose();
        }}
        value={pendingValues}
        onChange={(event, newValues, reason) => setPendingValues(newValues)}
        getOptionLabel={(option) => option.label}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        id="job-codes-dropdown"
        renderOption={(props, option, state) => (
          <SelectOptionComponent
            key={`select-option-${option.id}`}
            filterKey={filter.key}
            props={props}
            option={option}
            state={state}
            onFilterRuleChange={handleFilterRuleChange}
            onClose={handleCloseSelect}
            filterRule={filter.rule}
          />
        )}
        disableCloseOnSelect
        options={filter.options || []}
        sx={{ minWidth: '255px' }}
        PopperComponent={StyledPopper}
        clearOnBlur
        openOnFocus
        renderInput={(params) =>
          TextInput(
            params,
            pendingValues.length,
            filter.name,
            handleClearSelectedValues,
            inputRef,
            handleFocusTextField,
            handleClickLinkButton
          )
        }
      />
    </>
  );
};
