import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useNavigationMenu } from 'app/hooks/useNavigationMenu';
import { getIntegration, selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { NavigationTabsItem } from 'app/shared-components/layout/NavigationTabs';
import BrowserView from 'app/shared-components/util/BrowserView';
import MobileView from 'app/shared-components/util/MobileView';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import useDeepCompareEffect from 'use-deep-compare-effect';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import { getFinancialAccountDetails } from 'app/pages/store/fundingSlice';

export interface NavigationItem {
  id: string;
  label: string;
  href: string;
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  tabs?: Array<NavigationTabsItem>;
  hasAccess?: boolean;
}

function NavBar(): JSX.Element {
  const dispatch = useAppDispatch();
  const { navigationElements } = useNavigationMenu();
  const { integration } = useAppSelector(selectReviewOnboardSlice);

  const { selectedPaidol } = useAppSelector(selectUserCompanies);

  useDeepCompareEffect(() => {
    if (selectedPaidol) {
      dispatch(getIntegration(selectedPaidol.id));
    }
  }, [dispatch, selectedPaidol]);

  useDeepCompareEffect(() => {
    if (integration) {
      dispatch(getFinancialAccountDetails(integration));
    }
  }, [dispatch, integration]);

  return (
    <>
      <BrowserView>
        <DesktopNavBar navigationElements={navigationElements} />
      </BrowserView>
      <MobileView>
        <MobileNavBar navigationElements={navigationElements} />
      </MobileView>
    </>
  );
}

export default NavBar;
