import { Backdrop, CircularProgress, Table, TableBody } from '@mui/material';
import { Box } from '@mui/system';
import ResponsiveTableHead, { ResponsiveTableHeadColumn } from './ResponsiveTableHead';

interface ResponsiveTableProps {
  tableHeadColumns?: Array<ResponsiveTableHeadColumn>;
  TableHead?: React.ReactNode;
  loadingSpinner?: boolean;
  isLoading?: boolean;
  orderBy?: string;
  sortOrder?: 'asc' | 'desc';
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  children?: React.ReactNode;
}

function ResponsiveTable({
  tableHeadColumns,
  TableHead,
  loadingSpinner = false,
  isLoading = false,
  orderBy,
  sortOrder,
  onRequestSort,
  children,
}: ResponsiveTableProps): JSX.Element {
  return (
    <>
      <Box sx={{ overflowX: 'auto' }}>
        <Table>
          {tableHeadColumns && (
            <ResponsiveTableHead
              columns={tableHeadColumns}
              orderBy={orderBy}
              sortOrder={sortOrder}
              onRequestSort={onRequestSort}
            />
          )}
          {!tableHeadColumns && TableHead}
          <TableBody>{children}</TableBody>
        </Table>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingSpinner}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );
}

export default ResponsiveTable;
