import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export const handleHNErrors = (
  error: any,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey,
  message: string
) => {
  if (error.errors) {
    const errors = error.errors || [];
    const parsedErrors = errors.map((err: any) => err.description).join('\n');

    enqueueSnackbar(`${message}\n${parsedErrors}`, {
      variant: 'error',
      style: { whiteSpace: 'pre-line' },
    });

    return true;
  }

  return false;
};
