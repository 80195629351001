import { ActionReducerMapBuilder, CaseReducer } from '@reduxjs/toolkit';
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders';

export const withLoading = <
  T extends {
    pending: TypedActionCreator<any>;
    rejected: TypedActionCreator<any>;
    fulfilled: TypedActionCreator<any>;
  },
  U extends ActionReducerMapBuilder<any>,
  V extends CaseReducer
>(
  thunk: T,
  builder: U,
  hooks?: {
    afterFulfilled?: V;
    afterRejected?: V;
    afterPending?: V;
  }
) => {
  builder
    .addCase(thunk.pending, (state, action) => {
      state.loading = true;
      hooks?.afterPending?.(state, action);
    })
    .addCase(thunk.fulfilled, (state, action) => {
      hooks?.afterFulfilled?.(state, action);
      state.loading = false;
    })
    .addCase(thunk.rejected, (state, action) => {
      hooks?.afterRejected?.(state, action);
      state.loading = false;
    });
};
