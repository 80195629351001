import { CssBaseline, ThemeProvider } from '@mui/material';
import Error500Page from 'app/pages/misc/Error500Page';
import RoutingTable from 'app/RoutingTable';
import AuthProvider from 'app/shared-components/auth/AuthProvider';
import SharedDialog from 'app/shared-components/layout/SharedDialog';
import theme from 'app/theme';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import FeatureFlagProvider from './shared-components/feature-flags/FeatureFlagProvider';
import EnvironmentStripe from './shared-components/layout/environment-stripe/EnvironmentStripe';
import { SidesheetProvider } from './shared-components/sidesheets/SidesheetProvider';
import withAppProviders from './withAppProviders';
import { ErrorBoundary } from '@sentry/react';

const App = () => {
  return (
    <AuthProvider>
      {/* <SpeedchatProvider> */}
      <FeatureFlagProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <SidesheetProvider>
              <BrowserRouter>
                <EnvironmentStripe>
                  <ErrorBoundary fallback={Error500Page} showDialog>
                    <RoutingTable />
                    <SharedDialog />
                  </ErrorBoundary>
                </EnvironmentStripe>
              </BrowserRouter>
            </SidesheetProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </FeatureFlagProvider>
      {/* </SpeedchatProvider> */}
    </AuthProvider>
  );
};

export default withAppProviders(App)();
