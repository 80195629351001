import { Box } from '@mui/system';
import AppHeader from 'app/shared-components/layout/AppHeader';
import Loading from 'app/shared-components/util/Loading';
import { Suspense } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import NavBar from './navbar/NavBar';
import { useFetchProcoreCommonData } from 'app/hooks/useFetchProcoreCommonData';
import { useFetchAgaveCommonData } from 'app/hooks/useFetchAgaveCommonData';

function Layout(): JSX.Element {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');

  const location = useLocation();
  const isReviewPage = location.pathname.match(/^\/review/);

  useFetchProcoreCommonData(codeParam);
  useFetchAgaveCommonData();

  return (
    <>
      <AppHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {!isReviewPage && <NavBar />}
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

export default Layout;
