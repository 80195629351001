import type { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  ListHighnoteProductsQuery,
  ModelHighnoteProductConnection,
  Onboard,
  OnboardByInvitationCodeQuery,
  OnboardByInvitationCodeQueryVariables,
  RegisterOnboardInput,
  RegisterOnboardMutation,
  UpdateOnboardInput,
  UpdateOnboardMutation,
} from 'API';
import { API } from 'aws-amplify';
import { registerOnboard } from 'graphql/mutations';
import { listHighnoteProducts } from 'graphql/queries';
import { updateOnboard } from 'graphql_custom/mutations';
import { listOnboardByInvitationCode } from 'graphql_custom/queries';
import serializeError, { ErrorLike } from 'util/serializeError';

export interface OnboardState {
  onboard: Onboard | null | undefined;
  highnoteProducts?: ModelHighnoteProductConnection;
}

const initialState: OnboardState = {
  onboard: null,
  highnoteProducts: undefined,
};

export const getOnboardByInvitationCode = createAsyncThunk<
  Onboard | null | undefined,
  string,
  { rejectValue: ErrorLike }
>('onboard/getOnboardByInvitationCode', async (invitationCode: string, { rejectWithValue, dispatch }) => {
  const variables: OnboardByInvitationCodeQueryVariables = { invitationCode };
  const query = API.graphql({
    query: listOnboardByInvitationCode,
    variables,
    authMode: 'AWS_IAM',
  }) as Promise<GraphQLResult<OnboardByInvitationCodeQuery>>;
  return query
    .then((response) => {
      return response.data?.onboardByInvitationCode?.items[0];
    })
    .catch((error) => rejectWithValue(serializeError(error)));
});

export const getHighnoteProducts = createAsyncThunk('onboard/getHighnoteProducts', async () => {
  return (
    API.graphql({ query: listHighnoteProducts, authMode: 'AWS_IAM' }) as Promise<
      GraphQLResult<ListHighnoteProductsQuery>
    >
  ).then((results) => {
    return results?.data?.listHighnoteProducts as ModelHighnoteProductConnection;
  });
});

export const updateOnboardData = createAsyncThunk<
  Onboard | null | undefined,
  UpdateOnboardInput,
  { rejectValue: ErrorLike }
>('onboard/updateOnboardData', async (args, { rejectWithValue }) => {
  const query = API.graphql({
    query: updateOnboard,
    variables: { input: args },
    authMode: 'AWS_IAM',
  }) as Promise<GraphQLResult<UpdateOnboardMutation>>;

  return query
    .then((response) => {
      return response.data?.updateOnboard;
    })
    .catch((error) => rejectWithValue(serializeError(error)));
});

export const registerOnboardFunction = createAsyncThunk<
  string | null | undefined,
  RegisterOnboardInput,
  { rejectValue: ErrorLike }
>('onboard/registerOnboard', async (args, { rejectWithValue }) => {
  const query = API.graphql({
    query: registerOnboard,
    variables: { input: args },
    authMode: 'AWS_IAM',
  }) as Promise<GraphQLResult<RegisterOnboardMutation>>;

  return query
    .then((response) => {
      return response.data?.registerOnboard;
    })
    .catch((error) => rejectWithValue(serializeError(error)));
});

const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    resetOnboardSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getHighnoteProducts.fulfilled, (state, action) => {
      if (action.payload) {
        state.highnoteProducts = action.payload;
      }
    });

    builder.addMatcher(
      isAnyOf(getOnboardByInvitationCode.fulfilled, updateOnboardData.fulfilled),
      (state, action) => {
        if (action.payload) {
          state.onboard = action.payload;
        }
      }
    );
  },
});

export const { resetOnboardSlice } = onboardSlice.actions;

export const selectOnboard = (state: any): Onboard | null | undefined => state.onboard.onboard;
export const selectHighnoteProducts = (state: any): ModelHighnoteProductConnection | null | undefined =>
  state.onboard.highnoteProducts;

export default onboardSlice.reducer;
