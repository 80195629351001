import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import theme from 'app/theme';

export interface SideSheetHeaderProps {
  title: React.ReactNode;
  ButtonsComponent?: React.ReactNode;
  onClose?: () => void;
  href?: string;
  sx?: SxProps<Theme>;
}

function SideSheetHeader({
  title,
  ButtonsComponent,
  sx,
  onClose,
  ...restProps
}: SideSheetHeaderProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        padding: 1,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        ...sx,
      }}
    >
      <IconButton onClick={onClose} {...restProps}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h2" sx={{ flexGrow: 1, ml: 1, color: theme.palette.primary.main }}>
        {title}
      </Typography>
      {ButtonsComponent && <Box>{ButtonsComponent}</Box>}
    </Box>
  );
}

export default SideSheetHeader;
