import { Box, Button, Grid } from '@mui/material';
import { useProcoreIntegration } from 'app/hooks/erp-integration/useProcoreIntegration';

import { useAgaveIntegration } from 'app/hooks/erp-integration/useAgaveIntegration';
import ConnectButton from 'app/shared-components/connect-button/ConnectButton';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import ConnectToYourAccountingSystem from 'app/shared-components/erp-connection/ConnectToYourAccountingSystem';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConnectToAccountingSystemFormProps {
  handleNext?: () => void;
}

function ConnectToAccountingSystemForm({ handleNext }: ConnectToAccountingSystemFormProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>();

  const {
    loading: loadingAgave,
    connected: agaveConnected,
    openPopup: openAgavePopup,
  } = useAgaveIntegration({ sourceSystem: value });

  const { connected: procoreConnected, loading: loadingProcore, procoreUrl } = useProcoreIntegration();

  const isConnected = useMemo(() => procoreConnected || agaveConnected, [procoreConnected, agaveConnected]);
  const isLoading = useMemo(() => loadingProcore || loadingAgave, [loadingProcore, loadingAgave]);

  useEffect(() => {
    isConnected && handleNext?.();
  }, [isConnected, handleNext]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        pt: 3,
      }}
    >
      <Grid item xs={12}>
        <ContainerComponent
          title={t('connectAccountingSystem.title')}
          description={t('connectAccountingSystem.description')}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ConnectToYourAccountingSystem value={value} setValue={setValue} disabled={isConnected} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '4rem',
            flexWrap: 'wrap',
            paddingTop: 3,
          }}
        >
          <Button onClick={handleNext} size="small">
            {t('illDoThisLater')}
          </Button>
          <ConnectButton
            loading={isLoading}
            disabled={isConnected || !value}
            {...(value === 'procore' ? { href: procoreUrl } : { onClick: openAgavePopup })}
          >
            {isConnected ? t('connected') : t('connect')}
          </ConnectButton>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ConnectToAccountingSystemForm;
