import { ButtonProps } from '@mui/material';

import ProgressButton from 'app/shared-components/ui/ProgressButton';

type ConnectButtonProps = {
  loading?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top';
  children?: React.ReactNode;
} & ButtonProps;

function ConnectButton({ loading, disabled, children, ...props }: ConnectButtonProps) {
  return (
    <ProgressButton color="primary" size="small" loading={loading} disabled={loading || disabled} {...props}>
      {children}
    </ProgressButton>
  );
}

export default ConnectButton;
