import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

type ButtonContainerProps = {
  children: React.ReactNode;
};

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  gap: '2rem',
  paddingTop: '2.5rem',
});

function ButtonContainer({ children, ...props }: Readonly<ButtonContainerProps>) {
  return <StyledContainer {...props}>{children}</StyledContainer>;
}

export default ButtonContainer;
