export interface ErrorLike {
  name: string;
  message: string;
}

function serializeError(error: Error): ErrorLike {
  return {
    name: error.name,
    message: error.message,
  };
}

export function isErrorLike(obj: unknown): obj is ErrorLike {
  return (
    obj !== null &&
    typeof obj == 'object' &&
    Object.keys(obj).includes('name') &&
    Object.keys(obj).includes('message')
  );
}

export default serializeError;
