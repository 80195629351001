import { LinkBaseProps, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ColorPalette } from 'API';
import LinkBehavior from 'app/shared-components/util/LinkBehavior';
import customTypography from './customTypography';
import defaultColors, { DefaultColorPalette } from './defaultColors';

// Extend BreakpointOverrides to add the xxl property
declare module '@mui/system' {
  interface BreakpointOverrides {
    xxl: true; // adds the `xxl` breakpoint
    xxxl: true;
    xxxxl: true;
  }
}

export const createCustomTheme = (customColors: ColorPalette | DefaultColorPalette) => {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        xxl: 2560, // Custom XXL breakpoint
        xxxl: 3200,
        xxxxl: 3840,
      },
    },
    palette: {
      primary: {
        main: customColors.primary || defaultColors.primary,
        dark: customColors.primaryDark || defaultColors.primaryDark,
        darker: customColors.primaryDarker || defaultColors.primaryDarker,
        light: customColors.primaryLight || defaultColors.primaryLight,
        lighter: customColors.primaryLighter || defaultColors.primaryLighter,
      },
      secondary: {
        main: customColors.secondary || defaultColors.secondary,
        dark: customColors.secondaryDark || defaultColors.secondaryDark,
      },
      info: {
        main: customColors.info || defaultColors.info,
      },
      success: {
        main: customColors.successColor || defaultColors.successColor,
      },
      warning: {
        main: customColors.warningColor || defaultColors.warningColor,
      },
      error: {
        main: customColors.errorColor || defaultColors.errorColor,
        light: customColors.errorLightColor || defaultColors.errorLightColor,
      },
      text: {
        primary: customColors.textPrimary || defaultColors.textPrimary,
        secondary: customColors.textSecondary || defaultColors.textSecondary,
      },
      grayLighter: {
        main: grey[300],
        contrastText: customColors.textPrimary || defaultColors.textPrimary,
      },
      primaryLighter: {
        main: customColors.primaryLighter || defaultColors.primaryLighter,
        contrastText: customColors.textPrimary || defaultColors.textPrimary,
      },
    },
    typography: {
      htmlFontSize: 10,
      ...customTypography,
      body1: {
        ...customTypography.medium,
      },
      body2: {
        ...customTypography.small,
      },
      button: {
        ...customTypography.medium,
        textTransform: 'none',
        fontWeight: 'revert',
      },
      caption: {
        ...customTypography.small,
      },
      overline: {
        ...customTypography.medium,
        textTransform: 'none',
      },
    },
    mixins: {
      toolbar: {
        minHeight: 56,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableTouchRipple: true,
          disableElevation: true,
        },
        styleOverrides: {
          sizeSmall: {
            height: '36px',
            padding: '0 12px',
            borderRadius: '18px',
          },
          sizeMedium: {
            height: '46px',
            padding: '0 24px',
            borderRadius: '23px',
          },
          containedPrimary: {
            '&:hover': {
              backgroundColor: customColors.primaryDark,
            },
            '&:active': {
              backgroundColor: customColors.primaryDarker,
            },
          },
          outlinedPrimary: {
            '&:hover': {
              backgroundColor: customColors.primaryLighter,
            },
            '&:active': {
              backgroundColor: customColors.primaryLight,
            },
          },
          textPrimary: {
            '&:hover': {
              backgroundColor: customColors.primaryLighter,
            },
            '&:active': {
              backgroundColor: customColors.primaryLight,
            },
          },
          containedError: {
            backgroundColor: customColors.errorColor,
            color: customColors.lightestGrayColor,
            '&:hover': {
              backgroundColor: customColors.errorColor,
            },
          } as any,
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: 'primary',
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          componentsProps: {
            typography: {
              variant: 'medium',
            },
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiLink: {
        defaultProps: {
          color: 'info.main',
          component: LinkBehavior,
        } as LinkBaseProps, // https://github.com/mui/material-ui/issues/29942
      },
      MuiInputBase: {
        styleOverrides: {
          sizeSmall: {
            ...customTypography.small,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          sizeSmall: {
            ...customTypography.small,
          },
        },
      },
      MuiTable: {
        defaultProps: {
          stickyHeader: true,
        },
      },
      MuiTableRow: {
        defaultProps: {
          hover: true,
        },
        styleOverrides: {
          root: {
            '&.MuiTableRow-hover:hover': {
              backgroundColor: customColors.primaryLighter,
            },
          },
        },
      },
      MuiTableCell: {
        defaultProps: {
          sx: {
            pl: 2,
            pr: 2,
            whiteSpace: 'nowrap',
            height: 56,
          },
        },
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${grey[300]}`,
          },
          head: {
            ...customTypography.small,
            backgroundColor: customColors.lightestGrayColor,
          } as any,
        },
      },
      MuiTab: {
        defaultProps: {
          sx: {
            '&:hover': { backgroundColor: { sm: customColors.primaryLighter } },
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          sx: {
            '&:hover': { backgroundColor: { sm: customColors.primaryLighter } },
          },
        },
      },
      MuiInputAdornment: {
        defaultProps: {
          sx: { color: customColors.primary },
        },
      },
    },
    customComponents: {
      descriptionBar: {
        expandedSubTextWidth: {
          small: '33.5rem',
          medium: '40rem',
        },
      },
    },
  });
};
