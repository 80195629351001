import { useAuth } from './AuthProvider';
import { useLocation, Navigate } from 'react-router-dom';
import Loading from 'app/shared-components/util/Loading';
import { DEFAULT_LANDING_PAGE } from 'app/store/authSlice';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useAppSelector } from 'app/store';
import { setUser } from '@sentry/react';

export interface RequireAuthProps {
  children: React.ReactNode;
  requireUser?: boolean;
  requireOnboarded?: boolean;
}

function RequireAuth({
  children,
  requireUser = true,
  requireOnboarded = true,
}: RequireAuthProps): JSX.Element {
  const doesRequireOnboarded = requireUser && requireOnboarded;
  const { user, didSessionExpire, isAuthStateUnknown, didUserSignOut } = useAuth();
  const { isUserCompaniesStateUnknown, isUserOnboarded } = useAppSelector(selectUserCompanies);
  const location = useLocation();
  const locationState = location.state as { from?: Location };
  const from = locationState?.from?.pathname || DEFAULT_LANDING_PAGE;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user) {
      setUser({
        email: user?.email,
        id: user?.sub,
        username: user.preferred_username,
      });
    }
  }, [user]);

  useEffect(() => {
    if (requireUser && didSessionExpire) {
      enqueueSnackbar('You have been logged out. Please log in again.');
    }
  }, [didSessionExpire, enqueueSnackbar, requireUser]);

  if (isAuthStateUnknown || (doesRequireOnboarded && isUserCompaniesStateUnknown)) {
    return <Loading />;
  }

  if (requireUser && !user) {
    // Page requires a signed-in user; take them to the Login page
    // and remember the page they were trying to access
    return <Navigate to="/login" state={{ from: didUserSignOut ? undefined : location }} replace />;
  } else if (!requireUser && user) {
    // Page requires that user is **NOT** signed-in; take them back
    // to whence they came
    return <Navigate to={from} replace />;
  }

  if (doesRequireOnboarded && !isUserOnboarded && !isUserCompaniesStateUnknown) {
    // Page requires onboarded user; redirect to OnBoard
    return <Navigate to="/onboard" replace />;
  }

  return <>{children}</>;
}

export default RequireAuth;
