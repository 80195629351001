import { Box } from '@mui/material';
import { HighnotePaymentCard } from 'API';
import PinSDK from 'app/shared-components/PinSDK';
import SideSheet from 'app/shared-components/sidesheets/SideSheet';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';

interface Props {
  paymentCardId: string;
  open: boolean;
  onClose: () => void;
  card?: HighnotePaymentCard;
  cardProduct?: {
    name: string;
  };
}

function PinSheet({ paymentCardId, open, onClose, card, cardProduct }: Props): JSX.Element {
  const panelTitle = card?.name ?? cardProduct?.name ?? 'Card';

  return (
    <SideSheet width="28vw" open={open} onClose={onClose}>
      <SideSheetHeader title={panelTitle} onClose={onClose} />
      <SideSheetContent>
        <Box sx={{ px: 2 }}>
          <PinSDK paymentCardId={paymentCardId} onCancel={onClose} />
        </Box>
      </SideSheetContent>
    </SideSheet>
  );
}

export default PinSheet;
