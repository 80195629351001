import { useTranslation } from 'react-i18next';

export const useObjectsTranslation = (
  objects: {
    id: string;
    label: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
  }[]
) => {
  const { t } = useTranslation();
  return objects.map((c) => ({ ...c, label: t(c.id) }));
};
