import { SxProps } from '@mui/material';
import { BorderLinearProgress } from 'app/shared-components/ui/BorderLinearProgress';
import { Budget, Spent, getDisplayPercentage, getProgressColor } from './utils';

interface BudgetProgressProps {
  isUnlimited?: boolean;
  spent: Spent;
  budget?: Budget;
  sx?: SxProps;
}

function BudgetProgressBar({ isUnlimited = false, spent, budget, sx }: BudgetProgressProps) {
  return (
    <BorderLinearProgress
      sx={sx}
      variant="determinate"
      value={getDisplayPercentage(spent, isUnlimited, budget)}
      $color={getProgressColor(spent, isUnlimited, budget)}
    />
  );
}

export default BudgetProgressBar;
