export const hnGetBusinessAccountHolder = /* GraphQL */ `
  query HnGetBusinessAccountHolder($id: String!) {
    hnGetBusinessAccountHolder(id: $id) {
      ... on HNUSBusinessAccountHolder {
        id
        businessProfile {
          id
          businessType
          name {
            doingBusinessAsName
            legalBusinessName
          }
          billingAddress {
            postalCode
          }
        }
        primaryAuthorizedPerson {
          id
          name {
            givenName
            familyName
          }
        }
        financialAccounts {
          edges {
            node {
              id
              accountStatus
              ledgers {
                name
                normalBalance
                debitBalance {
                  value
                  currencyCode
                }
                creditBalance {
                  value
                  currencyCode
                }
              }
            }
          }
        }
        externalFinancialAccounts {
          edges {
            node {
              __typename
              ... on HNExternalFinancialBankAccount {
                id
                name
                accountStatus
              }
            }
          }
        }
        paymentCards {
          edges {
            node {
              id
              last4
            }
          }
        }
        cardProductApplications {
          edges {
            node {
              __typename
              ... on HNAccountHolderCardProductApplication {
                id
                applicationState {
                  status
                }
                adverseActionReasons {
                  code
                  description
                }
                updatedAt
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;
