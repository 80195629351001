import useSidesheetById from 'app/hooks/useSidesheetById';
import SideSheet from 'app/shared-components/sidesheets/SideSheet';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
//import SideSheetContent from 'app/shared-components/layout/side-sheet/SideSheetContent';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import { ReactNode } from 'react';

export type AssignCardSidesheetParams = {
  paymentCardId: string;
  onSuccess: () => void;
};

function SidesheetContainer({
  children,
  sidesheetId,
  title,
}: {
  children: ReactNode;
  sidesheetId: SidesheetRoutes;
  title?: string;
}) {
  const sidesheet = useSidesheetById(sidesheetId);
  const { closeSidesheet } = useSidesheet();

  const handleOnClose = () => sidesheet.params?.onClose?.() || closeSidesheet(sidesheetId);

  return (
    <SideSheet width={sidesheet.width || undefined} open={sidesheet.isOpen} onClose={handleOnClose}>
      {!sidesheet.customHeader && <SideSheetHeader title={title} onClose={handleOnClose} />}
      {/* <SideSheetContent padding={sidesheet.customHeader ? 0 : undefined}>
        {sidesheet.isOpen && children}
      </SideSheetContent> */}
      {children}
    </SideSheet>
  );
}

export default SidesheetContainer;
