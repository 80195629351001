import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateSpendRule } from 'app/hooks/spend-rule/useCreateSpendRule';
import { useCallback, useState } from 'react';
import { useUpdateFirstRunState } from 'app/hooks/useUpdateFirstRunState';
import SpendRulesForm, { EditSpendRuleFormValues } from 'app/pages/cards/spend-rules/SpendRulesForm';
import ContainerComponent from 'app/shared-components/container-component/container-component';

type CreateSpendRuleFormProps = {
  handleNext: () => void;
};

function CreateSpendRuleForm({ handleNext }: CreateSpendRuleFormProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const createSpendRuleHandler = useCreateSpendRule();

  const { triggerUpdate } = useUpdateFirstRunState({
    operationName: 'completeFirstRunChecklist',
    firstRunValue: 'createSpendRule',
  });

  const onSubmit = useCallback(
    async (formValues: EditSpendRuleFormValues) => {
      setLoading(true);
      await triggerUpdate();
      await createSpendRuleHandler(formValues);
      setLoading(false);
      handleNext();
    },
    [createSpendRuleHandler, handleNext, triggerUpdate]
  );

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        pt: 3,
      }}
    >
      <Grid item xs={12}>
        <ContainerComponent
          title={t('createSpendRuleHelperText.title')}
          description={t('createSpendRuleHelperText.description')}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 2,
        }}
      >
        <SpendRulesForm
          onSubmitForm={onSubmit}
          loading={loading}
          handleSkip={() => handleNext()}
          sx={{ width: '450px' }}
        />
      </Grid>
    </Grid>
  );
}

export default CreateSpendRuleForm;
