export interface CurrencyProps {
  value: number;
  currency?: string;
  currencyCode?: string;
}

function Currency({ value, ...restProps }: CurrencyProps) {
  const currency = restProps.currency ?? restProps.currencyCode ?? 'USD';
  return <>{formatCurrency({ value, currency })}</>;
}

export function currencyValueToFloat(value: number) {
  return value / 100;
}

export function floatToCurrencyValue(value: number) {
  return Math.floor(value * 100);
}

export function formatCurrency({ value, ...restProps }: CurrencyProps) {
  const currency = restProps.currency ?? restProps.currencyCode ?? 'USD';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(currencyValueToFloat(value));
}

export function toCurrency(value: number, currency = 'USD'): CurrencyProps {
  return {
    value: floatToCurrencyValue(value),
    currency,
  };
}

export function toHNAmount(value: number, currencyCode = 'USD'): CurrencyProps {
  return {
    value: floatToCurrencyValue(value),
    currencyCode,
  };
}

export default Currency;
